import useSettings from 'hooks/useSettings';
import React, { useEffect, useState } from 'react';
import endpoints from 'services/request/endpoints';
import { axiosInstance } from 'services/instance';

import useSweetAlert from 'hooks/useSweetAlert';
import View from './View';

const ListStates: React.FC = () => {
  const { themeStretch } = useSettings();
  const [selected, setSelected] = useState<string[]>([]);
  const [list, setList] = useState<any[]>([]);
  const [filter, setFilter] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [currentTab, setCurrentTab] = useState('all');
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const { exclude } = useSweetAlert();

  const getList = async () => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.states}`;
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(endpoint);

      setList(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const TABLE_HEAD = [
    { id: 'codigo_estados', label: 'Cód. Estado', alignRight: false },
    { id: 'estado', label: 'Estado', alignRight: false },
    { id: 'regiao', label: 'Região', alignRight: false },
    { id: 'sigla', label: 'UF', alignRight: false },
    { id: 'aliquota', label: 'Alíquota Padrão', alignRight: false },
    { id: 'updated_at', label: 'Atualizado em', alignRight: false },
    { id: '', label: '', alignRight: false }
  ];

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (filter: string) => {
    setFilter(filter);
  };

  const handleDelete = async (id: string) => {
    exclude({
      endpoint: `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.state}/${id}`,
      setLoading: setLoadingAction
    });
  };

  const filteredList = list.filter((el) => {
    const { nome, sigla, aliquota, regiao } = el;

    return (
      `${nome}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
      `${sigla}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
      `${aliquota}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
      `${regiao}`.toLowerCase().includes(`${filter}`.toLowerCase())
    );
  });

  const isNofilterResult = filteredList.length === 0;

  return (
    <View
      {...{
        themeStretch,
        selected,
        setSelected,
        list,
        filteredList,
        TABLE_HEAD,
        handleFilter,
        handleDelete,
        isLoading: loading,
        isNofilterResult,
        page,
        rowsPerPage,
        setRowsPerPage,
        setCurrentTab,
        currentTab,
        emptyRows,
        filter,
        handleChangeRowsPerPage,
        setFilter,
        setPage,
        loadingAction
      }}
    />
  );
};

export default ListStates;

import { motion } from 'framer-motion';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Button, Typography, Container } from '@material-ui/core';
import { MotionContainer, varBounceIn } from 'components/animate';
import { ReactComponent as PlanBackground } from 'assets/images/planBackground.svg';
import Page from 'components/Page';
import { FC, ReactElement } from 'react';
import { useSnackbar } from 'notistack5';
import { useDispatch } from 'services/store';
import { Logout } from 'services/store/actions/authcompany.action';
import woman from 'assets/images/woman.png';
// hooks

import useIsMountedRef from 'hooks/useIsMountedRef';
import { useContextCompany } from 'contexts/CompanyContext';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

const PeriodExpired: FC = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const { messageExpired } = useContextCompany();

  const navigate = useNavigate();

  const logoutAction = () => {
    dispatch(Logout());
  };

  const handleLogout = async () => {
    try {
      await logoutAction();
      if (isMountedRef.current) {
        navigate('/');
      }
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    }
  };

  return (
    <RootStyle title="Página inválida! [i]">
      <Container>
        <MotionContainer initial="initial" open>
          <div
            style={{
              margin: 'auto',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Opsss!
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              {messageExpired ||
                'Seu período de teste chegou ao fim. Contrate uma licença para um plano para poder utilizar esse recurso.'}
            </Typography>

            <motion.div variants={varBounceIn} style={{ marginTop: '54px', position: 'relative' }}>
              <PlanBackground />
              <img
                src={woman}
                style={{ position: 'absolute', top: '-30px', right: '350px' }}
                alt="woman"
              />
            </motion.div>

            <div
              style={{
                display: 'flex',
                columnGap: '16px',
                justifyContent: 'center',
                paddingTop: '34px'
              }}
            >
              <Button
                to="/licencas"
                size="large"
                variant="contained"
                component={RouterLink}
                style={{ textTransform: 'none' }}
              >
                Ir para Licenças
              </Button>

              <Button size="large" variant="contained" onClick={() => handleLogout()}>
                Sair
              </Button>
            </div>
          </div>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
};

export default PeriodExpired;

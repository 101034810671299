import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from 'services/instance';
import endpoints from 'services/request/endpoints';
import {
  CompaniesUserSelectedPayloadProps,
  CompanyEditUserPayloadProps,
  CompanyGroupIndividualPayloadProps,
  CompanyNewUserPayloadProps,
  CompanyUserDeletePayloadProps,
  CompanyUserPayloadProps,
  updateCompaniesUserSelectedPayloadProps
} from 'services/request/models/payload';
import {
  CompaniesUserSelectedResponseProps,
  CompanyGroupListResponseProps,
  CompanyIndividualResponseProps,
  CompanyNewUserResponseProps,
  CompanyPermissionsResponseProps,
  CompanyUserEditResponseProps,
  CompanyUserRemoveResponseProps,
  CompanyUsersListResponseProps
} from 'services/request/models/response';

export const fetchPermissions = createAsyncThunk<CompanyPermissionsResponseProps[], any>(
  'integrator.getPermissions',
  async (data, { rejectWithValue }) => {
    try {
      const api = axiosInstance;
      const response = await api({
        baseURL: process.env.REACT_APP_BASE_URL + endpoints.integrator.permissions,
        method: 'GET',
        data
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchGroups = createAsyncThunk<CompanyGroupListResponseProps[]>(
  'integrator.getGroups',
  async (__, { rejectWithValue }) => {
    try {
      const api = axiosInstance;
      const response = await api({
        baseURL: process.env.REACT_APP_BASE_URL + endpoints.integrator.groups,
        method: 'GET'
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchGroup = createAsyncThunk<
  CompanyIndividualResponseProps[],
  CompanyGroupIndividualPayloadProps
>('integrator.getGroup', async (data, { rejectWithValue }) => {
  try {
    const api = axiosInstance;
    const response = await api({
      baseURL: `${process.env.REACT_APP_BASE_URL + endpoints.integrator.group}/${data.id}`,
      method: 'GET',
      data
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchUsers = createAsyncThunk<CompanyUsersListResponseProps[]>(
  'integrator.getClientUsers',
  async (__, { rejectWithValue }) => {
    try {
      const api = axiosInstance;
      const response = await api({
        baseURL: process.env.REACT_APP_BASE_URL + endpoints.integrator.users,
        method: 'GET'
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchClientUsers = createAsyncThunk<CompanyUsersListResponseProps[]>(
  'integrator.getClientUsers',
  async (__, { rejectWithValue }) => {
    try {
      const api = axiosInstance;
      const response = await api({
        baseURL: process.env.REACT_APP_BASE_URL + endpoints.integrator.clientUsers,
        method: 'GET'
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchUser = createAsyncThunk<CompanyUsersListResponseProps, CompanyUserPayloadProps>(
  'integrator.getUser',
  async (data, { rejectWithValue }) => {
    try {
      const api = axiosInstance;
      const response = await api({
        baseURL: `${process.env.REACT_APP_BASE_URL + endpoints.integrator.user}/${data.id}`,
        method: 'GET',
        data
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchClientUser = createAsyncThunk<
  CompanyUsersListResponseProps,
  CompanyUserPayloadProps
>('integrator.getUser', async (data, { rejectWithValue }) => {
  try {
    const api = axiosInstance;
    const response = await api({
      baseURL: `${process.env.REACT_APP_BASE_URL + endpoints.integrator.clientUser}/${data.id}`,
      method: 'GET',
      data
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchNewUser = createAsyncThunk<
  CompanyNewUserResponseProps,
  CompanyNewUserPayloadProps
>('integrator.NewUser', async (data, { rejectWithValue }) => {
  try {
    const api = axiosInstance;
    const response = await api({
      baseURL: `${process.env.REACT_APP_BASE_URL + endpoints.integrator.user}`,
      method: 'POST',
      data
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchNewClientUser = createAsyncThunk<
  CompanyNewUserResponseProps,
  CompanyNewUserPayloadProps
>('integrator.NewUser', async (data, { rejectWithValue }) => {
  try {
    const api = axiosInstance;
    const response = await api({
      baseURL: `${process.env.REACT_APP_BASE_URL + endpoints.integrator.clientUser}`,
      method: 'POST',
      data
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchEditUser = createAsyncThunk<
  CompanyUserEditResponseProps,
  CompanyEditUserPayloadProps
>('integrator.EditUser', async (data, { rejectWithValue }) => {
  try {
    const api = axiosInstance;
    const response = await api({
      baseURL: `${process.env.REACT_APP_BASE_URL + endpoints.integrator.user}/${data.id}`,
      method: 'PUT',
      data
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchEditClientUser = createAsyncThunk<
  CompanyUserEditResponseProps,
  CompanyEditUserPayloadProps
>('integrator.EditUser', async (data, { rejectWithValue }) => {
  try {
    const api = axiosInstance;
    const response = await api({
      baseURL: `${process.env.REACT_APP_BASE_URL + endpoints.integrator.clientUser}/${data.id}`,
      method: 'PUT',
      data
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchRemoveUser = createAsyncThunk<
  CompanyUserRemoveResponseProps,
  CompanyUserDeletePayloadProps
>('integrator.EditUser', async (data, { rejectWithValue }) => {
  try {
    const api = axiosInstance;
    const response = await api({
      baseURL: `${process.env.REACT_APP_BASE_URL + endpoints.integrator.user}/${data.id}`,
      method: 'DELETE',
      data
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchRemoveClientUser = createAsyncThunk<
  CompanyUserRemoveResponseProps,
  CompanyUserDeletePayloadProps
>('integrator.EditUser', async (data, { rejectWithValue }) => {
  try {
    const api = axiosInstance;
    const response = await api({
      baseURL: `${process.env.REACT_APP_BASE_URL + endpoints.integrator.clientUser}/${data.id}`,
      method: 'DELETE',
      data
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchCompaniesUserSelected = createAsyncThunk<
  CompaniesUserSelectedResponseProps[],
  CompaniesUserSelectedPayloadProps
>('integrator.fetchCompaniesUserSelected', async (data, { rejectWithValue }) => {
  try {
    const api = axiosInstance;
    const response = await api({
      baseURL: `${
        process.env.REACT_APP_BASE_URL + endpoints.integrator.companiesSelectedUsers(data.id)
      }`,
      method: 'GET',
      data
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateCompaniesUserSelected = createAsyncThunk<
  CompaniesUserSelectedResponseProps[],
  updateCompaniesUserSelectedPayloadProps
>('integrator.fetchCompaniesUserSelected', async (data, { rejectWithValue }) => {
  try {
    const api = axiosInstance;
    const response = await api({
      baseURL: `${
        process.env.REACT_APP_BASE_URL +
        endpoints.integrator.updateCompaniesSelectedUsers(data.idIntegrator)
      }`,
      method: 'PUT',
      data: data.companies
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack5';
import { useParams } from 'react-router-dom';
import endpoints from 'services/request/endpoints';
import { axiosInstance } from 'services/instance';
import {
  fetchCompaniesUserSelected,
  updateCompaniesUserSelected
} from 'services/store/actions/integrator/user.action';
import { useAppDispatch } from 'services/request/baseQuery';
import { CompaniesUserSelectedResponseProps } from 'services/request/models/response';
import { CompanyUserProps } from './Model';
import View from './View';

const CompanyNewUserClientv2: React.FC = () => {
  const params = useParams();
  const [typePage, setTypePage] = useState<'New' | 'Edit'>('New');
  const [groupPermissions, setGroupPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const dispatch = useAppDispatch();

  const { enqueueSnackbar } = useSnackbar();

  // edit
  const [currentSelected, setCurrentSelected] = useState<CompanyUserProps>({
    ativo: true,
    celular: '',
    cpf: '',
    created_at: null,
    email: '',
    id: -1,
    nome: '',
    grupo_permissao: {
      deleted_at: null,
      descricao: '',
      permissoes: '',
      updated_at: '',
      id: 0
    },
    updated_at: null,
    deleted_at: null,
    integrador_id: -1
  });

  const [listCompaniesSelected, setListCompaniesSelected] = useState<
    CompaniesUserSelectedResponseProps[]
  >([]);

  const [updateCompaniesSelected, setUpdateCompaniesSelected] = useState<
    CompaniesUserSelectedResponseProps[]
  >([]);

  async function getGroupPermissionsList() {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.integrator.groups}`;

    try {
      const { data } = await axiosInstance.get(endpoint);
      await setGroupPermissions(data);
      setLoading(false);
    } catch (error) {
      console.log('Error', error);
      setLoading(false);
    }
  }

  async function callEdit() {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.integrator.clientUser}/${params.id}`;
    setLoading(true);
    setTypePage('Edit');

    try {
      const { data } = await axiosInstance.get(endpoint);
      await setCurrentSelected(data);
      setLoading(false);
    } catch (error: any) {
      console.log('Error', error);
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
      setLoading(false);
    }
  }

  async function getCompaniesSelected() {
    if (!params.id) {
      const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.clientV2.listCompaniesIntegrator}`;
      const { data } = await axiosInstance.get(endpoint);
      setListCompaniesSelected(data);
    } else {
      const response = await dispatch(fetchCompaniesUserSelected({ id: Number(params.id) }));
      if (response.meta.requestStatus === 'fulfilled') {
        // @ts-ignore
        setListCompaniesSelected(response.payload);
      }
    }
  }

  function callUpdateCompanies() {
    if (updateCompaniesSelected && updateCompaniesSelected.length > 0) {
      updateCompaniesSelectedOnline();
    }
  }

  async function updateCompaniesSelectedOnline() {
    const newStructureCompanies = updateCompaniesSelected.map((company) => ({
      id: company.id,
      razao: company.razao,
      fantasia: company.fantasia,
      cnpj: company.cnpj,
      selected: !company.selected
    }));

    const response = await dispatch(
      updateCompaniesUserSelected({
        idIntegrator: Number(params.id),
        // @ts-ignore
        companies: newStructureCompanies
      })
    );

    if (response.meta.requestStatus === 'fulfilled') {
      enqueueSnackbar('Seleção de Empresas aplicada com sucesso.', { variant: 'success' });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      enqueueSnackbar('Não foi possível aplicar a seleção de Empresas.', { variant: 'error' });
    }
  }

  useEffect(() => {
    getGroupPermissionsList();
    getCompaniesSelected();

    if (params && params.id) {
      setTypePage('Edit');
      callEdit();
    }
  }, []);

  return (
    <View
      loading={loading}
      typePage={typePage}
      listGroups={groupPermissions}
      selected={currentSelected}
      changePassword={changePassword}
      setChangePassword={setChangePassword}
      listCompanies={listCompaniesSelected}
      setUpdateCompaniesSelected={setUpdateCompaniesSelected}
      callUpdateCompanies={callUpdateCompanies}
    />
  );
};
export default CompanyNewUserClientv2;

import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import { Card, CardHeader } from '@material-ui/core';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important' as 'relative',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

// ----------------------------------------------------------------------

export default function AppMemoryUsage({ data }: { data: string | undefined }) {
  const theme = useTheme();
  if (data) {
    const lines = data?.split('\n').filter((line) => line.trim() !== '') || '';
    const headers = lines[0].split(/\s+/);
    // @ts-ignore
    const values: string[][] = lines.slice(1).map((line: string) => line.split(/\s+/));

    const result = values.reduce(
      (obj: { [x: string]: { [x: string]: number } }, value: string[]) => {
        const key = value[0].replace(':', '');
        obj[key] = {};
        headers.slice(1).forEach((header, index) => {
          obj[key][header] = parseInt(value[index + 1], 10);
        });
        return obj;
      },
      {}
    );

    const CHART_DATA = [
      result?.Mem?.available,
      result?.Mem?.shared,
      result?.Mem?.free,
      result?.Mem?.used
    ];

    const chartOptions = merge(BaseOptionChart(), {
      colors: [
        theme.palette.primary.lighter,
        theme.palette.primary.light,
        theme.palette.primary.main,
        theme.palette.primary.dark
      ],
      labels: ['Disponível', 'Compartilhado', 'Livre', 'Usado'],
      stroke: { colors: [theme.palette.background.paper] },
      legend: { floating: true, horizontalAlign: 'center' },
      tooltip: {
        fillSeriesColor: false,
        y: {
          formatter: (seriesName: string) => fNumber(seriesName),
          title: {
            formatter: (seriesName: string) => `${seriesName}`
          }
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '90%',
            labels: {
              value: {
                formatter: (val: number | string) => val
              },
              total: {
                formatter: (w: { globals: { seriesTotals: number[] } }) => {
                  const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                  return sum;
                }
              }
            }
          }
        }
      }
    });

    return (
      <Card>
        <CardHeader title="Memória  " />
        <ChartWrapperStyle dir="ltr">
          <ReactApexChart type="donut" series={CHART_DATA} options={chartOptions} height={280} />
        </ChartWrapperStyle>
      </Card>
    );
  }
  return null;
}

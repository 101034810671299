import { useAppDispatch } from 'services/request/baseQuery';
import { fetchIntegratorRecovery } from 'services/store/actions/integrator/auth.action';
import View from './View';

const IntegratorResetPassword: React.FC = () => {
  const dispatch = useAppDispatch();

  async function fetchRecovery(cpf: string) {
    const response = await dispatch(fetchIntegratorRecovery({ cpf }));
    return response;
  }

  return <View {...{ fetchRecovery }} />;
};
export default IntegratorResetPassword;

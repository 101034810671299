import React, {
  ReactElement,
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction
} from 'react';

export interface CompanyCreatePayloadProps {
  cnpj?: string;
  razao?: string;
  fantasia?: string;
  endereco?: string;
  numero?: string;
  complemento?: string;
  bairro?: string;
  cidade?: string;
  estado?: string;
  regime_tributario?: string;
  contribuinte_icms?: string;
  fundado_em?: string;
  email_cnpj?: string;
  mei?: boolean;
  nome_contato?: string;
  telefone_celular?: string;
  email_contato?: string;
  senha?: string;
  chegou_via?: string;
  aceite_termos?: boolean;
  ie?: string;
  telefone_fixo?: string;
  cep?: string;
  cobranca: {
    plano_id?: number;
    cobranca_endereco?: string;
    cobranca_numero?: string;
    cobranca_complemento?: string;
    cobranca_bairro?: string;
    cobranca_cep?: string;
    cobranca_cidade?: string;
    cobranca_estado?: string;
    metodo_pagamento?: number;
  };
}

export type CompanyContextProps = {
  company: CompanyCreatePayloadProps;
  setCompany: Dispatch<SetStateAction<CompanyCreatePayloadProps>>;
  messageExpired: string;
  setMessageExpired: Dispatch<SetStateAction<string>>;
};

const defaultValue: CompanyContextProps = {
  company: {
    cnpj: '',
    razao: '',
    fantasia: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    cep: '',
    regime_tributario: '',
    contribuinte_icms: '',
    fundado_em: '',
    email_cnpj: '',
    mei: false,
    nome_contato: '',
    telefone_celular: '',
    email_contato: '',
    senha: '',
    chegou_via: '',
    aceite_termos: false,
    ie: '',
    telefone_fixo: '',
    cobranca: {
      plano_id: 1,
      cobranca_endereco: '',
      cobranca_numero: '',
      cobranca_complemento: '',
      cobranca_bairro: '',
      cobranca_cep: '',
      cobranca_cidade: '',
      cobranca_estado: '',
      metodo_pagamento: 1
    }
  },
  setCompany: () => {},
  messageExpired: '',
  setMessageExpired: () => {}
};

interface CompanyContextProvider {
  children: ReactNode;
}

const CompanyContext = createContext<CompanyContextProps>(defaultValue);

export function CompanyProvider({ children }: CompanyContextProvider): ReactElement {
  const [company, setCompany] = useState<CompanyCreatePayloadProps>(
    {} as CompanyCreatePayloadProps
  );
  const [messageExpired, setMessageExpired] = useState<string>('');

  return (
    <CompanyContext.Provider
      value={{
        company,
        setCompany,
        messageExpired,
        setMessageExpired
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
}

export function useContextCompany(): CompanyContextProps {
  const context = useContext<CompanyContextProps>(CompanyContext);
  if (!context) throw new Error('O componente não está dentro do contexto de CompanyContext');
  return context;
}

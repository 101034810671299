// material
import { styled } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Container,
  Typography,
  Stack,
  Radio,
  FormControlLabel,
  RadioGroup
} from '@material-ui/core';
// components
import { useNavigate } from 'react-router-dom';
import Page from 'components/Page';
import LoadingScreen from 'components/LoadingScreen';
import { LicencesViewProps } from '../model';
import PricingPlanCard from '../components/PricingPlanCard';
//

// ----------------------------------------------------------------------

const View: React.FC<LicencesViewProps> = ({
  PLANS,
  period,
  setPeriod,
  isLoading,
  handleBack,
  handleNext
}) => {
  const RootStyle = styled(Page)(({ theme }) => ({
    minHeight: '100%',
    paddingTop: theme.spacing(5)
  }));

  return (
    <RootStyle title="Licenças" height="100%">
      {isLoading ? (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%', height: '100%' }}
        >
          <LoadingScreen />
        </Stack>
      ) : (
        <Container maxWidth="lg">
          <Box sx={{ my: 5 }}>
            <Stack direction="row" alignItems="center" justifyContent="center">
              <RadioGroup
                sx={{ display: 'flex', flexDirection: 'row', gap: 3, alignItems: 'start' }}
                onChange={(event, value) => setPeriod(value)}
                value={period}
              >
                <FormControlLabel
                  value="mensal"
                  control={<Radio />}
                  label="Mensal"
                  defaultChecked
                />
                <Stack>
                  <FormControlLabel value="trimestral" control={<Radio />} label="Trimestral" />
                  <Typography fontWeight={700} alignSelf="center">
                    15% off
                  </Typography>
                </Stack>
                <Stack>
                  <FormControlLabel value="anual" control={<Radio />} label="Anual" />
                  <Typography fontWeight={700} alignSelf="center">
                    30% off
                  </Typography>
                </Stack>
              </RadioGroup>
            </Stack>
          </Box>

          <Grid container spacing={3}>
            {PLANS?.map((card, index) => (
              <Grid item xs={12} md={4} key={card.subscription}>
                <PricingPlanCard
                  card={card}
                  index={index}
                  period={period}
                  handleNext={handleNext}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </RootStyle>
  );
};

export default View;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack5';
import endpoints from 'services/request/endpoints';
import { axiosInstance } from 'services/instance';
import { AdministrativeConv5291Props } from './View/Model';
import View from './View';

const NewConv5291: React.FC = () => {
  const params = useParams();
  const [typePage, setTypePage] = useState<'New' | 'Edit'>('New');
  const [loading, setLoading] = useState(false);

  // edit
  const [currentSelected, setCurrentSelected] = useState<AdministrativeConv5291Props>({
    id: 0,
    item: '',
    codigo: '',
    ncm: '',
    descricao: '',
    ativo: true,
    vigencia_inicio: null,
    vigencia_fim: null,
    created_at: null,
    updated_at: null
  });

  const { enqueueSnackbar } = useSnackbar();

  async function callEdit() {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.conv5291}/${params.id}`;
    setLoading(true);
    setTypePage('Edit');

    try {
      const { data } = await axiosInstance.get(endpoint);

      await setCurrentSelected(data);
      setLoading(false);
    } catch (error: any) {
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
      setLoading(false);
    }
  }

  useEffect(() => {
    if (params && params.id) {
      setTypePage('Edit');
      callEdit();
    }
  }, []);

  return <View loading={loading} typePage={typePage} selected={currentSelected} />;
};
export default NewConv5291;

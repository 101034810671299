import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Box,
  Card,
  Container,
  Typography,
  Grid,
  Stack,
  TextField,
  Button,
  Autocomplete
} from '@material-ui/core';

import { LoadingButton } from '@material-ui/lab';
import { useEffect } from 'react';
import LoadingScreen from 'components/LoadingScreen';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_ADMIN } from 'routes/paths';
import { useNavigate } from 'react-router';
import endpoints from 'services/request/endpoints';
import InputMask from 'react-input-mask';
import useSweetAlert from 'hooks/useSweetAlert';
import { NewStateViewProps } from '../Model';

const View: React.FC<NewStateViewProps> = ({ loading, typePage, selected, regionList }) => {
  const navigate = useNavigate();
  const { sendData } = useSweetAlert();

  const NewSchema = Yup.object().shape({
    cod_state: Yup.string().required('O Código do Estado é obrigatório.'),
    name: Yup.string().required('O Nome é obrigatório.'),
    sigla: Yup.string().required('A Sigla é obrigatória.'),
    aliquot: Yup.string().required('A Aliquota é obrigatória.'),
    region: Yup.string().required('A Região é obrigatória.')
  });

  const UpdateSchema = Yup.object().shape({
    cod_state: Yup.string().required('O Código do Estado é obrigatório.'),
    name: Yup.string().required('O Nome é obrigatório.'),
    sigla: Yup.string().required('A Sigla é obrigatória.'),
    aliquot: Yup.string().required('A Aliquota é obrigatória.'),
    region: Yup.string().required('A Região é obrigatória.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cod_state: '',
      name: '',
      sigla: '',
      aliquot: '',
      region: ''
    },
    validationSchema: typePage === 'New' ? NewSchema : UpdateSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      const endpoint =
        typePage === 'New'
          ? `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.state}`
          : `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.state}/${selected?.id}`;

      const payload = {
        codigo_estado: values.cod_state,
        nome: values.name,
        sigla: values.sigla,
        aliquota: values.aliquot.replace(',', '.').replace('%', ''),
        regiao: values.region
      };

      sendData({
        typePage,
        payload,
        endpoint,
        setSubmitting,
        setErrors
      });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  useEffect(() => {
    if (selected) {
      formik.setFieldValue('cod_state', selected.codigo_estados);
      formik.setFieldValue('name', selected.nome);
      formik.setFieldValue('sigla', selected.sigla);
      formik.setFieldValue('aliquot', selected.aliquota);
      formik.setFieldValue('region', selected.regiao);
    }
  }, [selected]);

  return (
    <Page title={typePage === 'New' ? 'Novo Estado' : 'Estado'}>
      <Container>
        <HeaderBreadcrumbs
          heading="Estados"
          links={[
            { name: 'Estados', href: PATH_ADMIN.general.administrative.tables.states },
            {
              name: typePage === 'New' ? 'Novo Estado' : formik.getFieldProps('name').value
            }
          ]}
        />

        {loading ? (
          <div style={{ marginTop: '300px' }}>
            <LoadingScreen />
          </div>
        ) : (
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <Typography variant="h5">Dados</Typography>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                        <Autocomplete
                          sx={{ width: 600 }}
                          disablePortal
                          id="combo-box-demo"
                          options={regionList}
                          getOptionLabel={(option: string) => option}
                          {...getFieldProps('region')}
                          onChange={(event, value) => formik.setFieldValue('region', value)}
                          renderInput={(inputProps) => (
                            <TextField
                              {...inputProps}
                              label="Região"
                              error={Boolean(touched.region && errors.region)}
                              helperText={touched.region && errors.region}
                            />
                          )}
                        />
                        <TextField
                          fullWidth
                          label="Nome"
                          {...getFieldProps('name')}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          sx={{ width: 600 }}
                        />
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="Sigla"
                          {...getFieldProps('sigla')}
                          error={Boolean(touched.sigla && errors.sigla)}
                          helperText={touched.sigla && errors.sigla}
                          sx={{ width: 600 }}
                          inputProps={{ maxLength: 2, style: { textTransform: 'uppercase' } }}
                        />
                        <InputMask
                          formatChars={{ '9': '[0-9.]' }}
                          mask="9999999999"
                          disabled={false}
                          maskChar=""
                          {...getFieldProps('cod_state')}
                          error={Boolean(touched.cod_state && errors.cod_state)}
                          helperText={touched.cod_state && errors.cod_state}
                          sx={{ width: 600 }}
                        >
                          {(inputProps: any) => (
                            <TextField fullWidth label="Código do Estado" {...inputProps} />
                          )}
                        </InputMask>
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                        <InputMask
                          formatChars={{ '9': '[0-9]' }}
                          mask="99,99%"
                          disabled={false}
                          maskChar="0"
                          {...getFieldProps('aliquot')}
                          error={Boolean(touched.aliquot && errors.aliquot)}
                          helperText={touched.aliquot && errors.aliquot}
                          sx={{ width: 300 }}
                        >
                          {(inputProps: any) => (
                            <TextField fullWidth label="Alíquota padrão" {...inputProps} />
                          )}
                        </InputMask>
                      </Stack>
                    </Stack>
                    <Stack
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1, sm: 2, md: 2 }}
                    >
                      <Box sx={{ mt: 3, display: 'flex' }}>
                        <Button
                          variant="outlined"
                          type="button"
                          color="primary"
                          onClick={() => navigate(-1)}
                        >
                          Cancelar
                        </Button>
                      </Box>
                      <Box sx={{ mt: 3, display: 'flex' }}>
                        <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                          {typePage === 'New' ? 'Cadastrar' : 'Atualizar'}
                        </LoadingButton>
                      </Box>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        )}
      </Container>
    </Page>
  );
};

export default View;

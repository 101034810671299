// eslint-disable-next-line import/no-anonymous-default-export
export default {
  administrative: {
    login: '/administrative/login',
    recovery: '/administrative/recovery',
    companiesList: '/administrative/companies',
    registerCompany: '/administrative/company',
    company: '/administrative/company',
    group: '/administrative/group-permission',
    groups: '/administrative/group-permissions',
    permissions: '/administrative/permissions',
    users: '/administrative/users',
    user: '/administrative/user',
    profile: '/administrative/me',
    changeDetails: '/administrative/me',
    changePassword: '/administrative/me/password',
    paymentMethods: '/administrative/payment-methods',
    tables: {
      states: '/administrative/table-states',
      state: '/administrative/table-state',
      attachmentxs: '/administrative/table-attachment-xs',
      attachmentx: '/administrative/table-attachment-x',
      segment: '/administrative/table-segment',
      segments: '/administrative/table-segments',
      port195s: '/administrative/table-port195s',
      port195: '/administrative/table-port195',
      conv5291s: '/administrative/table-conv5291s',
      conv5291: '/administrative/table-conv5291',
      proticms41s: '/administrative/table-proticms41s',
      proticms41: '/administrative/table-proticms41',
      customRules: '/administrative/custom-rules',
      customRule: '/administrative/custom-rules',
      companiesList: '/administrative/custom-rules/companies'
    },
    listNfe: '/administrative/importer/nfe',
    importerXml: '/administrative/importer/xml',
    analyzer: '/administrative/analyzer',
    listCompanies: '/administrative/analyzer/companies',
    listClients: '/administrative/analyzer/clients',
    listPlans: '/administrative/plans',
    dashboard: '/administrative/dashboard'
  },
  integrator: {
    registerCompany: '/company',
    plans: '/company/plans',
    checkout: '/company/checkout',
    paymentMethods: '/company/payment-methods',
    pay: '/company/pay',
    consultPayment: '/company/consult-payment',
    verify: '/company/license-verify',

    login: '/company/login',
    permissions: '/company/permissions',
    users: '/company/users',
    // v2
    clientUser: '/company/clientv2/user',
    clientUsers: '/company/clientv2/users',

    companiesSelectedUsers: (id: number) => `/company/clientv2/${id}/companies-selected`,
    updateCompaniesSelectedUsers: (id: number) => `/company/clientv2/${id}/companies-update`,

    user: '/company/user',
    group: '/company/group-permission',
    groups: '/company/group-permissions',
    clients: '/company/clients',
    client: '/company/client',
    profile: '/company/me',
    changeDetails: '/company/me',
    changePassword: '/company/me/password',
    recovery: '/company/recovery',
    listNfe: '/company/importer/nfe',
    importerXml: '/company/importer/xml',
    analyzer: '/company/analyzer',
    listCompanies: '/company/analyzer/companies',
    listClients: '/company/analyzer/clients',

    creditCard: '/company/configuration/invoicing/card',

    tables: {
      states: '/company/table-states',
      attachmentxs: '/company/table-attachment-xs',
      segments: '/company/table-segments',
      port195s: '/company/table-port195s',
      conv5291s: '/company/table-conv5291s',
      proticms41s: '/company/table-proticms41s'
    },
    dashboard: '/company/dashboard'
  },
  client: {
    login: '/client/login',
    permissions: '/client/permissions',
    users: '/client/users',
    user: '/client/user',
    group: '/client/group-permission',
    groups: '/client/group-permissions',
    profile: '/client/me',
    changeDetails: '/client/me',
    changePassword: '/client/me/password',
    recovery: '/client/recovery'
  },
  clientV2: {
    login: '/clientV2/login',
    recovery: '/clientV2/recovery',
    profile: '/clientV2/me',
    changeDetails: '/clientV2/me',
    changePassword: '/clientV2/me/password',
    importerXml: '/clientV2/importer/xml',
    analyzer: '/clientV2/analyzer',
    listCompanies: '/clientV2/analyzer/companies',
    listCompaniesIntegrator: '/clientV2/analyzer/companies-integrator'
  },
  login: '/login'
};

import React from 'react';
import View from './View';
import { NcmViewProps } from './Model';

const New: React.FC<NcmViewProps> = ({
  handleIsDialog,
  setHandleIsDialog,
  ncmList,
  setNcmList,
  ncmId,
  setNcmId,
  ncm,
  setNcm,
  description,
  setDescription,
  active,
  setActive,
  vigencStart,
  setVigencStart,
  vigencEnd,
  setVigencEnd,
  typePage
}): React.ReactElement => (
  <View
    handleIsDialog={handleIsDialog}
    setHandleIsDialog={setHandleIsDialog}
    ncmList={ncmList}
    setNcmList={setNcmList}
    ncmId={ncmId}
    setNcmId={setNcmId}
    ncm={ncm}
    setNcm={setNcm}
    description={description}
    setDescription={setDescription}
    active={active}
    setActive={setActive}
    vigencStart={vigencStart}
    setVigencStart={setVigencStart}
    vigencEnd={vigencEnd}
    setVigencEnd={setVigencEnd}
    typePage={typePage}
  />
);

export default New;

import { Container, Grid } from '@material-ui/core';
import { AppWelcome } from 'components/_dashboard/general-app';
import React from 'react';
import AppTotalCompanies from 'components/_dashboard/general-app/AppTotalCompanies';
import AppNfeTotal from 'components/_dashboard/general-app/AppNfeTotal';
import AppCPUUsage from 'components/_dashboard/general-app/AppCPUUsage';
import AppMemoryUsage from 'components/_dashboard/general-app/AppMemoryUsage';
import { HomeViewProps } from '../Model';
import { RootStyle } from './styles';

const View: React.FC<HomeViewProps> = ({ user, data }) => (
  <RootStyle title="Admin TRIB">
    <Container maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <AppWelcome displayName={user?.nome} />
        </Grid>
      </Grid>
      <Grid container spacing={3} mt={1}>
        <Grid item xs={6} md={4}>
          <AppTotalCompanies total={data?.companies.total} percent={data?.companies.growth} />
        </Grid>

        <Grid item xs={6} md={4}>
          <AppNfeTotal total={data?.nfe.total} percent={data?.nfe.growth} />
        </Grid>
      </Grid>
      <Grid container spacing={3} mt={1}>
        <Grid item xs={6} md={4}>
          <AppMemoryUsage data={data?.server.ram} />
        </Grid>
        <Grid item xs={6} md={4}>
          <AppCPUUsage data={data?.server.cpu} />
        </Grid>
      </Grid>
    </Container>
  </RootStyle>
);

export default View;

import { Icon } from '@iconify/react';
import React from 'react';
// material
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// routes
import { PATH_COMPANY } from 'routes/paths';
// @types
// components
import Scrollbar from 'components/Scrollbar';
import SearchNotFound from 'components/SearchNotFound';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { CompanyListHead, CompanyListToolbar } from 'components/_dashboard/companies';
import Page from 'components/Page';
import EmptyContent from 'components/EmptyContent';
import LoadingScreen from 'components/LoadingScreen';
import moment from 'moment';
import currencyBrl from 'utils/currencyBrl';
import fileAddFill from '@iconify/icons-eva/file-add-fill';
import { NfeViewProps } from '../Model';
import ImporterModal from '../../components/ImporterModal';

const View: React.FC<NfeViewProps> = ({
  TABLE_HEAD,
  currentTab,
  emptyRows,
  filter,
  filteredList,
  handleChangeRowsPerPage,
  handleFilter,
  isLoading,
  isNofilterResult,
  page,
  rowsPerPage,
  setCurrentTab,
  setPage,
  themeStretch,
  selected,
  handleModal,
  setHandleModal
}) => (
  <Page title="Importador">
    <Container maxWidth={themeStretch ? false : 'lg'}>
      <HeaderBreadcrumbs
        heading="Importar Notas Fiscais Eletrônicas"
        links={[{ name: 'Home', href: PATH_COMPANY.general.home }, { name: 'Importador' }]}
        action={
          <Button
            color="info"
            variant="contained"
            endIcon={<Icon icon={fileAddFill} />}
            sx={{ mx: 1 }}
            onClick={() => setHandleModal(true)}
          >
            Importar arquivos
          </Button>
        }
      />

      <ImporterModal handleModal={handleModal} setHandleModal={setHandleModal} />

      {isLoading ? (
        <div style={{ marginTop: '300px' }}>
          <LoadingScreen />
        </div>
      ) : (
        <Card>
          <CompanyListToolbar
            numSelected={selected.length}
            filterCompany={filter}
            onFilterCompany={handleFilter}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 900 }}>
              <Table>
                {!isNofilterResult || filter ? (
                  <>
                    {!isNofilterResult && <CompanyListHead headLabel={TABLE_HEAD} />}
                    <TableBody>
                      {filteredList
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const { id, emit_xnome, dest_xnome, tot_icms_vnf, nnf, updated_at } = row;

                          return (
                            <TableRow hover key={id} tabIndex={-1} role="checkbox">
                              <TableCell component="th" scope="row" padding="none">
                                {nnf}
                              </TableCell>
                              <TableCell style={{ minWidth: 100 }}>{emit_xnome}</TableCell>
                              <TableCell style={{ minWidth: 100 }}>{dest_xnome}</TableCell>
                              <TableCell style={{ minWidth: 100 }}>
                                {currencyBrl(tot_icms_vnf)}
                              </TableCell>

                              <TableCell style={{ maxWidth: 100 }}>
                                {updated_at ? moment(updated_at).format('DD/MM/YYYY') : ''}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isNofilterResult && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filter} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <EmptyContent title="Ops!!! Parece que não há nada para ver aqui." />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={(e) => handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por Página"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}–${to} de ${count !== -1 ? count : `more than ${to}`}`
            }
          />
        </Card>
      )}
    </Container>
  </Page>
);

export default View;

import { LoadingButton } from '@material-ui/lab';
import { Box, Button, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import InputMask from 'react-input-mask';
import { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import endpoints from 'services/request/endpoints';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_COMPANY } from 'routes/paths';
import { useSnackbar } from 'notistack5';
import { axiosInstance } from 'services/instance';
import { ViewBillingFormProps } from '../../Model';

export default function BillingForm({ data, method }: ViewBillingFormProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [errorCep, setErrorCep] = useState(false);
  const formik = useFormik({
    initialValues: {
      nome: '',
      cep: '',
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: ''
    },
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.integrator.changeDetails}`;

      const payload = {
        nome: values.nome,
        cobranca: {
          cobranca_cep: values.cep,
          cobranca_endereco: values.endereco,
          cobranca_complemento: values.complemento,
          cobranca_bairro: values.bairro,
          cobranca_numero: values.numero,
          cobranca_cidade: values.cidade,
          cobranca_estado: values.estado,
          metodo_pagamento: method.metodo_id
        }
      };

      try {
        const { status, data } = await axiosInstance.put(endpoint, payload);
        const message = data.message || 'Cadastro atualizado com sucesso!';

        if (status === 200) {
          enqueueSnackbar(message, {
            variant: 'success'
          });
        } else {
          enqueueSnackbar('Ocorreu um problema.', {
            variant: 'error'
          });
        }
      } catch (error: AxiosError<{ message: string }> | any) {
        const message = error.response?.data?.message || 'Ocorreu um erro!';

        setSubmitting(false);
        setErrors(error);
        enqueueSnackbar(message, {
          variant: 'error'
        });
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleGetCep = async (dataCep: string) => {
    const cep = dataCep.replace(/[^a-zA-Z0-9]/g, '');

    formik.setFieldValue('cep', cep);

    if (cep.length === 8) {
      const response = await axios.get(
        `https://viacep.com.br/ws/${cep.replace(/[^a-zA-Z0-9]/g, '')}/json/`
      );

      const { data: res } = response;

      if (res.erro) {
        setErrorCep(true);
      }

      formik.setFieldValue(
        'endereco',
        res.logradouro ? res.logradouro : data.cobranca.cobranca_endereco
      );
      formik.setFieldValue(
        'cidade',
        res.localidade ? res.localidade : data.cobranca.cobranca_cidade
      );
      formik.setFieldValue('bairro', res.bairro ? res.bairro : data.cobranca.cobranca_bairro);
      formik.setFieldValue('estado', res.uf ? res.uf : data.cobranca.cobranca_estado);

      if (data.cobranca) {
        formik.setFieldValue(
          'complemento',
          data.cobranca.cobranca_complemento ? data.cobranca.cobranca_complemento : data.complemento
        );
      }
    }
  };

  useEffect(() => {
    if (data) {
      formik.setFieldValue('nome', data.fantasia || data.razao);

      if (data.cobranca) {
        formik.setFieldValue('cep', data.cobranca.cobranca_cep);
        formik.setFieldValue('endereco', data.cobranca.cobranca_endereco);
        formik.setFieldValue('complemento', data.cobranca.cobranca_complemento);
        formik.setFieldValue('bairro', data.cobranca.cobranca_bairro);
        formik.setFieldValue('numero', data.cobranca.cobranca_numero);
        formik.setFieldValue('cidade', data.cobranca.cobranca_cidade);
        formik.setFieldValue('estado', data.cobranca.cobranca_estado);
      }
    }
  }, [data]);

  return (
    <Stack spacing={3} alignItems="flex-start">
      <Typography variant="overline" sx={{ mb: 3, display: 'block', color: 'text.secondary' }}>
        Dados de cobrança
      </Typography>

      <Container maxWidth="lg" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid item xs={12} md={8}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Nome"
                    {...getFieldProps('nome')}
                    error={Boolean(touched.nome && errors.nome)}
                    helperText={touched.nome && errors.nome}
                  />

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <InputMask
                      mask="99999-999"
                      disabled={false}
                      maskChar=" "
                      {...getFieldProps('cep')}
                      error={Boolean(touched.cep && errors.cep)}
                      helperText={touched.cep && errors.cep}
                      onChange={(e: any) => handleGetCep(e.target.value)}
                    >
                      {(inputprops: any) => <TextField fullWidth label="CEP" {...inputprops} />}
                    </InputMask>

                    {errorCep && (
                      <span style={{ fontSize: '12px', color: 'red' }}>CEP inválido.</span>
                    )}
                  </div>
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Endereço"
                    {...getFieldProps('endereco')}
                    error={Boolean(touched.endereco && errors.endereco)}
                    helperText={touched.endereco && errors.endereco}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Complemento"
                    {...getFieldProps('complemento')}
                    error={Boolean(touched.complemento && errors.complemento)}
                    helperText={touched.complemento && errors.complemento}
                  />

                  <TextField
                    fullWidth
                    label="Bairro"
                    {...getFieldProps('bairro')}
                    error={Boolean(touched.bairro && errors.bairro)}
                    helperText={touched.bairro && errors.bairro}
                  />

                  <TextField
                    fullWidth
                    label="Número"
                    {...getFieldProps('numero')}
                    error={Boolean(touched.numero && errors.numero)}
                    helperText={touched.numero && errors.numero}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Cidade"
                    {...getFieldProps('cidade')}
                    error={Boolean(touched.cidade && errors.cidade)}
                    helperText={touched.cidade && errors.cidade}
                  />

                  <TextField
                    fullWidth
                    label="Estado"
                    {...getFieldProps('estado')}
                    error={Boolean(touched.estado && errors.estado)}
                    helperText={touched.estado && errors.estado}
                  />
                </Stack>

                <Stack
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2, md: 2 }}
                >
                  <Box>
                    <Button
                      variant="outlined"
                      type="button"
                      color="primary"
                      component={RouterLink}
                      to={PATH_COMPANY.general.home}
                    >
                      Voltar
                    </Button>
                  </Box>
                  <Box>
                    <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                      Salvar
                    </LoadingButton>
                  </Box>
                </Stack>
              </Stack>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </Stack>
  );
}

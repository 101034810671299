import React, { useState } from 'react';

import { axiosInstance } from 'services/instance';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack5';
import View from './View';

import { NcmListProps } from './Model';

const ListNcm: React.FC<NcmListProps> = ({ ncmList, setNcmList, typePage }) => {
  const params = useParams();
  const [filter, setFilter] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [handleIsDialog, setHandleIsDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [ncmId, setNcmId] = useState(0);
  const [ncm, setNcm] = useState('');
  const [description, setDescription] = useState('');
  const [active, setActive] = useState(true);
  const [vigencStart, setVigencStart] = useState('');
  const [vigencEnd, setVigencEnd] = useState('');

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ncmList.length) : 0;

  const TABLE_HEAD = [
    { id: 'id', label: 'Cód.', alignRight: false },
    { id: 'ncm', label: 'NCM', alignRight: false },
    { id: 'descricao', label: 'Descrição', alignRight: false },
    { id: 'ativo', label: 'Status', alignRight: false },
    { id: 'vigencia_inicio', label: 'Vigência Inicio', alignRight: false },
    { id: 'vigencia_fim', label: 'Vigência Fim', alignRight: false },
    { id: '', label: '', alignRight: false }
  ];

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async (id: number) => {
    if (typePage !== 'Edit') {
      const temp = [...ncmList];
      const index = ncmList.findIndex((el) => el.id === id);
      temp.splice(index, 1);
      setNcmList(temp);

      setNcmId(0);
      setNcm('');
      setDescription('');
      setActive(false);
      setVigencStart('');
      setVigencEnd('');
    } else {
      setLoading(true);
      try {
        const { data } = await axiosInstance.delete(
          `administrative/table-port195/${params.id}/ncm/${id}`
        );

        enqueueSnackbar('Cadastro removido com sucesso!', {
          variant: 'success'
        });

        setNcmList(data.data);
        setLoading(false);
      } catch (error: any) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        setLoading(false);
      }
    }
  };

  const handleEdit = async (id: number) => {
    const selected = ncmList.find((el) => el.id === id);

    if (selected) {
      setHandleIsDialog(true);
      setNcmId(selected.id);
      setNcm(selected.ncm);
      setDescription(selected.descricao);
      setActive(selected.ativo);
      setVigencStart(selected.vigencia_inicio);
      setVigencEnd(selected.vigencia_fim);
    }
  };

  return (
    <View
      {...{
        TABLE_HEAD,
        emptyRows,
        filter,
        handleChangeRowsPerPage,
        handleDelete,
        handleEdit,
        isNofilterResult: false,
        page,
        rowsPerPage,
        setPage,
        handleIsDialog,
        setHandleIsDialog,
        ncmList,
        setNcmList,
        ncmId,
        setNcmId,
        ncm,
        setNcm,
        description,
        setDescription,
        active,
        setActive,
        vigencStart,
        setVigencStart,
        vigencEnd,
        setVigencEnd,
        typePage,
        loading
      }}
    />
  );
};

export default ListNcm;

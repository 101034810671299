import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack5';
import { Box, Button, Card, Divider, Link, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { PATH_COMPANY } from 'routes/paths';
import { axiosInstance } from 'services/instance';
import endpoints from 'services/request/endpoints';
import { useEffect, useState } from 'react';
import { capitalCase } from 'change-case';
import accounntCircle from '@iconify/icons-ic/account-circle';
import { PreviewProps } from '../model';

const Preview: React.FC<PreviewProps> = ({ previewData, isPix }) => {
  const { banksplit, transaction } = previewData;
  const { keypix, digitableline, bankslipurl, qrcodepix } = banksplit;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isPaymentOk, setIsPaymentOk] = useState(false);
  const [currentTab, setCurrentTab] = useState('boleto');

  async function consultPayment() {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.integrator.consultPayment}/${transaction}`;

    await axiosInstance
      .get(endpoint)
      .then(({ data }) => {
        if (data.status === 'Autorizado') {
          setIsPaymentOk(true);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }

  async function handleCopy(type: 'digitableline' | 'keypix') {
    await navigator.clipboard.writeText(type === 'digitableline' ? digitableline : keypix);

    const message =
      type === 'keypix'
        ? 'Chave copiada para a área de transferência'
        : 'Código do Boleto copiado para a área de transferência';
    enqueueSnackbar(message, { variant: 'success' });
  }

  useEffect(() => {
    if (isPix) {
      let interval;

      if (!isPaymentOk) {
        interval = setInterval(() => {
          consultPayment();
        }, 10000);
        return;
      }
      clearInterval(interval);
      enqueueSnackbar('Pagamento Efetuado com Sucesso!', { variant: 'success' });
      setTimeout(() => navigate(PATH_COMPANY.general.home), 2000);
    }
  }, [isPaymentOk]);

  if (isPix) {
    return (
      <Box
        sx={{
          maxWidth: '900px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Typography align="center" variant="h4">
          Para realizar o pagamento, escaneie o QR Code a seguir,
        </Typography>
        <Typography align="center" variant="h4">
          ou utilize a Chave PIX logo abaixo.
        </Typography>
        <Box sx={{ mt: 10 }}>
          <Button
            sx={{ textTransform: 'none' }}
            onClick={() => navigate(PATH_COMPANY.general.home)}
          >
            Voltar para a Home
          </Button>
          <Card
            sx={{
              padding: 7,
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              maxWidth: '700px'
            }}
          >
            <Divider />

            <img src={qrcodepix} style={{ height: '400px', flexShrink: 0 }} />

            <TextField
              id="digitableLine"
              sx={{ width: 240 }}
              value={keypix}
              InputProps={{
                endAdornment: (
                  <Box
                    sx={{
                      ':hover': {
                        cursor: 'pointer'
                      }
                    }}
                  >
                    <Icon
                      icon="material-symbols:content-copy-outline"
                      width="1.7rem"
                      height="1.7rem"
                      fontSize={400}
                      align="center"
                      onClick={() => handleCopy('digitableline')}
                    />
                  </Box>
                )
              }}
            />
          </Card>
        </Box>
      </Box>
    );
  }

  const PROFILE_TABS = [
    {
      value: 'boleto',
      icon: <Icon icon={accounntCircle} width={20} height={20} />,
      component: (
        <div
          style={{
            justifyContent: 'center',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography style={{ paddingBottom: '10px' }}>
            Para pagar seu Boleto, copie a linha digitável a seguir
          </Typography>
          <TextField
            style={{ paddingBottom: '20px' }}
            id="digitableLine"
            sx={{ width: 540 }}
            value={digitableline}
            InputProps={{
              endAdornment: (
                <Box
                  sx={{
                    ':hover': {
                      cursor: 'pointer'
                    }
                  }}
                >
                  <Icon
                    icon="material-symbols:content-copy-outline"
                    width="1.7rem"
                    height="1.7rem"
                    fontSize={400}
                    align="center"
                    onClick={() => handleCopy('digitableline')}
                  />
                </Box>
              )
            }}
          />
          <Typography style={{ paddingBottom: '10px' }}>ou</Typography>
          <Link target="_blank" href={bankslipurl}>
            Clique aqui para visualizar o PDF
          </Link>
        </div>
      )
    },
    {
      value: 'boleto-pix',
      icon: <Icon icon={accounntCircle} width={20} height={20} />,
      component: (
        <div
          style={{
            justifyContent: 'center',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <img src={qrcodepix} style={{ height: '400px', flexShrink: 0 }} />

          <Typography style={{ padding: '20px 0 10px 0' }}>Pix copia e cola</Typography>
          <TextField
            id="keypix"
            sx={{ width: 540 }}
            value={keypix}
            InputProps={{
              endAdornment: (
                <Box
                  sx={{
                    ':hover': {
                      cursor: 'pointer'
                    }
                  }}
                >
                  <Icon
                    icon="material-symbols:content-copy-outline"
                    width="1.7rem"
                    height="1.7rem"
                    fontSize={400}
                    align="center"
                    onClick={() => handleCopy('keypix')}
                  />
                </Box>
              )
            }}
          />
        </div>
      )
    }
  ];

  return (
    <Box
      sx={{
        maxWidth: '900px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <div style={{ padding: '0 0 10px 10px', display: 'flex', width: '100%' }}>
        <Button
          style={{
            textTransform: 'none'
          }}
          onClick={() => navigate(PATH_COMPANY.general.home)}
        >
          Voltar para a home
        </Button>
      </div>

      <Card
        sx={{
          padding: 6,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '700px',
          flexDirection: 'column'
        }}
      >
        <Typography align="center" variant="h5">
          Estamos aguardando o pagamento do Boleto bancário.
        </Typography>
        <Typography align="center" variant="h5">
          Após o pagamento, o Boleto poderá levar até dois dias úteis para ser compensado.
        </Typography>

        <Box sx={{ mb: 5 }} />

        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
        >
          {PROFILE_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={capitalCase(tab.value)}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {PROFILE_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Card>
    </Box>
  );
};

export default Preview;

/* eslint-disable no-unused-vars */
import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
// import Api from 'utils/api';
import { axiosInstance } from 'services/instance';
import { useSnackbar } from 'notistack5';

interface ExcludeProps {
  endpoint: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

interface sendDataProps {
  endpoint: string;
  payload: any;
  typePage: string;
  setSubmitting: any;
  setErrors: any;
}

const config = {
  confirmButtonColor: '#00AB55',
  cancelButtonColor: 'transparent',
  cancelButtonText: 'Não',
  confirmButtonText: 'Sim'
};

const useSweetAlert = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  async function exclude({ endpoint, setLoading }: ExcludeProps) {
    Swal.fire({
      title: 'Você realmente deseja deletar?',
      icon: 'warning',
      showCancelButton: true,
      ...config
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);

        try {
          const { status, data }: AxiosResponse = await axiosInstance.delete(endpoint);
          const message = data.message || 'Cadastro removido com sucesso!';
          if (status === 200) {
            enqueueSnackbar(message, {
              variant: 'success'
            });
            setLoading(false);
            navigate(0);
          }
        } catch (error: AxiosError<{ message: string }> | any) {
          const message = error.response?.data?.message || 'Ocorreu um erro!';
          setLoading(false);
          enqueueSnackbar(message, {
            variant: 'error'
          });
        }
      }
    });
  }

  async function sendData({
    endpoint,
    typePage,
    payload,
    setSubmitting,
    setErrors
  }: sendDataProps) {
    if (typePage === 'New') {
      try {
        const { status, data } = await axiosInstance.post(endpoint, payload);
        const message = data.message || 'Cadastro efetuado com sucesso!';

        if (status === 200) {
          enqueueSnackbar(message, {
            variant: 'success'
          });
          navigate(-1);
        } else {
          enqueueSnackbar(data.message || 'Ocorreu um problema.', {
            variant: 'error'
          });
        }
      } catch (error: AxiosError<{ message: string }> | any) {
        const message = error.response?.data?.message || 'Ocorreu um erro!';

        setSubmitting(false);
        setErrors(error);
        enqueueSnackbar(message, {
          variant: 'error'
        });
      }
    } else {
      try {
        const { status, data } = await axiosInstance.put(endpoint, payload);
        const message = data.message || 'Cadastro atualizado com sucesso!';

        if (status === 200) {
          enqueueSnackbar(message, {
            variant: 'success'
          });
          navigate(-1);
        } else {
          enqueueSnackbar('Ocorreu um problema.', {
            variant: 'error'
          });
        }
      } catch (error: AxiosError<{ message: string }> | any) {
        const message = error.response?.data?.message || 'Ocorreu um erro!';

        setSubmitting(false);
        setErrors(error);
        enqueueSnackbar(message, {
          variant: 'error'
        });
      }
    }
  }

  return { exclude, sendData };
};

export default useSweetAlert;

import { withStyles } from '@material-ui/styles';

// ----------------------------------------------------------------------

const GlobalStyles = withStyles((theme) => ({
  '@global': {
    '.swal2-container': {
      zIndex: 5000
    },

    '.swal2-html-container': {
      padding: '5px 0 10px 0',
      margin: '0'
    },

    '.swal2-title': {
      padding: '0',
      fontSize: '18px',
      fontWeight: 500
    },

    '.swal2-popup': {
      width: '28em'
    },

    '.swal2-actions': {
      margin: '0'
    },

    '.swal2-actions button': {
      borderRadius: '8px!important',
      boxShadow: '0 8px 16px 0 rgb(116 116 116 / 24%)',
      padding: '8px 22px'
    },

    '.swal2-styled.swal2-cancel': {
      color: '#00AB55',
      border: '1px solid rgba(0, 171, 85, 0.5)',
      boxShadow: 'none',
      fontWeight: 500,
      fontFamily: 'Public Sans,sans-serif'
    },

    '.swal2-styled.swal2-confirm': {
      fontWeight: 500,
      fontFamily: 'Public Sans,sans-serif'
    },

    '.MuiCircularProgress-root': {
      color: '#FFF'
    },

    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box'
    },
    html: {
      width: '100%',
      height: '100%',
      '-ms-text-size-adjust': '100%',
      '-webkit-overflow-scrolling': 'touch'
    },
    body: {
      width: '100%',
      height: '100%'
    },
    '#root': {
      width: '100%',
      height: '100%'
    },
    input: {
      '&[type=number]': {
        MozAppearance: 'textfield',
        '&::-webkit-outer-spin-button': { margin: 0, WebkitAppearance: 'none' },
        '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' }
      }
    },
    textarea: {
      '&::-webkit-input-placeholder': { color: theme.palette.text.disabled },
      '&::-moz-placeholder': { opacity: 1, color: theme.palette.text.disabled },
      '&:-ms-input-placeholder': { color: theme.palette.text.disabled },
      '&::placeholder': { color: theme.palette.text.disabled }
    },
    a: { color: theme.palette.primary.main },
    img: { display: 'block', maxWidth: '100%' },

    // Lazy Load Img
    '.blur-up': {
      WebkitFilter: 'blur(5px)',
      filter: 'blur(5px)',
      transition: 'filter 400ms, -webkit-filter 400ms'
    },
    '.blur-up.lazyloaded ': {
      WebkitFilter: 'blur(0)',
      filter: 'blur(0)'
    }
  }
}))(() => null);

export default GlobalStyles;

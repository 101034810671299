import { Icon } from '@iconify/react';
import React from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';

// material
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination
} from '@material-ui/core';

// components
import Scrollbar from 'components/Scrollbar';
import SearchNotFound from 'components/SearchNotFound';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { CompanyListHead } from 'components/_dashboard/companies';
import EmptyContent from 'components/EmptyContent';
import moment from 'moment';
import { NcmViewProps } from '../Model';
import MoreMenu from '../../components/MoreMenu';
import NewNcm from '../../new';

const View: React.FC<NcmViewProps> = ({
  TABLE_HEAD,
  emptyRows,
  filter,
  handleChangeRowsPerPage,
  handleDelete,
  handleEdit,
  isNofilterResult,
  page,
  rowsPerPage,
  setPage,
  handleIsDialog,
  setHandleIsDialog,
  ncmList,
  setNcmList,
  ncmId,
  setNcmId,
  ncm,
  setNcm,
  description,
  setDescription,
  active,
  setActive,
  vigencStart,
  setVigencStart,
  vigencEnd,
  setVigencEnd,
  typePage,
  loading
}) => {
  const renderDialogNcm = () => (
    <NewNcm
      handleIsDialog={handleIsDialog}
      setHandleIsDialog={setHandleIsDialog}
      ncmList={ncmList}
      setNcmList={setNcmList}
      ncmId={ncmId}
      setNcmId={setNcmId}
      ncm={ncm}
      setNcm={setNcm}
      description={description}
      setDescription={setDescription}
      active={active}
      setActive={setActive}
      vigencStart={vigencStart}
      setVigencStart={setVigencStart}
      vigencEnd={vigencEnd}
      setVigencEnd={setVigencEnd}
      typePage={typePage}
    />
  );

  return (
    <Container style={{ padding: '24px 0 0 0' }}>
      <HeaderBreadcrumbs
        heading="Listagem de NCM'S"
        links={[]}
        action={
          <Button
            variant="contained"
            onClick={() => setHandleIsDialog(!handleIsDialog)}
            startIcon={<Icon icon={plusFill} />}
          >
            Novo NCM
          </Button>
        }
      />

      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 900 }}>
            <Table style={{ marginTop: '10px' }}>
              {ncmList.length > 0 ? (
                <>
                  <CompanyListHead headLabel={TABLE_HEAD} />
                  <TableBody>
                    {ncmList
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const { id, ncm, descricao, ativo, vigencia_inicio, vigencia_fim } = row;

                        return (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox">
                            <TableCell component="th" scope="row" padding="none">
                              {id}
                            </TableCell>
                            <TableCell style={{ minWidth: 100 }}>{ncm}</TableCell>
                            <TableCell style={{ minWidth: 100 }}>{descricao}</TableCell>
                            <TableCell style={{ minWidth: 100 }}>
                              {ativo ? 'Ativo' : 'Inativo'}
                            </TableCell>
                            <TableCell style={{ maxWidth: 100 }}>
                              {vigencia_inicio ? moment(vigencia_inicio).format('DD/MM/YYYY') : ''}
                            </TableCell>
                            <TableCell style={{ maxWidth: 100 }}>
                              {vigencia_fim ? moment(vigencia_fim).format('DD/MM/YYYY') : ''}
                            </TableCell>

                            <TableCell align="right">
                              <MoreMenu
                                onDelete={() => handleDelete(id)}
                                onEdit={() => handleEdit(id)}
                                loading={loading}
                                id={id}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isNofilterResult && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filter} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <EmptyContent title="Ops!!! Parece que não há nada para ver aqui." />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={ncmList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={(e) => handleChangeRowsPerPage}
        />
      </Card>

      {renderDialogNcm()}
    </Container>
  );
};

export default View;

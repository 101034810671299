import * as React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  Button,
  Stack,
  Card,
  Modal
} from '@mui/material';
import { CompanyListToolbar } from 'components/_dashboard/companies';
import { CompaniesListModalProps } from '../Model';

export default function CompaniesListModal({ companies }: CompaniesListModalProps) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filter, setFilter] = React.useState('');
  const [isOpenModal, setIsOpenModal] = React.useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (filter: string) => {
    setFilter(filter);
  };

  const filteredCompanies = companies.filter((company) =>
    company?.razao.toLowerCase().includes(`${filter}`.toLowerCase())
  );

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - companies.length) : 0;

  return (
    <>
      {companies.length > 0 && companies[0] === null ? (
        <Button onClick={() => {}}>Todas</Button>
      ) : (
        <Button onClick={() => setIsOpenModal(true)}>Listar</Button>
      )}
      <Modal open={isOpenModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: 500
          }}
        >
          <Card>
            <Box sx={{ width: '100%' }}>
              <Paper sx={{ width: '100%' }}>
                <TableContainer>
                  <Typography
                    sx={{ flex: '1 1 100%', marginLeft: 3, marginTop: 3, marginBottom: 1 }}
                    variant="h4"
                    component="div"
                  >
                    Empresas Vinculadas
                  </Typography>
                  <CompanyListToolbar
                    numSelected={-1}
                    filterCompany={filter}
                    onFilterCompany={handleFilter}
                  />
                  <Table
                    sx={{ minWidth: 750, marginTop: 1, maxHeight: '20vh', overflowY: 'auto' }}
                    aria-labelledby="tableTitle"
                    size="small"
                  >
                    <TableBody>
                      {filteredCompanies
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const { razao, cnpj } = row;
                          return (
                            <TableRow
                              hover
                              key={index}
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 2,
                                alignItems: 'center',
                                p: 2
                              }}
                            >
                              <TableCell scope="row" sx={{ padding: 0 }}>
                                {razao} - {cnpj}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 33 * emptyRows
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  sx={{ marginTop: 4 }}
                  rowsPerPageOptions={[10, 15, 25]}
                  component="div"
                  count={companies.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}–${to} de ${count !== -1 ? count : ''}`
                  }
                  labelRowsPerPage="Linhas por Página"
                />

                <Stack
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2, md: 2 }}
                  sx={{ padding: 2 }}
                >
                  <Button
                    variant="outlined"
                    type="button"
                    color="primary"
                    onClick={() => setIsOpenModal(false)}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Paper>
            </Box>
          </Card>
        </Box>
      </Modal>
    </>
  );
}

import { Icon as IconIconify } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
// material
import { styled } from '@material-ui/core/styles';
import { Card, Button, Typography, Box, Stack } from '@material-ui/core';
// routes
import { PATH_COMPANY } from 'routes/paths';
//
import currencyBrl from 'utils/currencyBrl';
import { useContextCompany } from 'contexts/CompanyContext';
import { licencesProps } from '../model/index';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(5)
  }
}));

// ----------------------------------------------------------------------

export type PricingPlanCardProps = {
  card: {
    subscription: string;
    price: number;
    caption?: string;
    icon: JSX.Element;
    labelAction: string;
    lists: {
      text: string;
      isAvailable: boolean;
    }[];
    licence: licencesProps;
  };
  index: number;
  period: string;
  handleNext: () => void;
};

export default function PricingPlanCard({ card, period, handleNext }: PricingPlanCardProps) {
  const { subscription, caption, lists, labelAction, price, licence } = card;
  const { company, setCompany } = useContextCompany();

  const handleCheckout = () => {
    const plan = {
      cobranca: {
        plano_id: licence.id
      }
    };

    if (licence.id) {
      setCompany({ ...company, ...plan });
      handleNext();
    }
  };

  return (
    <RootStyle>
      <Typography variant="overline" sx={{ color: 'text.secondary' }}>
        {subscription}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2, alignItems: 'center' }}>
        {price !== 0 ? (
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            R$
          </Typography>
        ) : (
          ''
        )}
        <Typography variant="h2" sx={{ mx: 1 }}>
          {price === 0 ? 'Gratuito' : currencyBrl(price).replace('R$', '')}
        </Typography>
        {price !== 0 ? (
          <Typography
            gutterBottom
            component="span"
            variant="subtitle2"
            sx={{
              alignSelf: 'flex-end',
              color: 'text.secondary'
            }}
          >
            /mês
          </Typography>
        ) : (
          ''
        )}
      </Box>

      <Typography
        variant="caption"
        sx={{
          color: 'primary.main',
          textTransform: 'capitalize'
        }}
      >
        {caption}
      </Typography>

      <Stack component="ul" spacing={2} sx={{ my: 5, width: 1 }}>
        {lists.map((item) => (
          <Stack
            key={item.text}
            component="li"
            direction="row"
            alignItems="center"
            spacing={1.5}
            sx={{ typography: 'body2', color: item.isAvailable ? 'text.primary' : 'text.disabled' }}
          >
            {item.isAvailable ? (
              <>
                <Box component={IconIconify} icon={checkmarkFill} sx={{ width: 20, height: 20 }} />
                <Typography variant="body2">{item.text}</Typography>
              </>
            ) : (
              <>
                <IconIconify icon="ph:x-duotone" width={20} height={20} />
                <Typography variant="body2">{item.text}</Typography>
              </>
            )}
          </Stack>
        ))}
      </Stack>

      <Button fullWidth size="large" variant="contained" onClick={() => handleCheckout()}>
        {labelAction}
      </Button>
    </RootStyle>
  );
}

// material
import { Box, Grid, Card, Button, Typography, Stack } from '@material-ui/core';
// @types
//
import { Link, useNavigate } from 'react-router-dom';
import LoadingScreen from 'components/LoadingScreen';
import { PATH_COMPANY } from 'routes/paths';
import AccountBillingInvoiceHistory from './AccountBillingInvoiceHistory';
import BillingForm from './BillingForm';
import { ViewAccountBillingProps } from '../../Model';
import PaymentMethods from './paymentMethods';
import { RootStyle } from '../styles';
// ----------------------------------------------------------------------

export default function AccountBilling({
  data,
  paymentOptions,
  method,
  setMethod,
  isSubmitted,
  setIsSubmitted,
  isNewCard,
  setIsNewCard,
  isLoading
}: ViewAccountBillingProps) {
  const navigate = useNavigate();
  if (isLoading) {
    return (
      <RootStyle>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%', flexGrow: 1 }}
        >
          <LoadingScreen />
        </Stack>
      </RootStyle>
    );
  }

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={7}>
        <Stack spacing={3}>
          <Card sx={{ p: 3 }}>
            <Typography
              variant="overline"
              sx={{ mb: 3, display: 'block', color: 'text.secondary' }}
            >
              Plano atual
            </Typography>
            <Typography variant="h4">{data.cobranca?.plano?.descricao}</Typography>
            <Box
              sx={{
                mt: { xs: 2, sm: 0 },
                position: { sm: 'absolute' },
                top: { sm: 24 },
                right: { sm: 24 }
              }}
            >
              <Button size="small" color="inherit" variant="outlined" sx={{ mr: 1 }}>
                Cancelar plano
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={() => navigate(PATH_COMPANY.general.upgrade)}
              >
                Upgrade
              </Button>
            </Box>
          </Card>

          <Card sx={{ p: 3 }}>
            <PaymentMethods
              paymentOptions={paymentOptions}
              method={method}
              setMethod={setMethod}
              isSubmitted={isSubmitted}
              setIsSubmitted={setIsSubmitted}
              isNewCard={isNewCard}
              setIsNewCard={setIsNewCard}
              data={data}
            />
          </Card>

          <Card sx={{ p: 3 }}>
            <BillingForm data={data} method={method} />
          </Card>
        </Stack>
      </Grid>

      <Grid item xs={12} md={5}>
        <Stack>
          <Card sx={{ p: 3 }} style={{ height: '1080px', overflowY: 'auto' }}>
            <AccountBillingInvoiceHistory historic={data.cobranca?.historico} />
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
}

import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Stack, Link, Container, Typography } from '@material-ui/core';
// routes
import Logo from 'components/Logo';
import { PATH_AUTH } from '../../routes/paths';
// hooks
// layouts
// components
import Page from '../../components/Page';
import { MenuHidden } from '../../components/@material-extend';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login TRIB">
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="column" alignItems="center" sx={{ mb: 5 }}>
            <Logo sx={{ width: 300, height: 300, alignSelf: 'center' }} />
            <Box sx={{ flexGrow: 1 }}>
              <Typography sx={{ color: 'text.secondary' }}>Entre com suas credenciais.</Typography>
            </Box>
          </Stack>

          {/* <LoginForm /> */}
          <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            Não possui cadastro?&nbsp;
            <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
              Cadastre-se aqui!
            </Link>
          </Typography>

          <MenuHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Não possui cadastro?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                Cadastre-se aqui!
              </Link>
            </Typography>
          </MenuHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}

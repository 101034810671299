import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Box,
  Card,
  Container,
  Typography,
  Grid,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
  FormGroup,
  Button
} from '@material-ui/core';

import { LoadingButton } from '@material-ui/lab';
import InputMask from 'react-input-mask';
import { useEffect } from 'react';
import DatePicker from '@material-ui/lab/DatePicker';
import LoadingScreen from 'components/LoadingScreen';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_ADMIN } from 'routes/paths';
import { useNavigate } from 'react-router';
import endpoints from 'services/request/endpoints';
import useSweetAlert from 'hooks/useSweetAlert';
import { NewConv5291ViewProps } from './Model';

const View: React.FC<NewConv5291ViewProps> = ({ loading, typePage, selected }) => {
  const navigate = useNavigate();
  const { sendData } = useSweetAlert();

  const NewSchema = Yup.object().shape({
    item: Yup.string().required('O Item é obrigatório.'),
    cod: Yup.string().required('O Código é obrigatório.'),
    ncm: Yup.string().required('O NCM é obrigatório.'),
    description: Yup.string().required('A Descrição é obrigatória.'),
    vigenc_start: Yup.date().nullable().required('A data da vigência inicial é obrigatória.'),
    vigenc_end: Yup.date()
      .required('A data da vigência final é obrigatória.')
      .nullable()
      .min(Yup.ref('vigenc_start'), 'A data de término deve ser posterior à data de início')
  });

  const UpdateSchema = Yup.object().shape({
    item: Yup.string().required('O Item é obrigatório.'),
    cod: Yup.string().required('O Código é obrigatório.'),
    ncm: Yup.string().required('O NCM é obrigatório.'),
    description: Yup.string().required('A Descrição é obrigatória.'),
    vigenc_start: Yup.date().nullable().required('A data da vigência inicial é obrigatória.'),
    vigenc_end: Yup.date()
      .required('A data da vigência final é obrigatória.')
      .nullable()
      .min(Yup.ref('vigenc_start'), 'A data de término deve ser posterior à data de início')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      item: '',
      cod: '',
      ncm: '',
      description: '',
      active: true,
      vigenc_start: '',
      vigenc_end: ''
    },
    validationSchema: typePage === 'New' ? NewSchema : UpdateSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      const endpoint =
        typePage === 'New'
          ? `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.conv5291}`
          : `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.conv5291}/${selected?.id}`;

      const payload = {
        item: values.item,
        codigo: values.cod,
        ncm: values.ncm,
        descricao: values.description,
        ativo: values.active,
        vigencia_inicio: values.vigenc_start,
        vigencia_fim: values.vigenc_end
      };

      sendData({
        typePage,
        payload,
        endpoint,
        setSubmitting,
        setErrors
      });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  useEffect(() => {
    if (selected) {
      formik.setFieldValue('item', selected.item);
      formik.setFieldValue('cod', selected.codigo);
      formik.setFieldValue('ncm', selected.ncm);
      formik.setFieldValue('description', selected.descricao);
      formik.setFieldValue('active', selected.ativo);
      formik.setFieldValue('vigenc_start', selected.vigencia_inicio);
      formik.setFieldValue('vigenc_end', selected.vigencia_fim);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <Page title={typePage === 'New' ? 'Novo Convênio 5291' : 'Grupo'}>
      <Container>
        <HeaderBreadcrumbs
          heading="Convênio 5291"
          links={[
            { name: 'Convênio 5291', href: PATH_ADMIN.general.administrative.tables.conv5291s },
            {
              name: typePage === 'New' ? 'Novo Convênio 5291' : formik.getFieldProps('item').value
            }
          ]}
        />

        {loading ? (
          <div style={{ marginTop: '300px' }}>
            <LoadingScreen />
          </div>
        ) : (
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <Typography variant="h5">Dados</Typography>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                        <InputMask
                          formatChars={{ '9': '[0-9.]' }}
                          mask="9999999999"
                          disabled={false}
                          maskChar=""
                          {...getFieldProps('item')}
                          error={Boolean(touched.item && errors.item)}
                          helperText={touched.item && errors.item}
                        >
                          {(inputProps: any) => (
                            <TextField fullWidth label="Item" {...inputProps} />
                          )}
                        </InputMask>

                        <InputMask
                          formatChars={{ '9': '[0-9.]' }}
                          mask="9999999999"
                          disabled={false}
                          maskChar=""
                          {...getFieldProps('cod')}
                          error={Boolean(touched.cod && errors.cod)}
                          helperText={touched.cod && errors.cod}
                        >
                          {(inputProps: any) => (
                            <TextField fullWidth label="Código" {...inputProps} />
                          )}
                        </InputMask>
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                        <InputMask
                          formatChars={{ '9': '[0-9.]' }}
                          mask="9999999999"
                          disabled={false}
                          maskChar=""
                          {...getFieldProps('ncm')}
                          error={Boolean(touched.ncm && errors.ncm)}
                          helperText={touched.ncm && errors.ncm}
                        >
                          {(inputProps: any) => <TextField fullWidth label="NCM" {...inputProps} />}
                        </InputMask>
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="Descrição"
                          {...getFieldProps('description')}
                          error={Boolean(touched.description && errors.description)}
                          helperText={touched.description && errors.description}
                          multiline
                          rows={2}
                        />
                      </Stack>

                      <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                        <DatePicker
                          label="Data inicial da vigência"
                          {...getFieldProps('vigenc_start')}
                          onChange={(date) => formik.setFieldValue('vigenc_start', date)}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              error={Boolean(touched.vigenc_start && errors.vigenc_start)}
                            />
                          )}
                          inputFormat="dd/MM/yyyy"
                        />

                        <DatePicker
                          label="Data final da vigência"
                          {...getFieldProps('vigenc_end')}
                          onChange={(date) => formik.setFieldValue('vigenc_end', date)}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              error={Boolean(touched.vigenc_end && errors.vigenc_end)}
                            />
                          )}
                          inputFormat="dd/MM/yyyy"
                        />
                      </Stack>

                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={formik.getFieldProps('active').value}
                              onChange={(event, value) => formik.setFieldValue('active', value)}
                            />
                          }
                          label="Ativo"
                        />
                      </FormGroup>
                    </Stack>

                    <Stack
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1, sm: 2, md: 2 }}
                    >
                      <Box sx={{ mt: 3, display: 'flex' }}>
                        <Button
                          variant="outlined"
                          type="button"
                          color="primary"
                          onClick={() => navigate(-1)}
                        >
                          Cancelar
                        </Button>
                      </Box>
                      <Box sx={{ mt: 3, display: 'flex' }}>
                        <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                          {typePage === 'New' ? 'Cadastrar' : 'Atualizar'}
                        </LoadingButton>
                      </Box>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        )}
      </Container>
    </Page>
  );
};

export default View;

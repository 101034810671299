import { createSlice, Reducer } from '@reduxjs/toolkit';
import { CheckoutStateProps } from '../../models/integrator/index';

const initialState: CheckoutStateProps = {
  id: 0,
  descricao: '',
  data_inicio: '',
  data_expira: '',
  valor: 0,
  desconto_trimestral: 0,
  desconto_anual: 0,
  quantidade_cnpj: 0,
  periodo: ''
};

interface PayloadCheckoutProps {
  payload: CheckoutStateProps;
}

const checkoutIntegratorSlice = createSlice({
  name: 'checkoutIntegrator',
  initialState,
  reducers: {
    makeCheckout: (state, { payload }: PayloadCheckoutProps) => {
      state = payload;
      return state;
    }
  }
});

export const { makeCheckout } = checkoutIntegratorSlice.actions;

export const CheckoutSelector = (state: any) => state.Checkout;

export default checkoutIntegratorSlice.reducer as Reducer<typeof initialState>;

import useSettings from 'hooks/useSettings';
import React, { useEffect, useState } from 'react';
import endpoints from 'services/request/endpoints';
import { axiosInstance } from 'services/instance';

import View from './View';

const ListNfe: React.FC = () => {
  const { themeStretch } = useSettings();
  const [selected, setSelected] = useState<string[]>([]);
  const [list, setList] = useState<any[]>([]);
  const [filter, setFilter] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [currentTab, setCurrentTab] = useState('active');
  const [loading, setLoading] = useState(false);
  const [handleModal, setHandleModal] = useState(false);

  const getList = async () => {
    const endpoint = process.env.REACT_APP_BASE_URL + endpoints.administrative.listNfe;
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(endpoint);

      setList(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const TABLE_HEAD = [
    { id: 'nnf', label: 'Cód. NFe', alignRight: false },
    { id: 'emit_xnome', label: 'Emissor', alignRight: false },
    { id: 'dest_xnome', label: 'Destinatário', alignRight: false },
    { id: 'tot_icms_vnf', label: 'Valor', alignRight: false },
    { id: 'created_at', label: 'Importado em', alignRight: false },
    { id: '', label: '', alignRight: false }
  ];

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (filter: string) => {
    setFilter(filter);
  };

  const filteredList = list.filter((el) => {
    const { emit_xnome, dest_xnome, tot_icms_vnf, nnf } = el;

    return (
      `${emit_xnome}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
      `${dest_xnome}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
      `${tot_icms_vnf}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
      `${nnf}`.toLowerCase().includes(`${filter}`.toLowerCase())
    );
  });

  const isNofilterResult = filteredList.length === 0;

  return (
    <View
      {...{
        themeStretch,
        selected,
        filteredList,
        TABLE_HEAD,
        handleFilter,
        isLoading: loading,
        isNofilterResult,
        page,
        rowsPerPage,
        setCurrentTab,
        currentTab,
        emptyRows,
        filter,
        handleChangeRowsPerPage,
        setPage,
        handleModal,
        setHandleModal
      }}
    />
  );
};

export default ListNfe;

import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader } from '@material-ui/core';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important' as 'relative',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

// ----------------------------------------------------------------------

const CHART_DATA = [12244, 53345, 44313, 78343];

export default function AppCPUUsage({ data }: { data: string | undefined }) {
  const theme = useTheme();

  // eslint-disable-next-line consistent-return
  // @ts-ignore
  function createObject(data) {
    // @ts-ignore
    const lines = data.split('\n').filter((line) => line.trim() !== '');
    const obj = {};

    for (const line of lines) {
      const [key, value] = line.split(/\t:\s?/);
      const keyWithUnderscore = key.replace(/\s/g, '_'); // Replace spaces with underscores
      // @ts-ignore
      obj[keyWithUnderscore] = value;
    }

    return obj;
  }

  if (data) {
    // @ts-ignore
    const result:
      | {
          processor: string;
          vendor_id: string;
          cpu_family: string;
          model_: string;
          model_name: string;
          stepping: string;
          microcode: string;
          cpu_MHz_: string;
          cache_size: string;
          physical_id: string;
          siblings: string;
          core_id_: string;
          cpu_cores: string;
          apicid_: string;
          initial_apicid: string;
          fpu_: string;
          fpu_exception: string;
          cpuid_level: string;
          wp_: string;
          flags_: string;
          bogomips: string;
          clflush_size: string;
          cache_alignment: string;
          address_sizes: string;
        }
      | undefined = createObject(data);

    const chartOptions = merge(BaseOptionChart(), {
      colors: [
        theme.palette.primary.lighter,
        theme.palette.primary.light,
        theme.palette.primary.main,
        theme.palette.primary.dark
      ],
      labels: ['Mac', 'Window', 'iOS', 'Android'],
      stroke: { colors: [theme.palette.background.paper] },
      legend: { floating: true, horizontalAlign: 'center' },
      tooltip: {
        fillSeriesColor: false,
        y: {
          formatter: (seriesName: string) => fNumber(seriesName),
          title: {
            formatter: (seriesName: string) => `${seriesName}`
          }
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '90%',
            labels: {
              value: {
                formatter: (val: number | string) => fNumber(val)
              },
              total: {
                formatter: (w: { globals: { seriesTotals: number[] } }) => {
                  const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                  return fNumber(sum);
                }
              }
            }
          }
        }
      }
    });

    return (
      <Card>
        <CardHeader title="CPU" />
        <CardContent>
          <p>
            <b>Processador </b> {result?.model_name || ''}
          </p>
          <p>
            <b>MHz</b> {result?.cpu_MHz_ || ''}
          </p>
          <p>
            <b>Cores</b> {result?.cpu_cores || ''}
          </p>
          <p>
            <b>Fabricante</b> {result?.vendor_id || ''}
          </p>
          <p>
            <b>Tam. Cache</b> {result?.cache_size || ''}
          </p>
        </CardContent>
      </Card>
    );
  }
  return null;
}

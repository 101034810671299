// routes
import { PATH_CLIENT, PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  register: getIcon('ic_register'),
  calculator: getIcon('ic_calculator'),
  tax: getIcon('ic_tax'),
  aliquot: getIcon('ic_aliquot'),
  analytics: getIcon('ic_analytics'),
  resources: getIcon('ic_resources'),
  key: getIcon('ic_key'),
  whatsapp: getIcon('ic_whatsapp'),
  users: getIcon('ic_users')
};

const sidebarConfig = [
  {
    subheader: 'ADMINSITRATIVO',
    items: [
      // {
      //   title: 'Usuários',
      //   path: PATH_DASHBOARD.registers.root,
      //   icon: ICONS.users,
      //   children: [
      //     { title: 'Grupo Permissões', path: PATH_CLIENT.general.management.groups },
      //     { title: 'Usuários', path: PATH_CLIENT.general.administrative.users }
      //   ]
      // },
      {
        title: 'Recursos',
        path: '',
        icon: ICONS.resources,
        children: [
          { title: 'Importador', path: PATH_CLIENT.general.administrative.xml },
          { title: 'Analisador', path: PATH_CLIENT.general.administrative.analyzer }
        ]
      }
    ]
  }
];

export default sidebarConfig;

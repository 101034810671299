import { useEffect } from 'react';
import {
  Typography,
  Box,
  Radio,
  RadioGroup,
  Paper,
  FormControlLabel,
  Stack,
  TextField,
  Grid
} from '@mui/material';
import { Icon } from '@iconify/react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import { LoadingButton } from '@material-ui/lab';
import useSweetAlert from 'hooks/useSweetAlert';
import endpoints from 'services/request/endpoints';
import { PaymentMethodsProps } from '../../Model';

const options = [
  { id: 1, label: 'Cartão de Crédito', icon: 'material-symbols:credit-card-outline' },
  {
    id: 2,
    label: 'Pix',
    icon: 'fa6-brands:pix'
  },
  {
    id: 3,
    label: 'Boleto',
    icon: 'mdi:barcode-scan'
  }
];

const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  setMethod,
  method,
  isSubmitted,
  setIsSubmitted,
  paymentOptions,
  isNewCard,
  setIsNewCard,
  data
}) => {
  const { sendData } = useSweetAlert();

  const ValidationSchema = Yup.object().shape({
    cardName: Yup.string().required('O Nome do Titular do Cartão é Obrigatório.'),
    cardNumber: Yup.string().required('O Número do Cartão é Obrigatório.'),
    expirationDate: Yup.string().nullable().required('A Data de Validade é Obrigatória.'),
    cvv: Yup.string().required('O Código de Verificação é Obrigatório.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cardName: '',
      cardNumber: '',
      expirationDate: null,
      cvv: ''
    },
    validationSchema: ValidationSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.integrator.creditCard}`;

      const payload = {
        nome: values.cardName,
        numero: values.cardNumber,
        expira: values.expirationDate,
        codigo: values.cvv
      };

      sendData({
        typePage: 'update',
        payload,
        endpoint,
        setSubmitting,
        setErrors
      });
    }
  });

  const { getFieldProps, errors, touched, handleSubmit } = formik;

  useEffect(() => {
    if (method.metodo_id === '2') {
      if (isNewCard === 'newCard') {
        handleSubmit();
      }
      setIsSubmitted(false);
    }
  }, [isSubmitted]);

  useEffect(() => {
    if (data) {
      if (data.cobranca) {
        formik.setFieldValue('cardName', data.cobranca.cartao_nome || '');
        formik.setFieldValue('cardNumber', data.cobranca.cartao_numero || '');
        formik.setFieldValue('expirationDate', data.cobranca.cartao_expira || '');
        formik.setFieldValue('cvv', data.cobranca.cartao_codigo || '');
      }
    }
  }, [data]);

  return (
    <>
      <Typography variant="overline" sx={{ mb: 3, display: 'block', color: 'text.secondary' }}>
        Forma de pagamento
      </Typography>

      <RadioGroup
        sx={{ display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'start' }}
        value={method.metodo_id}
        onChange={(event, value) => {
          const selectedMethod = paymentOptions.filter((option) => option.metodo_id === value)[0];

          const { metodo_id, metodo_nome } = selectedMethod;

          setMethod({
            metodo_id,
            metodo_nome
          });
        }}
      >
        {paymentOptions.map(({ metodo_id, metodo_nome }) => (
          <Paper
            key={metodo_id}
            variant="outlined"
            sx={{
              width: '100%',
              padding: 3,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 3
            }}
          >
            <Stack direction="row" sx={{ width: '100%' }}>
              <FormControlLabel
                sx={{ width: '100%' }}
                value={metodo_id}
                control={<Radio />}
                label={
                  <Box id="labelContainer" sx={{ marginLeft: 2, width: '100%' }}>
                    <Typography fontSize="0.875rem" fontWeight={600}>
                      {metodo_nome}
                    </Typography>
                  </Box>
                }
              />
              {metodo_nome === 'Cartão de Crédito' && (
                <Icon icon={options[0].icon} width={50} height={50} />
              )}
              {metodo_nome === 'Pix Recebimento' && (
                <Icon icon={options[1].icon} width={50} height={50} />
              )}
              {metodo_nome === 'Boleto' && <Icon icon={options[2].icon} width={50} height={50} />}
            </Stack>

            {method.metodo_id === '2' && metodo_id === '2' && (
              <>
                <FormikProvider value={formik}>
                  <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Box>
                      <Grid container direction="column" spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            label="Nome no Cartão"
                            fullWidth
                            disabled={false}
                            {...getFieldProps('cardName')}
                            error={Boolean(errors.cardName && touched.cardName)}
                            helperText={touched.cardName && errors.cardName}
                            sx={{ fontSize: '0.875rem' }}
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <InputMask
                            mask="9999 9999 9999 9999"
                            disabled={false}
                            maskChar=""
                            {...getFieldProps('cardNumber')}
                            error={Boolean(errors.cardNumber && touched.cardNumber)}
                            helperText={touched.cardNumber && errors.cardNumber}
                          >
                            {(props: any) => (
                              <TextField
                                label="Número do Cartão"
                                fullWidth
                                {...props}
                                sx={{ fontSize: '0.875rem' }}
                                size="small"
                              />
                            )}
                          </InputMask>
                        </Grid>
                        <Grid item container direction="row" spacing={3} xs={12}>
                          <Grid item xs={6}>
                            <InputMask
                              mask="999"
                              disabled={false}
                              maskChar=""
                              {...getFieldProps('cvv')}
                              error={Boolean(errors.cvv && touched.cvv)}
                              helperText={touched.cvv && errors.cvv}
                            >
                              {(props: any) => (
                                <TextField
                                  label="CVV"
                                  fullWidth
                                  {...props}
                                  sx={{ fontSize: '0.875rem' }}
                                  size="small"
                                />
                              )}
                            </InputMask>
                          </Grid>
                          <Grid item xs={6}>
                            <InputMask
                              formatChars={{ '1': '[0-1]', '9': '[0-9]' }}
                              mask="19/9999"
                              disabled={false}
                              maskChar=""
                              {...getFieldProps('expirationDate')}
                              error={Boolean(errors.expirationDate && touched.expirationDate)}
                              helperText={touched.expirationDate && errors.expirationDate}
                            >
                              {(props: any) => (
                                <TextField
                                  label="Data de Validade"
                                  fullWidth
                                  {...props}
                                  placeholder="mm/aaaa"
                                  sx={{ fontSize: '0.875rem' }}
                                  size="small"
                                />
                              )}
                            </InputMask>
                          </Grid>

                          <Box
                            sx={{
                              mt: 3,
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'flex-end'
                            }}
                          >
                            <LoadingButton variant="contained" type="submit">
                              Atualizar cartão
                            </LoadingButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Form>
                </FormikProvider>
              </>
            )}
          </Paper>
        ))}
      </RadioGroup>
    </>
  );
};

export default PaymentMethods;

import { useState, useEffect, ChangeEvent, MouseEvent } from 'react';
import { CompanyListToolbar } from 'components/_dashboard/companies';
import {
  Button,
  Stack,
  Checkbox,
  Paper,
  Typography,
  TableRow,
  TablePagination,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Box
} from '@material-ui/core';
import { Company, CompanyModalProps } from 'screens/admin/tables/customRules/new/Model';

export default function CompaniesList({
  companies,
  setIsOpenModal,
  setCompaniesValue,
  companiesValue
}: CompanyModalProps) {
  const [selected, setSelected] = useState<Company[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState('');

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // const newSelected = companies.map((n) => n);
      const newCompaniesAllSelected = companies.map((company) => ({
        id: company.id,
        razao: company.razao,
        fantasia: company.fantasia,
        cnpj: company.cnpj,
        selected: false
      }));

      setSelected(newCompaniesAllSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, company: Company) => {
    const selectedIndex = selected.indexOf(company);
    let newSelected: Company[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, company);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (filter: string) => {
    setFilter(filter);
  };

  const filteredCompanies = companies?.filter((company) =>
    company.razao.toLowerCase().includes(`${filter}`.toLowerCase())
  );

  const isSelected = (company: Company) => {
    if (selected.indexOf(company) !== -1) {
      return true;
    }
    return false;
  };

  const setInitialSelected = () => {
    const searchSelected = companies.filter((el) => el.selected);
    setSelected(searchSelected);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - companies.length) : 0;

  useEffect(() => {
    setSelected(companiesValue);
  }, [companiesValue]);

  useEffect(() => {
    setInitialSelected();
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%' }}>
        <TableContainer>
          <Typography
            sx={{ flex: '1 1 100%', marginLeft: 3, marginTop: 3, marginBottom: 1 }}
            variant="h4"
            component="div"
          >
            Selecionar Empresas
          </Typography>
          <CompanyListToolbar
            numSelected={-1}
            filterCompany={filter}
            onFilterCompany={handleFilter}
          />
          <TableRow>
            <TableCell sx={{ padding: 0, paddingLeft: 3 }}>
              <Checkbox color="primary" onChange={(event) => handleSelectAllClick(event)} />
            </TableCell>
            <TableCell>
              <Typography fontWeight={700}>Selecionar Todas</Typography>
            </TableCell>
          </TableRow>
          <Table
            sx={{ minWidth: 750, marginTop: 1, maxHeight: '20vh', overflowY: 'auto' }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <TableBody>
              {filteredCompanies
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                        alignItems: 'center'
                      }}
                    >
                      <TableCell sx={{ padding: 0 }}>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId
                          }}
                        />
                      </TableCell>
                      <TableCell id={labelId} scope="row" sx={{ padding: 0 }}>
                        {row.razao} - {row.cnpj}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ marginTop: 4 }}
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={companies.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}–${to} de ${count !== -1 ? count : ''}`
          }
          labelRowsPerPage="Linhas por Página"
        />

        <Stack
          justifyContent="flex-end"
          alignItems="flex-end"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 2 }}
          sx={{ padding: 2 }}
        >
          <Button
            variant="outlined"
            type="button"
            color="primary"
            onClick={() => setIsOpenModal(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setCompaniesValue(selected);
              setIsOpenModal(false);
            }}
          >
            Salvar
          </Button>
        </Stack>
      </Paper>
    </Box>
  );
}

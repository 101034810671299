import useSettings from 'hooks/useSettings';
import React, { useEffect, useState } from 'react';
import { axiosInstance } from 'services/instance';
import endpoints from 'services/request/endpoints';
import { useSnackbar } from 'notistack5';
import { AnalyzerProps, ListCompanies, FilterProps } from './Model';
import View from './View';

const Analyzer: React.FC = () => {
  const { themeStretch } = useSettings();
  const [filter, setFilter] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<AnalyzerProps[]>([]);
  const currentLanguage = 'pt-br';
  const [companySelected, setCompanySelected] = useState<boolean>(false);
  const [company, setCompany] = useState<ListCompanies>({
    cnpj: '',
    fantasia: '',
    id: 0,
    inputValue: '',
    razao: '',
    title: ''
  });
  const [listCompanies, setListCompanies] = useState<ListCompanies[]>([]);

  const [rememberValues, setRememberValues] = useState({
    client: '',
    company: '',
    initialDate: '',
    finalDate: ''
  });

  const { enqueueSnackbar } = useSnackbar();

  const getNfs = async (endpoint: string, filterValues: FilterProps) => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(endpoint);
      setList(data);
      setCompanySelected(true);
      setRememberValues(filterValues);
      setLoading(false);
    } catch (error: any) {
      enqueueSnackbar(error.response.data.message, {
        variant: 'error'
      });
      setCompanySelected(true);
      setRememberValues(filterValues);
      setLoading(false);
    }
  };

  const getCompanies = async () => {
    setLoading(true);
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.clientV2.listCompanies}`;
    try {
      const { data } = await axiosInstance.get(endpoint);
      setListCompanies(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const TABLE_HEAD = [
    { id: '', label: '', alignRight: false },
    { id: 'nota_fiscal', label: 'Nota Fiscal', alignRight: false },
    { id: 'data_emissao', label: 'Data Emissão', alignRight: false },
    { id: 'emitente', label: 'Emitente', alignRight: false },
    { id: 'valor_total_nota', label: 'Valor Total Nota', alignRight: false },
    { id: 'valor_total_icms_st', label: 'Valor Total ICMS ST', alignRight: false },
    { id: 'uf', label: 'UF', alignRight: false }
  ];

  const TABLE_HEAD_ITENS = [
    { id: '', label: '', alignRight: false },
    { id: 'item', label: 'Item', alignRight: false },
    { id: 'produto', label: 'Produto', alignRight: false },
    { id: 'descricao', label: 'Descrição', alignRight: false },
    { id: 'valor_produto', label: 'Valor Produto', alignRight: false },
    { id: 'mva', label: 'MVA', alignRight: false },
    { id: 'valor_icms_st', label: 'Valor ICMS ST', alignRight: false },
    { id: '', label: '', alignRight: false }
  ];

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (filter: string) => {
    setFilter(filter);
  };

  const filteredList = list.filter((el) => {
    const { nota } = el;
    return `${nota}`.toLowerCase().includes(`${filter}`.toLowerCase());
  });

  const valorTotalIcmsst = (value: AnalyzerProps) => {
    const numbers = value.itens.map((item) => item.icmsst.description.valor_icms_st);
    let soma = 0;
    numbers.forEach((number) => {
      soma += number;
    });
    return soma;
  };

  const valorTotalNota = (value: AnalyzerProps) => {
    const itens_values = value.itens.map((item) => {
      const { quantidade, valor_unitario, ipi } = item;
      const { valor_frete, valor_desconto } = item.icmsst.description;
      const { value } = item.icmsst;

      const n_quantidade = parseInt(quantidade, 10);
      const n_valor_unitario = valor_unitario;
      const n_ipi = parseFloat(ipi) || 0;

      let item_value = n_quantidade * n_valor_unitario;

      item_value += Number(value) + n_ipi + Number(valor_frete) - Number(valor_desconto);

      return item_value;
    });

    let nota_total_value = 0;
    itens_values.forEach((item) => {
      nota_total_value += Number(item);
    });
    return nota_total_value;
  };

  const isNofilterResult = filteredList.length === 0;

  return (
    <View
      {...{
        themeStretch,
        filteredList,
        TABLE_HEAD,
        TABLE_HEAD_ITENS,
        handleFilter,
        isLoading: loading,
        isNofilterResult,
        page,
        rowsPerPage,
        emptyRows,
        filter,
        handleChangeRowsPerPage,
        setPage,
        getNfs,
        valorTotalIcmsst,
        valorTotalNota,
        currentLanguage,
        companySelected,
        listCompanies,
        rememberValues,
        setCompany,
        company
      }}
    />
  );
};

export default Analyzer;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack5';
import endpoints from 'services/request/endpoints';
import { axiosInstance } from 'services/instance';
import { SegmentProps } from './Model';
import View from './View';

const NewSegment: React.FC = () => {
  const params = useParams();
  const [typePage, setTypePage] = useState<'New' | 'Edit'>('New');
  const [loading, setLoading] = useState(false);

  // edit
  const [currentSelected, setCurrentSelected] = useState<SegmentProps>({
    id: -1,
    nome: '',
    item: '',
    codigo: '',
    ativo: true,
    vigencia_inicio: '',
    vigencia_fim: ''
  });

  const { enqueueSnackbar } = useSnackbar();

  async function callEdit() {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.segment}/${params.id}`;
    setLoading(true);
    setTypePage('Edit');

    try {
      const { data } = await axiosInstance.get(endpoint);
      await setCurrentSelected(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    }
  }

  useEffect(() => {
    if (params && params.id) {
      setTypePage('Edit');
      callEdit();
    }
  }, []);

  return (
    <View
      setLoading={setLoading}
      loading={loading}
      typePage={typePage}
      selected={currentSelected}
    />
  );
};
export default NewSegment;

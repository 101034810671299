import useSettings from 'hooks/useSettings';
import React, { useEffect, useState } from 'react';
import endpoints from 'services/request/endpoints';
import { axiosInstance } from 'services/instance';

import View from './View';
import { Proticms41Prop } from './Model';

const ListProticms41Company: React.FC = () => {
  const { themeStretch } = useSettings();
  const [filter, setFilter] = useState<string>('');
  const [currentTab, setCurrentTab] = useState<'active' | 'inactive'>('active');
  const [loading, setLoading] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [dataItens, setDataItens] = useState<Proticms41Prop[]>([]);

  const getList = async () => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.integrator.tables.proticms41s}`;
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(endpoint);
      setDataItens(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataItens.length) : 0;

  const TABLE_HEAD = [
    { id: 'id', label: 'Cód.', alignRight: false },
    { id: 'item', label: 'Item', alignRight: false },
    { id: 'code', label: 'Código', alignRight: false },
    { id: 'ncm', label: 'NCM', alignRight: false },
    { id: 'description', label: 'Descrição', alignRight: false, minWidth: 100 },
    { id: 'updated_at', label: 'Atualizado em', alignRight: false }
  ];

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (filter: string) => {
    setFilter(filter);
  };

  const filteredList = dataItens.filter((element) => {
    const { descricao, item, codigo, ncm, ativo } = element;
    if (currentTab === 'active') {
      return (
        ativo === true &&
        (`${descricao}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
          `${item}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
          `${codigo}`.toLowerCase().includes(`${filter}`.toLowerCase()))
      );
    }

    if (currentTab === 'inactive') {
      return (
        ativo === false &&
        (`${descricao}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
          `${item}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
          `${ncm}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
          `${codigo}`.toLowerCase().includes(`${filter}`.toLowerCase()))
      );
    }

    return (
      `${descricao}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
      `${item}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
      `${ncm}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
      `${codigo}`.toLowerCase().includes(`${filter}`.toLowerCase())
    );
  });

  const isNofilterResult = filteredList.length === 0;

  return (
    <View
      {...{
        themeStretch,
        filteredList,
        TABLE_HEAD,
        handleFilter,
        isLoading: loading,
        isNofilterResult,
        page,
        rowsPerPage,
        setCurrentTab,
        currentTab,
        emptyRows,
        filter,
        handleChangeRowsPerPage,
        setPage
      }}
    />
  );
};

export default ListProticms41Company;

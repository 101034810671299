import React, { useState } from 'react';
// material
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  Tabs,
  Tab,
  TablePagination,
  Collapse,
  Box
} from '@material-ui/core';
// redux
// routes
import { PATH_COMPANY } from 'routes/paths';
// @types
// components
import Scrollbar from 'components/Scrollbar';
import SearchNotFound from 'components/SearchNotFound';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { CompanyListHead, CompanyListToolbar } from 'components/_dashboard/companies';
import Page from 'components/Page';
import EmptyContent from 'components/EmptyContent';
import LoadingScreen from 'components/LoadingScreen';
import moment from 'moment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { DataItensProps, Port195ViewProps } from '../Model';

function Rows({ row }: { row: DataItensProps }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell style={{ width: 100 }}>
          <Button color="success" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Button>
        </TableCell>
        <TableCell>{row.segment}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {row.items.map((item: any, key: any) => (
                    <TableRow key={key}>
                      <TableCell style={{ minWidth: 100 }}>{item.codigo}</TableCell>
                      <TableCell style={{ minWidth: 100 }}>{item.item}</TableCell>
                      <TableCell style={{ minWidth: 100 }}>{item.cest}</TableCell>
                      <TableCell style={{ minWidth: 100 }}>
                        {item.ncm.map((ncmItem: any) => (
                          <>
                            <span>{ncmItem.ncm}</span>
                            <br />
                          </>
                        ))}
                      </TableCell>
                      <TableCell style={{ minWidth: 100 }}>{item.descricao}</TableCell>
                      <TableCell style={{ minWidth: 100 }}>{item.mva_outorgado}</TableCell>
                      <TableCell style={{ minWidth: 100 }}>{item.mva}</TableCell>
                      <TableCell style={{ minWidth: 100 }}>
                        {moment(item.updated_at).format('DD/MM/YYYY')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <div style={{ backgroundColor: 'rgb(233 233 233)', height: '1px' }} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const View: React.FC<Port195ViewProps> = ({
  TABLE_HEAD,
  currentTab,
  emptyRows,
  filter,
  filteredList,
  handleChangeRowsPerPage,
  handleFilter,
  isLoading,
  isNofilterResult,
  page,
  rowsPerPage,
  setCurrentTab,
  setPage,
  themeStretch
}) => (
  <Page title="Portaria 195">
    <Container maxWidth={themeStretch ? false : 'lg'}>
      <HeaderBreadcrumbs
        heading="Portaria 195"
        links={[{ name: 'Home', href: PATH_COMPANY.general.home }, { name: 'Portaria 195' }]}
      />

      {isLoading ? (
        <div style={{ marginTop: '300px' }}>
          <LoadingScreen />
        </div>
      ) : (
        <Card>
          <CompanyListToolbar
            numSelected={-1}
            filterCompany={filter}
            onFilterCompany={handleFilter}
          />

          <Tabs
            value={currentTab}
            onChange={(event, value) => setCurrentTab(value)}
            style={{
              margin: 10
            }}
          >
            <Tab value="all" label="Todos" />

            <Tab value="active" label="Ativo" />
            <Tab value="inactive" label="Inativo" />
          </Tabs>

          <Scrollbar>
            <TableContainer>
              {!isNofilterResult || filter ? (
                <>
                  {!isNofilterResult && (
                    <Table aria-label="collapsible table">
                      <CompanyListHead headLabel={TABLE_HEAD} />
                    </Table>
                  )}
                  <Table aria-label="collapsible table">
                    <TableBody>
                      {filteredList.map((el, key) => (
                        <Rows key={key} row={el} />
                      ))}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isNofilterResult && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filter} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </>
              ) : (
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <EmptyContent title="Ops!!! Parece que não há nada para ver aqui." />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por Página"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}–${to} de ${count !== -1 ? count : `more than ${to}`}`
            }
          />
        </Card>
      )}
    </Container>
  </Page>
);

export default View;

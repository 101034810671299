import { Icon } from '@iconify/react';
import React from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  Tabs,
  Tab,
  TablePagination
} from '@material-ui/core';
// redux
// routes
import { PATH_ADMIN } from 'routes/paths';
// @types
// components
import Scrollbar from 'components/Scrollbar';
import SearchNotFound from 'components/SearchNotFound';
import EmptyContent from 'components/EmptyContent';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { CompanyListHead, CompanyListToolbar } from 'components/_dashboard/companies';
import Page from 'components/Page';
import LoadingScreen from 'components/LoadingScreen';
import moment from 'moment';
import { fPercent } from 'utils/formatNumber';
import { UsersViewProps } from '../Model';
import MoreMenu from '../../components/MoreMenu';

const View: React.FC<UsersViewProps> = ({
  TABLE_HEAD,
  currentTab,
  emptyRows,
  filter,
  filteredList,
  handleChangeRowsPerPage,
  handleDelete,
  handleFilter,
  isLoading,
  isNofilterResult,
  page,
  rowsPerPage,
  setCurrentTab,
  setPage,
  themeStretch,
  selected,
  loadingAction
}) => (
  <Page title="Estados">
    <Container maxWidth={themeStretch ? false : 'lg'}>
      <HeaderBreadcrumbs
        heading="Estados"
        links={[
          { name: 'Home', href: PATH_ADMIN.general.administrative.tables.states },
          { name: 'Estados' }
        ]}
        action={
          <Button
            variant="contained"
            component={RouterLink}
            to={PATH_ADMIN.general.administrative.tables.registerStates}
            startIcon={<Icon icon={plusFill} />}
          >
            Novo Estado
          </Button>
        }
      />

      {isLoading ? (
        <div style={{ marginTop: '300px' }}>
          <LoadingScreen />
        </div>
      ) : (
        <Card>
          <CompanyListToolbar
            numSelected={selected.length}
            filterCompany={filter}
            onFilterCompany={handleFilter}
          />

          <Tabs
            value={currentTab}
            onChange={(event, value) => setCurrentTab(value)}
            style={{
              margin: 10
            }}
          >
            <Tab value="all" label="Todos" />
          </Tabs>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 900 }}>
              <Table>
                {!isNofilterResult || filter ? (
                  <>
                    {!isNofilterResult && <CompanyListHead headLabel={TABLE_HEAD} />}

                    <TableBody>
                      {filteredList
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const { id, nome, sigla, aliquota, codigo_estados, regiao, updated_at } =
                            row;

                          const isItemSelected = selected.indexOf(nome) !== -1;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              aria-checked={isItemSelected}
                            >
                              <TableCell style={{ minWidth: 100 }}>{codigo_estados}</TableCell>
                              <TableCell style={{ minWidth: 100 }}>{nome}</TableCell>
                              <TableCell style={{ minWidth: 100 }}>{regiao}</TableCell>
                              <TableCell style={{ minWidth: 100 }}>{sigla}</TableCell>
                              <TableCell style={{ minWidth: 100 }}>
                                {fPercent(aliquota).replace('.', ',')}
                              </TableCell>
                              <TableCell style={{ maxWidth: 100 }}>
                                {updated_at ? moment(updated_at).format('DD/MM/YYYY') : ''}
                              </TableCell>

                              <TableCell align="right">
                                <MoreMenu
                                  onDelete={() => handleDelete(id)}
                                  loadingAction={loadingAction}
                                  id={id}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isNofilterResult && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filter} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <EmptyContent title="Ops!!! Parece que não há nada para ver aqui." />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por Página"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}–${to} de ${count !== -1 ? count : `more than ${to}`}`
            }
          />
        </Card>
      )}
    </Container>
  </Page>
);

export default View;

import useSettings from 'hooks/useSettings';
import React, { useEffect, useState } from 'react';
import endpoints from 'services/request/endpoints';
import { axiosInstance } from 'services/instance';

import useSweetAlert from 'hooks/useSweetAlert';
import View from './View';
import { AttachmentXProp, DataItensProps } from './Model';

const ListAttachmentX: React.FC = () => {
  const { themeStretch } = useSettings();
  const { exclude } = useSweetAlert();
  const [selected, setSelected] = useState<string[]>([]);
  const [list, setList] = useState<any[]>([]);
  const [filter, setFilter] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [currentTab, setCurrentTab] = useState('active');
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [dataItens, setDataItens] = useState<DataItensProps[]>([]);
  const [openCollapse, setOpenCollapse] = useState(false);

  const getList = async () => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.attachmentxs}`;
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(endpoint);

      const forRender = [] as { segment: string; items: AttachmentXProp[] }[];

      for (const [key, value] of Object.entries(data as DataItensProps)) {
        if (value && value.length > 0) {
          const typedKey: string = key;
          const typedValue: AttachmentXProp[] = value;

          if (typedValue.length > 0) {
            forRender.push({ segment: typedKey.split(' - ')[1], items: typedValue });
          }
        }
      }

      setDataItens(forRender);

      setList(data.SEGMENTO.SEGMENTO);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataItens.length) : 0;

  const TABLE_HEAD = [
    { id: 'id', label: 'Cód.', alignRight: false },
    { id: 'item', label: 'Item', alignRight: false },
    { id: 'cest', label: 'CEST', alignRight: false },
    { id: 'ncm', label: 'NCM', alignRight: false },
    { id: 'description', label: 'Descrição', alignRight: false, minWidth: 100 },
    { id: 'updated_at', label: 'Atualizado em', alignRight: false },
    { id: '', label: '', alignRight: false }
  ];

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = async (filter: string) => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.attachmentxs}?search=${filter}`;
    setLoading(true);

    try {
      const { data } = await axiosInstance.get(endpoint);

      const forRender = [] as { segment: string; items: AttachmentXProp[] }[];

      for (const [key, value] of Object.entries(data as DataItensProps)) {
        if (value && value.length > 0) {
          const typedKey: string = key;
          const typedValue: AttachmentXProp[] = value;

          if (typedValue.length > 0) {
            forRender.push({ segment: typedKey.split(' - ')[1], items: typedValue });
          }
        }
      }

      setDataItens(forRender);
      setOpenCollapse(true);

      setList(data.SEGMENTO.SEGMENTO);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    exclude({
      endpoint: `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.attachmentx}/${id}`,
      setLoading: setLoadingAction
    });
  };

  const filteredList = dataItens
    .map((element) => {
      const filt = element.items.filter((ele) => {
        const { descricao, item, codigo, cest, ativo } = ele;
        if (currentTab === 'active') {
          return (
            ativo === true &&
            (`${descricao}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
              `${item}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
              `${codigo}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
              `${cest}`.toLowerCase().includes(`${filter}`.toLowerCase()))
          );
        }

        if (currentTab === 'inactive') {
          return (
            ativo === false &&
            (`${descricao}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
              `${item}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
              `${codigo}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
              `${cest}`.toLowerCase().includes(`${filter}`.toLowerCase()))
          );
        }

        return (
          `${descricao}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
          `${item}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
          `${codigo}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
          `${cest}`.toLowerCase().includes(`${filter}`.toLowerCase())
        );
      });

      return { segment: element.segment, items: filt };
    })
    .filter((item) => {
      if (item.items.length > 0) return true;
      return false;
    });

  const isNofilterResult = filteredList.length === 0;

  return (
    <View
      {...{
        themeStretch,
        selected,
        filteredList,
        TABLE_HEAD,
        handleFilter,
        handleDelete,
        isLoading: loading,
        isNofilterResult,
        page,
        rowsPerPage,
        setCurrentTab,
        currentTab,
        emptyRows,
        filter,
        handleChangeRowsPerPage,
        setPage,
        loadingAction,
        openCollapse
      }}
    />
  );
};

export default ListAttachmentX;

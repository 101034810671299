import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack5';
import { useParams } from 'react-router-dom';
import endpoints from 'services/request/endpoints';
import { axiosInstance } from 'services/instance';
import { AdministrativeStatesProps } from './Model';
import View from './View';

const NewState: React.FC = () => {
  const params = useParams();
  const [typePage, setTypePage] = useState<'New' | 'Edit'>('New');
  const [loading, setLoading] = useState(false);

  const regionList: string[] = ['Centro-Oeste', 'Nordeste', 'Norte', 'Sudeste', 'Sul'];

  // edit
  const [currentSelected, setCurrentSelected] = useState<AdministrativeStatesProps>({
    id: -1,
    codigo_estados: '',
    nome: '',
    sigla: '',
    aliquota: '',
    regiao: '',
    updated_at: ''
  });

  const { enqueueSnackbar } = useSnackbar();

  async function callEdit() {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.state}/${params.id}`;
    setLoading(true);
    setTypePage('Edit');

    try {
      const { data } = await axiosInstance.get(endpoint);
      await setCurrentSelected(data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    }
  }

  useEffect(() => {
    if (params && params.id) {
      setTypePage('Edit');
      callEdit();
    }
  }, []);

  return (
    <View
      loading={loading}
      typePage={typePage}
      selected={currentSelected}
      regionList={regionList}
    />
  );
};
export default NewState;

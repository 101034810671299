import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { axiosInstance } from 'services/instance';
import { useSnackbar } from 'notistack5';
import endpoints from 'services/request/endpoints';

// material
import { Box, Typography, Button } from '@mui/material';
import { styled, useTheme } from '@material-ui/core/styles';
// hooks
import moment from 'moment';
import { PATH_COMPANY } from 'routes/paths';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
//
import Navbar from './Navbar';
import Sidebar from './Sidebar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function CompanyLayout() {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const onlyOutlet =
    pathname === '/checkout' || pathname === '/licencas' || pathname === '/licencas/upgrade';
  const [data, setData] = useState({
    ultima_atualizacao: '',
    expira_em: '',
    periodo_teste: false,
    situacao: ''
  });
  const { periodo_teste, expira_em } = data;
  const lastDay = moment(expira_em);
  const today = moment();
  const timeToEndTrialPeriod = lastDay.diff(today, 'days') + 1;
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const verifyPlan = () => {
    axiosInstance
      .get(endpoints.integrator.verify)
      .then((response) => {
        setData(response.data);
      })
      .catch((error: any) => {
        enqueueSnackbar(error.response.data.message, {
          variant: 'error'
        });
        console.log(error);
      });
  };

  useEffect(() => {
    verifyPlan();
  }, []);

  return (
    <RootStyle id="RootStyle">
      {!onlyOutlet && (
        <>
          {periodo_teste && (
            <Box
              sx={{
                width: '100%',
                height: 50,
                backgroundColor: '#333333',
                position: 'fixed',
                zIndex: 2005,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 4
              }}
            >
              <Typography color="white">
                {timeToEndTrialPeriod < 0
                  ? 'Seu período de testes terminou!'
                  : `Seu período de teste grátis termina em ${timeToEndTrialPeriod} ${
                      timeToEndTrialPeriod <= 1 ? 'dia' : 'dias'
                    } `}
              </Typography>
              <Button variant="contained" onClick={() => navigate(PATH_COMPANY.general.licences)}>
                Obter Licença
              </Button>
            </Box>
          )}
          <Navbar {...{ isTrialPeriod: periodo_teste }} onOpenSidebar={() => setOpen(true)} />
          <Sidebar
            {...{ isTrialPeriod: periodo_teste }}
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
          />
        </>
      )}
      <MainStyle
        id="MainStyle"
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(collapseClick && {
            ml: '102px'
          }),
          mt: periodo_teste && pathname !== '/licencas' ? '50px' : '0px'
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}

import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  Modal,
  Box,
  Tab,
  Tabs,
  Typography,
  Stack,
  ListItemIcon,
  ListItem,
  ListItemText
} from '@material-ui/core';

import currencyBrl from 'utils/currencyBrl';
import { fPercent } from 'utils/formatNumber';

import { item } from '../Model';
// routes

interface MoreMenuProps {
  item: item;
  nota: string;
}

// ----------------------------------------------------------------------

export default function MoreMenu({ item, nota }: MoreMenuProps) {
  const ref = useRef(null);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [currentTab, setCurrentTab] = useState<string>('st');

  const {
    base_calculo_icms_st,
    aliquota_interna_icms,
    total_a_b,
    valor_total_produtos,
    valor_desconto,
    aliquota_icms,
    total_d_e_f,
    resultado,
    valor_frete,
    valor_aliquota_cims,
    valor_icms_st
  } = item.icmsst.description;

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpenMenu(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpenMenu}
        anchorEl={ref.current}
        onClose={() => setIsOpenMenu(false)}
        PaperProps={{
          sx: { maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => setIsOpenModal(true)}>
          <ListItem>
            <ListItemIcon>
              <Icon icon="material-symbols:calculate-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Memória de Cálculo"
              primaryTypographyProps={{ variant: 'body2' }}
              sx={{ width: 'auto' }}
            />
          </ListItem>
        </MenuItem>
        <Modal
          open={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            style={{
              borderRadius: 25,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography id="modal-modal-title" variant="h4" component="h2" mb={2}>
              Memória de Cálculo
            </Typography>
            <Stack direction="row" spacing={5}>
              <Stack direction="row" spacing={1}>
                <Typography id="modal-modal-title" fontSize="1rem" fontWeight="700">
                  NF:
                </Typography>
                <Typography id="modal-modal-title" fontSize="1rem">
                  {nota}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography id="modal-modal-title" fontSize="1rem" fontWeight="700">
                  Item:
                </Typography>
                <Typography id="modal-modal-title" fontSize="1rem">
                  {item.item}
                </Typography>
              </Stack>
            </Stack>
            <Tabs
              value={currentTab}
              onChange={(event, value) => setCurrentTab(value)}
              style={{
                margin: 10
              }}
            >
              <Tab value="st" label="ST" />
              <Tab value="red_bc" label="Red. Base de Cálculo" />
            </Tabs>
            {currentTab === 'st' && (
              <Stack>
                <Box sx={{ display: 'flex', mt: 3 }} flexDirection="row">
                  <Stack direction="column">
                    <Typography fontSize="1rem" fontWeight="700">
                      Base de Cálculo ICMS ST:
                    </Typography>
                    <Typography fontSize="1rem" fontWeight="700">
                      Alíquota interna ICMS:
                    </Typography>
                    <Typography fontSize="1rem" fontWeight="700">
                      Total A B:
                    </Typography>
                    <Typography fontSize="1rem" fontWeight="700">
                      Valor total Produtos:
                    </Typography>
                    <Typography fontSize="1rem" fontWeight="700">
                      Valor desconto:
                    </Typography>
                    <Typography fontSize="1rem" fontWeight="700">
                      Alíquota ICMS:
                    </Typography>
                    <Typography fontSize="1rem" fontWeight="700">
                      Total D E F:
                    </Typography>
                    <Typography fontSize="1rem" fontWeight="700">
                      Resultado:
                    </Typography>
                    <Typography fontSize="1rem" fontWeight="700">
                      Valor Frete:
                    </Typography>
                    <Typography fontSize="1rem" fontWeight="700">
                      Valor Alíquota ICMS:
                    </Typography>
                    <Typography fontSize="1rem" fontWeight="700">
                      Valor ICMS ST:
                    </Typography>
                  </Stack>
                  <Stack
                    direction="column"
                    sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'right' }}
                  >
                    <Typography fontSize="1rem">{currencyBrl(base_calculo_icms_st)}</Typography>
                    <Typography fontSize="1rem">{fPercent(aliquota_interna_icms)}</Typography>
                    <Typography fontSize="1rem">{currencyBrl(total_a_b)}</Typography>
                    <Typography fontSize="1rem">{currencyBrl(valor_total_produtos)}</Typography>
                    <Typography fontSize="1rem">{currencyBrl(valor_desconto)}</Typography>
                    <Typography fontSize="1rem">{fPercent(aliquota_icms)}</Typography>
                    <Typography fontSize="1rem">{currencyBrl(total_d_e_f)}</Typography>
                    <Typography fontSize="1rem">{currencyBrl(resultado)}</Typography>
                    <Typography fontSize="1rem">{currencyBrl(valor_frete)}</Typography>
                    <Typography fontSize="1rem">{fPercent(valor_aliquota_cims)}</Typography>
                    <Typography fontSize="1rem">{currencyBrl(valor_icms_st)}</Typography>
                  </Stack>
                </Box>
              </Stack>
            )}
          </Box>
        </Modal>
      </Menu>
    </>
  );
}

import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  Tabs,
  Tab,
  TablePagination,
  Collapse,
  Box
} from '@material-ui/core';
// redux
// routes
import { PATH_ADMIN } from 'routes/paths';
// @types
// components
import Scrollbar from 'components/Scrollbar';
import SearchNotFound from 'components/SearchNotFound';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import EmptyContent from 'components/EmptyContent';
import { CompanyListHead } from 'components/_dashboard/companies';
import Page from 'components/Page';
import LoadingScreen from 'components/LoadingScreen';
import moment from 'moment';
import { Proticms41ViewProps } from '../Model';
import MoreMenu from '../../components/MoreMenu';
import CompanyListToolbar from '../../components/CompanyListToolbar';

const View: React.FC<Proticms41ViewProps> = ({
  handleFilter,
  filter,
  setCurrentTab,
  currentTab,
  setPage,
  handleChangeRowsPerPage,
  handleDelete,
  rowsPerPage,
  TABLE_HEAD,
  emptyRows,
  filteredList,
  isLoading,
  isNofilterResult,
  page,
  themeStretch,
  loadingAction,
  openCollapse
}) => (
  <Page title="Protocolo ICMS 41">
    <Container maxWidth={themeStretch ? false : 'lg'}>
      <HeaderBreadcrumbs
        heading="Protocolo ICMS 41"
        links={[{ name: 'Home', href: PATH_ADMIN.general.home }, { name: 'Protocolo ICMS 41' }]}
        action={
          <Button
            variant="contained"
            component={RouterLink}
            to={PATH_ADMIN.general.administrative.tables.registerProticms41s}
            startIcon={<Icon icon={plusFill} />}
          >
            Novo Protocolo
          </Button>
        }
      />

      {isLoading ? (
        <div style={{ marginTop: '300px' }}>
          <LoadingScreen />
        </div>
      ) : (
        <Card>
          <CompanyListToolbar
            numSelected={0}
            filterCompany={filter}
            onFilterCompany={handleFilter}
          />

          <Tabs
            value={currentTab}
            onChange={(event, status) => setCurrentTab(status)}
            style={{
              margin: 10
            }}
          >
            <Tab value="all" label="Todos" />

            <Tab value="active" label="Ativo" />
            <Tab value="inactive" label="Inativo" />
          </Tabs>

          <Scrollbar>
            <TableContainer>
              {!isNofilterResult || filter ? (
                <>
                  {!isNofilterResult && (
                    <Table aria-label="collapsible table">
                      <CompanyListHead headLabel={TABLE_HEAD} />
                    </Table>
                  )}
                  <Table aria-label="collapsible table">
                    <TableBody>
                      {filteredList
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((el, key) => (
                          <TableRow key={key}>
                            <TableCell style={{ minWidth: 100 }}>{el.id}</TableCell>
                            <TableCell style={{ minWidth: 100 }}>{el.item}</TableCell>
                            <TableCell style={{ minWidth: 100 }}>{el.codigo}</TableCell>
                            <TableCell style={{ minWidth: 100 }}>{el.ncm}</TableCell>
                            <TableCell style={{ minWidth: 100 }}>{el.descricao}</TableCell>
                            <TableCell style={{ minWidth: 100 }}>
                              {moment(el.updated_at).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell align="right">
                              <MoreMenu
                                onDelete={() => handleDelete(el.id)}
                                id={el.id}
                                loadingAction={loadingAction}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isNofilterResult && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filter} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </>
              ) : (
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <EmptyContent title="Ops!!! Parece que não há nada para ver aqui." />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      )}
    </Container>
  </Page>
);

export default View;

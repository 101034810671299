import { useEffect, useState } from 'react';
import API from 'services/api';
import { PATH_APP } from 'routes/paths';
import axios from 'axios';
import { ListPlansProps } from './Model';
import View from './View';

const Register = () => {
  const { request } = API();

  const [currentCnpj, setCurrentCnpj] = useState('');
  const [remoteLoading, setRemoteLoading] = useState(false);
  const [remoteCompany, setRemoteCompany] = useState(null);
  const [dataAutocompleteError, setDataAutocompleteError] = useState({
    detalhes: '',
    status: null,
    titulo: '',
    validacao: []
  });

  const [listPlans, setListPlans] = useState<ListPlansProps[]>([]);

  async function consultCnpj(cnpj: string) {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${PATH_APP.company.consult}/${cnpj}`;
    setRemoteLoading(true);

    try {
      const { data } = await axios.get(endpoint);

      if (data.detalhes) {
        setDataAutocompleteError(data);
      }

      setRemoteCompany(data);
      setRemoteLoading(false);
    } catch (error) {
      console.log('Error', error);
      setRemoteLoading(false);
    }
  }

  useEffect(() => {
    const cnpjValidate = currentCnpj.replace(/\D/g, '');
    if (cnpjValidate.length >= 14) {
      consultCnpj(cnpjValidate);
    }
  }, [currentCnpj]);

  const getListPlans = async () => {
    try {
      const { data }: any = await request('company_plans', 'GET', false);

      const dataPlans: ListPlansProps[] = [];

      data.forEach((item: ListPlansProps) => {
        dataPlans.push({ ...item, label: item.descricao, value: item.id });
      });

      setListPlans(dataPlans);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getListPlans();
  }, []);

  return (
    <View
      listPlans={listPlans}
      dataCnpj={{ set: setCurrentCnpj }}
      dataAutocomplete={remoteCompany}
      loading={remoteLoading}
      dataAutocompleteError={dataAutocompleteError}
    />
  );
};

export default Register;

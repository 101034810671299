import React, { useCallback, useState } from 'react';
// material
import { Card, Button, Container, CardContent, Stack, Box } from '@material-ui/core';
// components
import Scrollbar from 'components/Scrollbar';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import { UploadMultiFile } from 'components/upload';

import { axiosInstance } from 'services/instance';
import endpoints from 'services/request/endpoints';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack5';
import { XmlViewProps } from '../Model';

interface LogsProps {
  imported: string[];
  not_imported: string[];
}

const View: React.FC<XmlViewProps> = ({ files, setFiles }) => {
  const [logs, setLogs] = useState<LogsProps>({ imported: [], not_imported: [] });
  const { enqueueSnackbar } = useSnackbar();

  const hasFile = files.length > 0;

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const handleRemove = (file: File | string) => {
    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems);
  };

  const handleDropMultiFile = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file: File) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    },
    [setFiles]
  );

  const sendFiles = async () => {
    const body: any = new FormData();

    files.forEach((element: any) => {
      body.append('files[]', element);
    });

    try {
      const { data, status } = await axiosInstance.post(
        endpoints.administrative.importerXml,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      const message = data.message || 'Cadastro efetuado com sucesso!';

      if (status === 200) {
        setLogs(data?.log);
        enqueueSnackbar(message, {
          variant: 'success'
        });
      } else {
        enqueueSnackbar(data.message || 'Ocorreu um problema.', {
          variant: 'error'
        });
      }
    } catch (error: AxiosError<{ message: string }> | any) {
      const message = error.response?.data?.message || 'Ocorreu um erro!';
      enqueueSnackbar(message, {
        variant: 'error'
      });
    }
  };

  return (
    <Page title="Importação de Xml">
      <Container>
        <HeaderBreadcrumbs heading="Importação de Xml" links={[{ name: 'Xml' }]} />

        <Card>
          <Scrollbar>
            <Card>
              <CardContent>
                <UploadMultiFile
                  showPreview={false}
                  files={files}
                  onDrop={handleDropMultiFile}
                  onRemove={handleRemove}
                  accept="text/xml"
                  logs={logs}
                />

                {hasFile && (
                  <Stack
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 2 }}
                  >
                    <Box sx={{ mt: 3, display: 'flex' }}>
                      <Button
                        variant="outlined"
                        type="button"
                        color="primary"
                        onClick={handleRemoveAll}
                      >
                        Remover todos
                      </Button>
                    </Box>
                    <Box sx={{ mt: 3, display: 'flex' }}>
                      <Button variant="contained" onClick={sendFiles}>
                        Enviar
                      </Button>
                    </Box>
                  </Stack>
                )}
              </CardContent>
            </Card>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
};

export default View;

import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Stack
} from '@material-ui/core';
import { AxiosError } from 'axios';
import { UploadMultiFile } from 'components/upload';
import React, { Dispatch, FC, ReactElement, SetStateAction, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack5';
import { axiosInstance } from 'services/instance';
import endpoints from 'services/request/endpoints';
import { LoadingButton } from '@material-ui/lab';
import { useNavigate } from 'react-router';

interface ImporterModalProps {
  handleModal: boolean;
  setHandleModal: Dispatch<SetStateAction<boolean>>;
}

interface LogsProps {
  imported: string[];
  not_imported: string[];
}

const ImporterModal: FC<ImporterModalProps> = ({ handleModal, setHandleModal }): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [files, setFiles] = useState<(File | string)[]>([]);
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState<LogsProps>({} as LogsProps);

  const hasFile = files.length > 0;

  const handleRemoveAll = () => {
    setFiles([]);
    setLogs({ imported: [], not_imported: [] });
  };

  const onCloseModal = () => {
    setFiles([]);
    setHandleModal(false);
    setLogs({ imported: [], not_imported: [] });
  };

  const handleRemove = (file: File | string) => {
    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems);
  };

  const handleDropMultiFile = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file: File) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    },
    [setFiles]
  );

  const sendFiles = async () => {
    setLoading(true);
    const body: any = new FormData();

    files.forEach((element: any) => {
      body.append('files[]', element);
    });

    try {
      const { data, status } = await axiosInstance.post(
        endpoints.administrative.importerXml,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      const message = data.message || 'Cadastro efetuado com sucesso!';

      if (status === 200) {
        if (data.log.not_imported.length > 0 || data.log.imported.length > 0) {
          setLoading(false);
          setLogs(data.log);
        }

        if (data.log.not_imported.length === 0) {
          enqueueSnackbar(message, {
            variant: 'success'
          });

          setTimeout(() => {
            setHandleModal(false);
            navigate(0);
          }, 2000);
        }
      } else {
        enqueueSnackbar(data.message || 'Ocorreu um problema.', {
          variant: 'error'
        });
        setLoading(false);
        setFiles([]);
        setLogs({ imported: [], not_imported: [] });
      }
    } catch (error: AxiosError<{ message: string }> | any) {
      const message = error.response?.data?.message || 'Ocorreu um erro!';
      enqueueSnackbar(message, {
        variant: 'error'
      });
      setLoading(false);
      setFiles([]);
    }
  };

  return (
    <Dialog maxWidth="lg" fullWidth open={handleModal}>
      <DialogContent style={{ overflowX: 'hidden' }}>
        <UploadMultiFile
          showPreview={false}
          files={files}
          onDrop={handleDropMultiFile}
          onRemove={handleRemove}
          accept="text/xml"
          logs={logs}
        />
      </DialogContent>
      <DialogActions>
        <Stack
          justifyContent="flex-end"
          alignItems="flex-end"
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 2 }}
        >
          <Button color="primary" variant="outlined" onClick={onCloseModal}>
            Cancelar
          </Button>

          {hasFile && (
            <>
              <Box sx={{ display: 'flex' }}>
                <Button variant="outlined" type="button" color="primary" onClick={handleRemoveAll}>
                  Remover todos
                </Button>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Button variant="contained" onClick={sendFiles}>
                  {loading ? (
                    <LoadingButton
                      loading
                      size="medium"
                      variant="contained"
                      style={{
                        width: '100%',
                        padding: '12px 0',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex'
                      }}
                    />
                  ) : (
                    'Iniciar importação'
                  )}
                </Button>
              </Box>
            </>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ImporterModal;

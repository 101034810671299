import { useSnackbar } from 'notistack5';

import { Box, Container, Typography, Stack } from '@material-ui/core';

import { useEffect, useState, ReactNode } from 'react';

import Logo from 'components/Logo';

import { Step, StepLabel, Stepper } from '@mui/material';
import CheckIcon from '@material-ui/icons/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { StepIconProps } from '@mui/material/StepIcon';
import { ContentStyle, RootStyle } from './styles';
import { AutoCompleteBillingDataProps, RegisterProps } from '../Model';
import DadosEmpresa from './DadosEmpresa';
import Cobranca from './Cobranca';
import Licencas from './Licencas/index';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#00AB55'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#00AB55'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1
  }
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#00AB55'
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#00AB55',
      zIndex: 1,
      fontSize: 18
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor'
    }
  })
);

interface ComponentsStepsProps {
  id: number;
  component: ReactNode;
}

const steps = ['Dados da Empresa', 'Licenças', 'Dados de Cobrança e Faturamento'];

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <CheckIcon className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const View: React.FC<RegisterProps> = ({
  dataCnpj,
  loading,
  dataAutocomplete,
  dataAutocompleteError,
  listPlans
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [autoCompleteBillingData, setAutoCompleteBillingData] =
    useState<AutoCompleteBillingDataProps>({
      address: '',
      bairro: '',
      cep: '',
      city: '',
      complemento: '',
      number: '',
      state: ''
    });

  const isStepSkipped = (step: number) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const componentsSteps: ComponentsStepsProps[] = [
    {
      id: 1,
      component: (
        <DadosEmpresa
          dataCnpj={dataCnpj}
          dataAutocomplete={dataAutocomplete}
          loading={loading}
          dataAutocompleteError={dataAutocompleteError}
          handleNext={handleNext}
          setAutoCompleteBillingData={setAutoCompleteBillingData}
        />
      )
    },
    {
      id: 1,
      component: <Licencas handleNext={handleNext} handleBack={handleBack} />
    },
    {
      id: 1,
      component: (
        <Cobranca listPlans={listPlans} handleBack={handleBack} {...{ autoCompleteBillingData }} />
      )
    }
  ];

  return (
    <RootStyle title="Cadastrar-se na TRIB">
      <Container>
        <ContentStyle>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ mb: 5, position: 'relative' }}
          >
            <Logo sx={{ width: 150, height: 150, alignSelf: 'center' }} />
            <Box sx={{ flexGrow: 1 }}>
              <Typography sx={{ color: 'text.secondary' }}>Cadastre sua Empresa</Typography>
            </Box>
          </Stack>

          <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {componentsSteps[activeStep].component}
          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default View;

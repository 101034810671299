import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack5';
import endpoints from 'services/request/endpoints';
import { axiosInstance } from 'services/instance';
import { AdministrativePort195Props } from './View/Model';
import View from './View';
import { NcmProps } from '../../attachmentX/list/Model';

const NewPort195: React.FC = () => {
  const params = useParams();
  const [typePage, setTypePage] = useState<'New' | 'Edit'>('New');
  const [listSegments, setListSegments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ncmList, setNcmList] = useState<NcmProps[]>([]);

  // edit
  const [currentSelected, setCurrentSelected] = useState<AdministrativePort195Props>({
    id: 0,
    item: '',
    codigo: '',
    cest: '',
    mva: '',
    mva_outorgado: '',
    descricao: '',
    ativo: true,
    vigencia_inicio: '',
    vigencia_fim: '',
    created_at: '',
    updated_at: '',
    segmento: {
      id: 0,
      nome: '',
      item: '',
      codigo: '',
      ativo: true,
      vigencia_inicio: '',
      vigencia_fim: '',
      created_at: '',
      updated_at: ''
    }
  });

  const { enqueueSnackbar } = useSnackbar();

  async function getListNcm(id: number) {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/administrative/table-port195/${id}/ncms`;

    try {
      const { data } = await axiosInstance.get(endpoint);
      await setNcmList(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function getListSegments() {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.segments}`;

    try {
      const { data } = await axiosInstance.get(endpoint);
      await setListSegments(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function callEdit() {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.port195}/${params.id}`;
    setLoading(true);
    setTypePage('Edit');

    try {
      const { data } = await axiosInstance.get(endpoint);

      if (currentSelected.id < 0) {
        setNcmList(data.ncm);
      } else {
        getListNcm(data.id);
      }

      await setCurrentSelected(data);
      setLoading(false);
    } catch (error: any) {
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
      setLoading(false);
    }
  }

  useEffect(() => {
    getListSegments();

    if (params && params.id) {
      setTypePage('Edit');
      callEdit();
    }
  }, []);

  return (
    <View
      loading={loading}
      typePage={typePage}
      listSegments={listSegments}
      selected={currentSelected}
      ncmList={ncmList}
      setNcmList={setNcmList}
    />
  );
};
export default NewPort195;

import { axiosInstance } from 'services/instance';
import endpoints from 'services/request/endpoints';
import { useEffect, useState } from 'react';
import currencyBrl from 'utils/currencyBrl';
import { useLocation } from 'react-router';
import { PlanFreeIcon } from 'assets';
import View from './view';
import { PLANSProps, PeriodsType, licencesProps } from './model';

const Licences: React.FC = () => {
  const [period, setPeriod] = useState<PeriodsType>('mensal');
  const [licences, setLicences] = useState<licencesProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<string>('');
  const [currentPlanIndex, setCurrentPlanIndex] = useState<number>(-1);
  const { pathname } = useLocation();
  const labelButton = 'Selecionar Plano';

  const getList = async () => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.integrator.plans}`;
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.get(endpoint);
      setLicences(data.reverse());
      setIsLoading(false);
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  };

  const getCurrentPlan = async () => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.integrator.profile}`;
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.get(endpoint);
      setCurrentPlan(data.cobranca.plano.descricao);
      if (pathname.includes('/licencas/upgrade')) setCurrentPlanIndex(data.cobranca.plano.id - 1);
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getList();
    getCurrentPlan();
  }, []);

  const PLANS: PLANSProps[] | undefined = licences?.map((licence) => {
    let desconto = 0;
    if (period === 'trimestral') {
      desconto = licence.valor * licence.desconto_trimestral * 0.01;
    } else if (period === 'anual') {
      desconto = licence.valor * licence.desconto_anual * 0.01;
    }
    const licenceItem = {
      subscription: licence.descricao,
      icon: <PlanFreeIcon />,
      price: licence.valor - desconto,
      caption: `${currencyBrl((licence.valor - desconto) / licence.quantidade_cnpj)} / CNPJ`,
      lists: [
        { text: `Até ${licence.quantidade_cnpj} CNPJs`, isAvailable: true },
        {
          text: 'Contato Direto',
          isAvailable: licence.descricao === 'Ouro' || licence.descricao === 'Diamante'
        }
      ],
      labelAction: labelButton,
      licence: { ...licence }
    };
    return licenceItem;
  });

  return <View {...{ PLANS, period, setPeriod, isLoading, currentPlan, currentPlanIndex }} />;
};

export default Licences;

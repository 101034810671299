import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Card, Button, Grid } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import endpoints from 'services/request/endpoints';
import { MaskCNPJ } from 'utils/masks';
import { useEffect } from 'react';
import { IcmsFilterProps, ListCompanies } from '../Model';

const IcmsFilter: React.FC<IcmsFilterProps> = ({
  getNfs,
  listCompanies,
  currentLanguage,
  rememberValues,
  setCompany,
  company
}) => {
  const filter = createFilterOptions<ListCompanies>();

  const ValidationSchema = Yup.object().shape({
    company: Yup.string().required('A empresa é Obrigatória.'),
    initialDate: Yup.date().nullable(),
    finalDate: Yup.date()
      .nullable()
      .min(Yup.ref('initialDate'), 'A data final deve ser posterior à data inicial.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    initialValues: rememberValues,
    onSubmit: async (values) => {
      const { client, company, initialDate, finalDate } = values;
      const companyArray = client.split(' ');
      const companyValue = companyArray[companyArray.length - 1]
        .replace('.', '')
        .replace('.', '')
        .replace('-', '')
        .replace('/', '');
      let parameters = '';

      const data_inicio = dayjs(initialDate).format('YYYY-MM-DD');
      const data_fim = dayjs(finalDate).format('YYYY-MM-DD');

      if (company) {
        if (initialDate || finalDate) {
          if (initialDate && finalDate) {
            parameters = `?empresa=${companyValue}&data_inicio=${data_inicio}&data_fim=${data_fim}`;
          }
          if (initialDate && !finalDate) {
            parameters = `?empresa=${companyValue}&data_inicio=${data_inicio}`;
          }
          if (!initialDate && finalDate) {
            parameters = `?empresa=${companyValue}&data_inicio=${data_inicio}`;
          }
        } else {
          parameters = `?empresa=${companyValue}`;
        }
      } else {
        if (initialDate && finalDate) {
          parameters = `?data_inicio=${data_inicio}&data_fim=${data_fim}`;
        }
        if (initialDate && !finalDate) {
          parameters = `?data_inicio=${data_inicio}`;
        }
        if (!initialDate && finalDate) {
          parameters = `?data_fim=${finalDate}`;
        }
        if (!initialDate && !finalDate) {
          parameters = '';
        }
      }
      const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.clientV2.analyzer}${parameters}`;
      getNfs(endpoint, values);
    }
  });

  useEffect(() => {
    if (company) {
      formik.setFieldValue('company', company.fantasia);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Card sx={{ p: 3 }}>
          <Stack direction="row">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  {...formik.getFieldProps('company')}
                  onChange={(event, newValue) => {
                    setCompany(newValue);
                    if (typeof newValue === 'string') {
                      formik.setFieldValue('company', MaskCNPJ(newValue));
                    } else if (newValue && newValue.inputValue) {
                      formik.setFieldValue('company', MaskCNPJ(newValue.inputValue));
                    } else if (newValue && newValue.cnpj) {
                      formik.setFieldValue(
                        'company',
                        `${newValue.razao} ${MaskCNPJ(newValue.cnpj)}`
                      );
                    }
                  }}
                  filterOptions={(listCompanies, params) => {
                    const filtered = filter(listCompanies, params);
                    const { inputValue } = params;
                    const isExisting = listCompanies.some(
                      (option) =>
                        option.cnpj.includes(inputValue) ||
                        option.razao.includes(inputValue) ||
                        inputValue === `${option.razao} - ${MaskCNPJ(option.cnpj)}`
                    );
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        cnpj: `Pesquisar por: "${inputValue}"`
                      });
                    }

                    return filtered;
                  }}
                  options={listCompanies}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return `${option.inputValue}`;
                    }

                    return option.razao + option.cnpj;
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>{`${option.razao ? `${option.razao} - ` : ''}${MaskCNPJ(
                      option.cnpj
                    )}`}</li>
                  )}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Empresa"
                      error={Boolean(touched.company && errors.company)}
                      helperText={touched.company && errors.company}
                    />
                  )}
                />
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={currentLanguage}>
                <Grid item xs={4}>
                  <DatePicker
                    label="Data inicial"
                    {...getFieldProps('initialDate')}
                    onChange={(value) => formik.setFieldValue('initialDate', value)}
                    renderInput={(props) => (
                      <TextField
                        fullWidth
                        {...props}
                        inputProps={{ ...props.inputProps, placeholder: 'dd/mm/aaaa' }}
                        autoComplete="off"
                        error={Boolean(touched.initialDate && errors.initialDate)}
                        helperText={touched.initialDate && errors.initialDate}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DatePicker
                    label="Data final"
                    {...getFieldProps('finalDate')}
                    onChange={(value) => formik.setFieldValue('finalDate', value)}
                    renderInput={(props) => (
                      <TextField
                        fullWidth
                        {...props}
                        inputProps={{ ...props.inputProps, placeholder: 'dd/mm/aaaa' }}
                        autoComplete="off"
                        error={Boolean(touched.finalDate && errors.finalDate)}
                        helperText={touched.finalDate && errors.finalDate}
                      />
                    )}
                  />
                </Grid>
              </LocalizationProvider>
              <Grid item xs={4}>
                <Button
                  sx={{ width: '100%', height: '56px', fontSize: '16px' }}
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  Analisar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Card>
      </Form>
    </FormikProvider>
  );
};

export default IcmsFilter;

import { forEach, isString } from 'lodash';
import { Icon } from '@iconify/react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import fileFill from '@iconify/icons-eva/file-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import { motion, AnimatePresence } from 'framer-motion';
// material
import { alpha, Theme, styled } from '@material-ui/core/styles';
import {
  Box,
  List,
  Stack,
  Paper,
  Button,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import { SxProps } from '@material-ui/system';
// utils
import { useEffect } from 'react';
import { fData } from '../../utils/formatNumber';
//
import { MIconButton } from '../@material-extend';
import { varFadeInRight } from '../animate';
import { UploadIllustration } from '../../assets';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
}));

// ----------------------------------------------------------------------

interface CustomFile extends File {
  path?: string;
  preview?: string;
}

interface LogsProps {
  imported: string[];
  not_imported: string[];
}

interface UploadMultiFileProps extends DropzoneOptions {
  error?: boolean;
  files: (File | string)[];
  showPreview: boolean;
  onRemove: (file: File | string) => void;
  onRemoveAll?: VoidFunction;
  sx?: SxProps<Theme>;
  logs?: LogsProps;
}

const getFileData = (file: CustomFile | string) => {
  if (typeof file === 'string') {
    return {
      key: file
    };
  }
  return {
    key: file.name,
    name: file.name,
    size: file.size,
    preview: file.preview
  };
};
export default function UploadMultiFile({
  error,
  showPreview = false,
  files,
  onRemove,
  onRemoveAll,
  sx,
  logs,
  ...other
}: UploadMultiFileProps) {
  const hasFile = files.length > 0;

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other
  });

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size }: CustomFile = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  function validateXmlStatus(
    item: string | undefined,
    logs: { imported: string[]; not_imported: string[] } | undefined
  ) {
    enum colors {
      imported = '#00BA14',
      oldImported = '#0041ba',
      not_imported = '#FF0000',
      invalid = '#FFA303',
      not_a_client_nfe = '#FF8C00',
      default = '#86898b'
    }

    const search = item || '';

    const existsInImported = logs?.imported?.find((el) => el.includes(search));

    if (existsInImported) {
      return {
        label: 'Importado',
        color: colors.imported
      };
    }

    const existsInNotImported = logs?.not_imported?.find((el) => el.includes(search));
    const isInvalid = existsInNotImported?.includes('cteproc');
    const hasOldImport = existsInNotImported?.includes('Já importada');
    const notAClientNfe = existsInNotImported?.includes(
      'nfeproc - O destinatário não pertence a sua lista de clientes.'
    );

    if (existsInNotImported) {
      if (isInvalid) {
        return {
          label: 'Arquivo inválido',
          color: colors.invalid
        };
      }

      if (hasOldImport) {
        return {
          label: 'Já importada anteriormente',
          color: colors.oldImported
        };
      }

      if (notAClientNfe) {
        return {
          label: 'O destinatário não pertence à sua lista de clientes.',
          color: colors.not_a_client_nfe
        };
      }
      return {
        label: 'Não importado',
        color: colors.not_imported
      };
    }

    return {
      label: '',
      color: colors.default
    };
  }

  // useEffect(() => {
  //   console.log('logs', logs);
  // }, [logs]);

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          })
        }}
      >
        <input {...getInputProps()} />

        <UploadIllustration sx={{ width: 220 }} />

        <Box sx={{ p: 3, ml: { md: 2 } }}>
          <Typography gutterBottom variant="h5">
            Importar Notas Fiscais em formato XML
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Clique, ou arraste e solte, notas em XML para fazer a importação.
          </Typography>
        </Box>
      </DropZoneStyle>

      {fileRejections.length > 0 && <ShowRejectionItems />}

      <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
        <AnimatePresence>
          {files.map((file) => {
            const { key, name, size, preview } = getFileData(file as CustomFile);

            if (showPreview) {
              return (
                <ListItem
                  key={key}
                  component={motion.div}
                  {...varFadeInRight}
                  sx={{
                    p: 0,
                    m: 0.5,
                    width: 80,
                    height: 80,
                    borderRadius: 1.5,
                    overflow: 'hidden',
                    position: 'relative',
                    display: 'inline-flex'
                  }}
                >
                  <Paper
                    variant="outlined"
                    component="img"
                    src={isString(file) ? file : preview}
                    sx={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }}
                  />

                  <Box sx={{ top: 6, right: 6, position: 'absolute' }}>
                    <MIconButton
                      size="small"
                      onClick={() => onRemove(file)}
                      sx={{
                        p: '2px',
                        color: 'common.white',
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                        '&:hover': {
                          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
                        }
                      }}
                    >
                      <Icon icon={closeFill} />
                    </MIconButton>
                  </Box>
                </ListItem>
              );
            }

            return (
              <ListItem
                key={key}
                component={motion.div}
                {...varFadeInRight}
                sx={{
                  my: 1,
                  py: 0.75,
                  px: 2,
                  borderRadius: 1,
                  bgcolor: 'background.paper',
                  border: `1px solid ${validateXmlStatus(name, logs).color}`
                }}
              >
                <ListItemIcon>
                  <Icon
                    icon={fileFill}
                    width={28}
                    height={28}
                    color={validateXmlStatus(name, logs).color}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={isString(file) ? file : name}
                  secondary={`${isString(file) ? '' : fData(size || 0)} - ${
                    validateXmlStatus(name, logs).label
                  }`}
                  primaryTypographyProps={{ variant: 'subtitle2' }}
                  secondaryTypographyProps={{ variant: 'caption' }}
                />
                <ListItemSecondaryAction>
                  <MIconButton edge="end" size="small" onClick={() => onRemove(file)}>
                    <Icon icon={closeFill} />
                  </MIconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </AnimatePresence>
      </List>

      {hasFile && (
        <Stack direction="row" justifyContent="flex-end">
          {onRemoveAll && (
            <Button onClick={onRemoveAll} sx={{ mr: 1.5 }}>
              Remover todos
            </Button>
          )}
        </Stack>
      )}
    </Box>
  );
}

import React, { FC, ReactElement, useState } from 'react';

import View from './list/View/xml';

const PageXmlClient: FC = (): ReactElement => {
  const [files, setFiles] = useState<(File | string)[]>([]);

  return <View files={files} setFiles={setFiles} />;
};

export default PageXmlClient;

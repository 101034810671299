import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
// material
import { styled, useTheme } from '@material-ui/core/styles';
import { Box, Toolbar, OutlinedInput, InputAdornment, Typography } from '@material-ui/core';
import { useState } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  })
}));

// ----------------------------------------------------------------------

type CompanyListToolbarProps = {
  numSelected: number;
  filterCompany: string;
  onFilterCompany: (value: string) => void;
};

export default function CompanyListToolbar({
  numSelected,
  filterCompany,
  onFilterCompany
}: CompanyListToolbarProps) {
  const theme = useTheme();
  const [value, setValue] = useState('');
  const [isHover, setIsHover] = useState(false);

  const isLight = theme.palette.mode === 'light';

  const handleFilter = () => {
    onFilterCompany(value);
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? 'primary.main' : 'text.primary',
          bgcolor: isLight ? 'primary.lighter' : 'primary.dark'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selecionado(s)
        </Typography>
      ) : (
        <>
          <SearchStyle
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Digite para pesquisar..."
          />

          <button
            type="button"
            style={{
              background: isHover ? '#00000005' : 'transparent',
              marginLeft: '8px',
              width: '62px',
              borderRadius: '8px',
              height: '56px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid rgba(145, 158, 171, 0.32)',
              cursor: 'pointer'
            }}
            onClick={() => handleFilter()}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <InputAdornment position="start">
              <Box
                component={Icon}
                icon={searchFill}
                fontSize={20}
                sx={{ color: 'text.disabled' }}
              />
            </InputAdornment>
          </button>
        </>
      )}
    </RootStyle>
  );
}

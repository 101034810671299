import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack5';
import React from 'react';
import * as Yup from 'yup';
import DatePicker from '@material-ui/lab/DatePicker';
import { axiosInstance } from 'services/instance';
import InputMask from 'react-input-mask';
import { useParams } from 'react-router';
import { NcmViewProps, NcmDataProps } from '../Model';

const View: React.FC<NcmViewProps> = ({
  handleIsDialog,
  setHandleIsDialog,
  ncmList,
  setNcmList,
  ncmId,
  setNcmId,
  ncm,
  setNcm,
  description,
  setDescription,
  active,
  setActive,
  vigencStart,
  setVigencStart,
  vigencEnd,
  setVigencEnd,
  typePage
}): React.ReactElement => {
  const params = useParams();
  const validationSchema = Yup.object().shape({
    ncm: Yup.string().required('O NCM é obrigatório.'),
    description: Yup.string().required('A Descrição é obrigatória.'),
    vigenc_start: Yup.date().nullable().required('A data da vigência inicial é obrigatória.'),
    vigenc_end: Yup.date()
      .required('A data da vigência final é obrigatória.')
      .nullable()
      .min(Yup.ref('vigenc_start'), 'A data de término deve ser posterior à data de início')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ncm: ncm || '',
      description: description || '',
      active: active || true,
      vigenc_start: vigencStart || '',
      vigenc_end: vigencEnd || ''
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      handleSendData(values, setSubmitting);
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, resetForm } =
    formik;

  const { enqueueSnackbar } = useSnackbar();

  const closeDialog = () => {
    setHandleIsDialog(false);
    setNcmId(0);
    resetForm();
    setNcm('');
    setDescription('');
    setActive(false);
    setVigencStart('');
    setVigencEnd('');
  };

  const handleSendData = (values: NcmDataProps, setSubmitting: any) => {
    if (typePage !== 'Edit') {
      handleNewNcm(values);
    } else {
      handleEditNcm(values, setSubmitting);
    }
  };

  const handleNewNcm = (values: NcmDataProps) => {
    const index = ncmList.findIndex((el) => el.id === ncmId);

    const payload = {
      id: ncmId,
      ncm: values.ncm,
      descricao: values.description,
      ativo: values.active,
      vigencia_inicio: values.vigenc_start,
      vigencia_fim: values.vigenc_end
    };

    if (ncmId > 0) {
      const temp = [...ncmList];
      temp.splice(index, 1, payload);
      setNcmList(temp);

      closeDialog();
    } else {
      setNcmList((old: any) => [
        ...old,
        {
          id: old.length + 1,
          ncm: values.ncm,
          descricao: values.description,
          ativo: values.active,
          vigencia_inicio: values.vigenc_start,
          vigencia_fim: values.vigenc_end
        }
      ]);

      closeDialog();
    }
  };

  const handleEditNcm = async (values: NcmDataProps, setSubmitting: any) => {
    const payload = {
      id: ncmId,
      ncm: values.ncm,
      descricao: values.description,
      ativo: values.active,
      vigencia_inicio: values.vigenc_start,
      vigencia_fim: values.vigenc_end
    };

    if (ncmId > 0) {
      setSubmitting(true);

      try {
        const { data } = await axiosInstance.put(
          `administrative/table-port195/${params.id}/ncm/${ncmId}`,
          payload
        );

        setNcmList(data.data);

        setSubmitting(false);
        closeDialog();
      } catch (error: any) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        setSubmitting(false);
      }
      // NEW
    } else {
      setSubmitting(true);
      try {
        const { data } = await axiosInstance.post(
          `administrative/table-port195/${params.id}/ncm`,
          payload
        );

        closeDialog();

        if (data) {
          setNcmList(data.data);
        }
      } catch (error: any) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        console.log('error', error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={handleIsDialog}>
      <FormikProvider value={formik}>
        <DialogContent>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={{ xs: 2, md: 3 }}>
              <Typography variant="h5">
                {ncmId > 0 ? 'Editar' : 'Cadastrar'} NCM{ncm && `: ${ncm}`}
              </Typography>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                <InputMask
                  formatChars={{ '9': '[0-9.]' }}
                  mask="9999999999"
                  disabled={false}
                  maskChar=""
                  {...getFieldProps('ncm')}
                  error={Boolean(touched.ncm && errors.ncm)}
                  helperText={touched.ncm && errors.ncm}
                >
                  {(inputProps: any) => <TextField fullWidth label="NCM" {...inputProps} />}
                </InputMask>
                <TextField
                  fullWidth
                  label="Descrição"
                  {...getFieldProps('description')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                  sx={{ width: 600 }}
                />
              </Stack>

              <Stack spacing={{ xs: 2, sm: 3 }} direction={{ xs: 'column', sm: 'row' }}>
                <DatePicker
                  label="Data inicial da vigência"
                  {...getFieldProps('vigenc_start')}
                  onChange={(date) => setFieldValue('vigenc_start', date)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      error={Boolean(touched.vigenc_start && errors.vigenc_start)}
                    />
                  )}
                  inputFormat="dd/MM/yyyy"
                />

                <DatePicker
                  label="Data final da vigência"
                  {...getFieldProps('vigenc_end')}
                  onChange={(date) => setFieldValue('vigenc_end', date)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      error={Boolean(touched.vigenc_end && errors.vigenc_end)}
                    />
                  )}
                  inputFormat="dd/MM/yyyy"
                />
              </Stack>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={getFieldProps('active').value}
                      onChange={(event, value) => setFieldValue('active', value)}
                    />
                  }
                  label="Ativo"
                />
              </FormGroup>
            </Stack>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={() => closeDialog()}>
            Cancelar
          </Button>

          <LoadingButton
            variant="contained"
            type="button"
            loading={isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            {ncmId > 0 ? 'Atualizar' : 'Cadastrar'}
          </LoadingButton>
        </DialogActions>
      </FormikProvider>
    </Dialog>
  );
};

export default View;

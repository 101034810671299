// material
import { Stack, Typography } from '@material-ui/core';
// utils
import moment from 'moment';
import { CompanyInvoicingHistoricProps, ViewHistoricProps } from '../../Model';
// @types

// ----------------------------------------------------------------------

export default function AccountBillingInvoiceHistory({ historic }: ViewHistoricProps) {
  return (
    <Stack spacing={3} alignItems="flex-end">
      <Typography variant="subtitle1" sx={{ width: 1 }}>
        Histórico de Faturas
      </Typography>

      <Stack spacing={2} sx={{ width: 1 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ width: 1 }}>
          <Typography variant="button" sx={{ minWidth: 120 }}>
            Vencimento
          </Typography>
          <Typography variant="button" sx={{ minWidth: 180 }} style={{ textTransform: 'none' }}>
            Método de pagamento
          </Typography>
          <Typography variant="button" sx={{ minWidth: 100 }}>
            Situação
          </Typography>
        </Stack>
      </Stack>

      <Stack spacing={2} sx={{ width: 1 }}>
        {historic?.map((item: CompanyInvoicingHistoricProps) => (
          <Stack key={item.id} direction="row" justifyContent="space-between" sx={{ width: 1 }}>
            <Typography variant="body2" sx={{ minWidth: 120 }}>
              {moment(item.vencimento).format('DD/MM/YYYY')}
            </Typography>
            <Typography variant="body2" sx={{ minWidth: 180 }}>
              {item.metodo_pagamento}
            </Typography>
            <Typography variant="body2" sx={{ minWidth: 100 }}>
              {item.situacao}
            </Typography>
            {/* <Link component={RouterLink} to="#">
              PDF
            </Link> */}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

import useSettings from 'hooks/useSettings';
import React, { useEffect, useState } from 'react';
import endpoints from 'services/request/endpoints';
import { axiosInstance } from 'services/instance';

import View from './View';
import { DataItensProps, Port195Prop } from './Model';

const ListPort195Company: React.FC = () => {
  const { themeStretch } = useSettings();
  const [list, setList] = useState<any[]>([]);
  const [filter, setFilter] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [currentTab, setCurrentTab] = useState('active');
  const [loading, setLoading] = useState(false);
  const [dataItens, setDataItens] = useState<DataItensProps[]>([]);

  const getList = async () => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.integrator.tables.port195s}`;
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(endpoint);

      const forRender = [] as { segment: string; items: Port195Prop[] }[];

      for (const [key, value] of Object.entries(data as DataItensProps)) {
        if (value && value.length > 0) {
          const typedKey: string = key;
          const typedValue: Port195Prop[] = value;

          if (typedValue.length > 0) {
            forRender.push({ segment: typedKey.split(' - ')[1], items: typedValue });
          }
        }
      }

      setDataItens(forRender);

      setList(data.SEGMENTO.SEGMENTO);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const TABLE_HEAD = [
    { id: 'id', label: 'Cód.', alignRight: false },
    { id: 'item', label: 'Item', alignRight: false },
    { id: 'cest', label: 'CEST', alignRight: false },
    { id: 'ncm', label: 'NCM', alignRight: false },
    { id: 'descricao', label: 'Descrição', alignRight: false, minWidth: 100 },
    { id: 'mva_outorgado', label: 'MVA Outorgado', alignRight: false, minWidth: 100 },
    { id: 'mva', label: 'MVA Art. 2B', alignRight: false, minWidth: 100 },
    { id: 'updated_at', label: 'Atualizado em', alignRight: false }
  ];

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (filter: string) => {
    setFilter(filter);
  };

  const filteredList = dataItens
    .map((element) => {
      const filt = element.items.filter((ele) => {
        const { codigo, descricao, item, cest, mva_outorgado, mva, ativo } = ele;
        if (currentTab === 'active') {
          return (
            ativo === true &&
            (`${codigo}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
              `${item}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
              `${cest}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
              `${descricao}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
              `${mva_outorgado}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
              `${mva}`.toLowerCase().includes(`${filter}`.toLowerCase()))
          );
        }

        if (currentTab === 'inactive') {
          return (
            ativo === false &&
            (`${codigo}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
              `${item}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
              `${cest}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
              `${descricao}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
              `${mva_outorgado}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
              `${mva}`.toLowerCase().includes(`${filter}`.toLowerCase()))
          );
        }

        return (
          `${codigo}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
          `${item}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
          `${cest}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
          `${descricao}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
          `${mva_outorgado}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
          `${mva}`.toLowerCase().includes(`${filter}`.toLowerCase())
        );
      });

      return { segment: element.segment, items: filt };
    })
    .filter((item) => {
      if (item.items.length > 0) return true;
      return false;
    });

  const isNofilterResult = filteredList.length === 0;

  return (
    <View
      {...{
        themeStretch,
        filteredList,
        TABLE_HEAD,
        handleFilter,
        isLoading: loading,
        isNofilterResult,
        page,
        rowsPerPage,
        setCurrentTab,
        currentTab,
        emptyRows,
        filter,
        handleChangeRowsPerPage,
        setPage
      }}
    />
  );
};

export default ListPort195Company;

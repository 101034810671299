import { Form, FormikProvider, useFormik } from 'formik';
// material
import {
  Card,
  Typography,
  Grid,
  Stack,
  TextField,
  Autocomplete,
  FormControlLabel,
  Switch,
  Container
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import InputMask from 'react-input-mask';

import LoadingScreen from 'components/LoadingScreen';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { CompanyDataViewProps } from '../Model';

const dropTributacao = ['Simples Nacional', 'Lucro Presumido', 'Lucro Real'];
const dropContribuente = ['Contribuinte', 'Contribuinte Isento', 'Não contribuinte'];
const questRed = ['Anúncios Patrocinados', 'Facebook', 'Google', 'Instagram', 'Outdoor', 'Outros'];

const Profile: React.FC<CompanyDataViewProps> = ({ data }) => {
  const { isLoading, companyData } = data;
  const navigate = useNavigate();
  const [vmei, setvmei] = useState(false);
  const [inscEst, setInscEst] = useState('');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cod: '',
      company: '',
      companyName: '',
      cnpj: '',
      tax: 'simpleNational' || 'presumedProfit' || 'realProfit',
      icmsWithinTheState: false,
      ordinance195Granted: false,
      limitICMSRate: false,
      name: '',
      email: '',
      emailNfe: '',
      confirmEmail: '',
      phoneNumber: '',
      cellNumber: '',
      number: '',
      complemento: '',
      bairro: '',
      zipCode: '',
      regimeTax: '',
      dateIn: '',
      ie: '',
      icms: '',
      address: '',
      city: '',
      state: '',
      mei: false,
      arrived: ''
    },
    onSubmit: async (values) => {}
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    if (companyData) {
      formik.setFieldValue('cnpj', companyData.cnpj);
      formik.setFieldValue('company', companyData.razao);
      formik.setFieldValue('companyName', companyData.fantasia);
      formik.setFieldValue('zipCode', companyData.cep);
      formik.setFieldValue('address', companyData.endereco);
      formik.setFieldValue('number', companyData.numero);
      formik.setFieldValue('complemento', companyData.complemento);
      formik.setFieldValue('bairro', companyData.bairro);
      formik.setFieldValue('city', companyData.cidade);
      formik.setFieldValue('state', companyData.estado);
      formik.setFieldValue('dateIn', moment(companyData.fundado_em).format('DD-MM-YYYY'));
      formik.setFieldValue('emailNfe', companyData.email_cnpj);

      formik.setFieldValue('regimeTax', companyData.regime_tributario);
      formik.setFieldValue('icms', companyData.contribuinte_icms);

      formik.setFieldValue('name', companyData.nome_contato);
      formik.setFieldValue('email', companyData.email_contato);
      formik.setFieldValue('confirmEmail', companyData.email_contato);
      formik.setFieldValue('cellNumber', companyData.telefone_celular);
      formik.setFieldValue('phoneNumber', companyData.telefone_fixo);
      formik.setFieldValue('arrived', companyData.chegou_via);
      setInscEst(`${companyData.ie}`);
    }
  }, [companyData]);

  useEffect(() => {
    formik.setFieldValue('ie', inscEst);
  }, [inscEst]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Container maxWidth="lg" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="CNPJ"
                          {...getFieldProps('cnpj')}
                          error={Boolean(touched.cnpj && errors.cnpj)}
                          helperText={touched.cnpj && errors.cnpj}
                          disabled
                        />

                        <TextField
                          fullWidth
                          label="Razão Social"
                          {...getFieldProps('company')}
                          error={Boolean(touched.company && errors.company)}
                          helperText={touched.company && errors.company}
                          disabled
                        />
                      </Stack>
                      <TextField
                        fullWidth
                        label="Nome Fantasia"
                        disabled
                        {...getFieldProps('companyName')}
                        error={Boolean(touched.companyName && errors.companyName)}
                        helperText={touched.companyName && errors.companyName}
                      />
                      <Typography variant="h5">Endereço</Typography>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                        <TextField
                          sx={{ width: 550 }}
                          fullWidth
                          label="CEP"
                          {...getFieldProps('zipCode')}
                          disabled
                        />
                        <TextField
                          sx={{ width: 550 }}
                          fullWidth
                          label="Endereço (Rua)"
                          {...getFieldProps('address')}
                          disabled
                        />
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                        <TextField
                          sx={{ width: 550 }}
                          fullWidth
                          label="Número"
                          {...getFieldProps('number')}
                          disabled
                        />
                        <TextField
                          sx={{ width: 550 }}
                          fullWidth
                          label="Complemento"
                          {...getFieldProps('complemento')}
                          disabled
                        />
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                        <TextField
                          sx={{ width: 820 }}
                          fullWidth
                          label="Bairro"
                          {...getFieldProps('bairro')}
                          disabled
                        />
                        <TextField
                          sx={{ width: 600 }}
                          fullWidth
                          label="Cidade"
                          {...getFieldProps('city')}
                          disabled
                        />
                        <TextField
                          sx={{ width: 200 }}
                          label="Estado"
                          {...getFieldProps('state')}
                          disabled
                        />
                      </Stack>
                      <Typography variant="h5">Fiscal</Typography>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={dropTributacao}
                          sx={{ width: 600 }}
                          {...getFieldProps('regimeTax')}
                          onChange={(event, value) => formik.setFieldValue('regimeTax', value)}
                          disabled
                          renderInput={(regimeTaxProp: any) => (
                            <TextField
                              {...regimeTaxProp}
                              label="Regime Tributário"
                              error={Boolean(touched.regimeTax && errors.regimeTax)}
                            />
                          )}
                        />
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={dropContribuente}
                          sx={{ width: 600 }}
                          {...getFieldProps('icms')}
                          onChange={(event, value) => formik.setFieldValue('icms', value)}
                          disabled
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Contribuinte ICMS"
                              error={Boolean(touched.icms && errors.icms)}
                            />
                          )}
                        />
                        {formik.values.icms !== 'Não contribuinte' ? (
                          <InputMask
                            sx={{ width: 600 }}
                            {...getFieldProps('ie')}
                            onChange={(event: any) => {
                              setInscEst(event.target.value);
                            }}
                            maskChar=""
                            mask="999.999.999.999"
                            helperText={touched.ie && errors.ie}
                            error={Boolean(touched.ie && errors.ie)}
                            value={inscEst}
                            disabled
                          >
                            {(params: any) => <TextField {...params} label="Inscrição Estadual" />}
                          </InputMask>
                        ) : null}
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                        <TextField
                          sx={{ width: 360 }}
                          fullWidth
                          label="Data de Fundação"
                          {...getFieldProps('dateIn')}
                          disabled
                        />
                        <TextField
                          sx={{ width: 355 }}
                          fullWidth
                          label="E-mail para envio de NFe"
                          {...getFieldProps('emailNfe')}
                          error={Boolean(touched.emailNfe && errors.emailNfe)}
                          helperText={touched.emailNfe && errors.emailNfe}
                          disabled
                        />
                        <FormControlLabel
                          {...getFieldProps('mei')}
                          checked={vmei}
                          onChange={() => setvmei(!vmei)}
                          control={<Switch />}
                          label="Microempreendedor individual (MEI)"
                          disabled
                        />
                      </Stack>
                      <Typography variant="h5">Contato</Typography>
                      <TextField
                        fullWidth
                        label="Nome completo"
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        disabled
                      />
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="E-mail"
                          placeholder="Exemplo@Trib"
                          {...getFieldProps('email')}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          disabled
                        />
                        <TextField
                          fullWidth
                          label="Confirmação de E-mail"
                          placeholder="Exemplo@Trib"
                          {...getFieldProps('confirmEmail')}
                          error={Boolean(touched.confirmEmail && errors.confirmEmail)}
                          helperText={touched.confirmEmail && errors.confirmEmail}
                          disabled
                        />
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="Telefone Fixo"
                          {...getFieldProps('phoneNumber')}
                          error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                          helperText={touched.phoneNumber && errors.phoneNumber}
                          disabled
                        />

                        <TextField
                          fullWidth
                          label="Celular"
                          {...getFieldProps('cellNumber')}
                          error={Boolean(touched.cellNumber && errors.cellNumber)}
                          helperText={touched.cellNumber && errors.cellNumber}
                          disabled
                        />
                      </Stack>

                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={questRed}
                        sx={{ width: '100%' }}
                        {...getFieldProps('arrived')}
                        onChange={(event, value) => formik.setFieldValue('arrived', value)}
                        disabled
                        renderInput={(params) => (
                          <TextField {...params} label="Onde nos encontrou" />
                        )}
                      />

                      <Stack
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 2 }}
                      >
                        <div
                          style={{
                            paddingTop: '30px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end'
                          }}
                        >
                          <LoadingButton
                            variant="contained"
                            type="submit"
                            loading={isSubmitting}
                            onClick={() => navigate('/faturamento')}
                          >
                            Dados de cobrança
                          </LoadingButton>
                        </div>
                      </Stack>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Container>
      )}
    </>
  );
};

export default Profile;

import { axiosInstance } from 'services/instance';
import endpoints from 'services/request/endpoints';
import { useEffect, useState } from 'react';
import currencyBrl from 'utils/currencyBrl';
import { PlanFreeIcon } from 'assets';
import { LoadingButton } from '@material-ui/lab';
import View from './view';
import { PLANSProps, PeriodsType, licencesProps } from './model';

interface LicencaProps {
  handleNext: () => void;
  handleBack: () => void;
}

const Licences: React.FC<LicencaProps> = ({ handleBack, handleNext }) => {
  const [period, setPeriod] = useState<PeriodsType>('mensal');
  const [licences, setLicences] = useState<licencesProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const labelButton = 'Selecionar Plano';

  const getList = async () => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.integrator.plans}`;
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.get(endpoint);
      setLicences(data.reverse());
      setIsLoading(false);
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const PLANS: PLANSProps[] | undefined = licences?.map((licence) => {
    let desconto = 0;
    if (period === 'trimestral') {
      desconto = licence.valor * licence.desconto_trimestral * 0.01;
    } else if (period === 'anual') {
      desconto = licence.valor * licence.desconto_anual * 0.01;
    }
    const licenceItem = {
      subscription: licence.descricao,
      icon: <PlanFreeIcon />,
      price: licence.valor - desconto,
      caption: `${currencyBrl((licence.valor - desconto) / licence.quantidade_cnpj)} / CNPJ`,
      lists: [
        { text: `Até ${licence.quantidade_cnpj} CNPJs`, isAvailable: true },
        {
          text: 'Contato Direto',
          isAvailable: licence.descricao === 'Ouro' || licence.descricao === 'Diamante'
        }
      ],
      labelAction: labelButton,
      licence: { ...licence }
    };
    return licenceItem;
  });

  return (
    <>
      <View {...{ PLANS, period, setPeriod, isLoading, handleBack, handleNext }} />
      <div
        style={{
          padding: '0 20px',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <LoadingButton variant="contained" type="button" onClick={() => handleBack()}>
          Voltar
        </LoadingButton>

        <LoadingButton variant="contained" type="button" onClick={() => handleNext()}>
          Próximo
        </LoadingButton>
      </div>
    </>
  );
};

export default Licences;

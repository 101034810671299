import { useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Stack,
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Button,
  Divider,
  TextField,
  Grid,
  InputAdornment
} from '@mui/material';
import currencyBrl from 'utils/currencyBrl';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { useNavigate } from 'react-router';
import LoadingScreen from 'components/LoadingScreen';
import { RootStyle } from './styles';
import Stepper from '../components/stepper';
import Product from '../components/product';
import PaymentMethods from '../components/paymentMethods';
import { CheckoutViewProps } from '../model';
import BillingForm from '../components/BillingForm';
import Preview from '../components/preview';

const View: React.FC<CheckoutViewProps> = ({
  isLoading,
  isPreview,

  // paymentMethods
  setCardValues,
  cardValues,
  method,
  setMethod,
  isSubmitted,
  setIsSubmitted,
  paymentOptions,
  installmentsOptions,
  handlePayment,
  isNewCard,
  setIsNewCard,

  // product
  planData,
  setPlanData,
  setNewPeriod,

  // stepper
  steps,

  // preview
  previewData,
  isPix,

  // BillingForm
  companyConfiguration,
  isSubmittedBillingForm,
  setIsSubmittedBillingForm
}) => {
  const [currentStep, setCurrentStep] = useState<'Carrinho' | 'Pagamento'>('Carrinho');
  const navigate = useNavigate();

  const { value, discount } = planData;

  const handleCheckout = () => {
    setCurrentStep('Pagamento');
  };

  const handleBack = () => {
    if (currentStep === 'Carrinho') {
      navigate(-1);
    } else {
      setCurrentStep('Carrinho');
    }
  };

  if (isLoading) {
    return (
      <RootStyle>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%', flexGrow: 1 }}
        >
          <LoadingScreen />
        </Stack>
      </RootStyle>
    );
  }

  if (isPreview) {
    return (
      <RootStyle style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
        <Preview {...{ previewData, isPix }} />
      </RootStyle>
    );
  }

  return (
    <RootStyle title="Checkout">
      <Container
        id="general-container"
        sx={{ maxWidth: 'lg', display: 'flex', flexDirection: 'column', gap: 5 }}
      >
        <HeaderBreadcrumbs
          heading="Checkout"
          links={[
            { name: 'Licenças', href: '/licencas' },
            { name: 'Adquirir Licença', href: '/checkout' },
            { name: 'Checkout' }
          ]}
          sx={{ margin: 0, padding: 0 }}
        />
        <Grid container spacing={3} direction="column">
          <Grid item container direction="row" spacing={3}>
            <Grid item md={8} xl={8}>
              {/* cart e stepper */}
              <Stack direction="column" spacing={5} sx={{ width: '100%' }}>
                <Stepper {...{ currentStep, steps }} />
                <Card id="cart" sx={{ width: '100%', height: 'auto', minHeight: { md: 450 } }}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ alignItems: 'center', padding: `${24}px ${24}px ${24}px` }}
                  >
                    <Typography fontWeight={700} fontSize="1.125rem">
                      {currentStep === 'Carrinho' ? 'Carrinho' : 'Formas de Pagamento'}
                    </Typography>
                  </Stack>
                  {currentStep === 'Carrinho' ? (
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow sx={{ borderRadius: 'none' }}>
                            <TableCell>Plano</TableCell>
                            <TableCell>Preço</TableCell>
                            <TableCell>Desconto</TableCell>
                            <TableCell>Período</TableCell>
                            <TableCell>Valor Total</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <Product {...{ planData, setPlanData, setNewPeriod }} />
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <PaymentMethods
                      {...{
                        setCardValues,
                        cardValues,
                        setMethod,
                        method,
                        isSubmitted,
                        paymentOptions,
                        installmentsOptions,
                        isNewCard,
                        setIsNewCard,
                        setIsSubmitted,
                        companyConfiguration
                      }}
                    />
                  )}
                </Card>
                {currentStep === 'Pagamento' && (
                  <Card sx={{ p: 3 }}>
                    <BillingForm
                      {...{
                        data: companyConfiguration,
                        method,
                        setMethod,
                        isSubmittedBillingForm,
                        setIsSubmittedBillingForm,
                        setIsSubmitted
                      }}
                    />
                  </Card>
                )}
              </Stack>
            </Grid>

            <Grid item md={4} sm={5}>
              <Box
                id="purchase_summary"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 3,
                  minWidth: 300,
                  marginTop: '100px'
                }}
              >
                <Card
                  sx={{
                    padding: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    gap: 3
                  }}
                >
                  <Typography fontWeight={700} fontSize="1.125rem" noWrap>
                    Resumo da Compra
                  </Typography>
                  <Stack sx={{ width: '100%', gap: 2 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography fontWeight={400} color=" rgb(99, 115, 129)" fontSize="0.875rem">
                        Sub Total
                      </Typography>
                      <Typography fontWeight={600} fontSize="0.875rem">
                        {currencyBrl(value)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography fontWeight={400} color=" rgb(99, 115, 129)" fontSize="0.875rem">
                        Desconto
                      </Typography>
                      <Typography fontWeight={600} fontSize="0.875rem">
                        {discount !== 0 ? currencyBrl(discount) : '-'}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography fontWeight={600} fontSize="1rem">
                        Total
                      </Typography>
                      <Typography fontWeight={600} fontSize="1rem">
                        {discount !== 0 ? currencyBrl(value - discount) : currencyBrl(value)}
                      </Typography>
                    </Box>
                  </Stack>

                  <TextField
                    label="Cupom de Desconto"
                    autoComplete="off"
                    sx={{ minWidth: 240 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button>Aplicar</Button>
                        </InputAdornment>
                      )
                    }}
                  />
                </Card>
                {currentStep === 'Carrinho' ? (
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ width: '100%' }}
                    onClick={handleCheckout}
                  >
                    Prosseguir
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ width: '100%' }}
                      onClick={() => setIsSubmittedBillingForm(true)}
                    >
                      Concluir Pagamento
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ width: '100%' }}
                      onClick={() => null}
                      disabled
                    >
                      Experimentar 7 dias Grátis
                    </Button>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
          <Button onClick={handleBack} sx={{ width: '50px', ml: 3, mt: 2, mb: 3 }}>
            Voltar
          </Button>
        </Grid>
      </Container>
    </RootStyle>
  );
};

export default View;

import useSettings from 'hooks/useSettings';
import { Box, Container, Tab, Tabs } from '@mui/material';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useAppSelector } from 'services/request/baseQuery';
import { useDispatch } from 'services/store';
import { fetchProfile } from 'services/store/actions/client/profile.action';
import Page from 'components/Page';
import { Icon } from '@iconify/react';
import { capitalCase } from 'change-case';
import accounntCircle from '@iconify/icons-ic/account-circle';

import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_CLIENT } from 'routes/paths';

import View from './View';

const ClientProfile: FC = (): ReactElement => {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();

  const [currentTab, setCurrentTab] = useState('conta');
  const [handleModal, setHandleModal] = useState(false);
  const [user] = useAppSelector((state) => [state.ProfileClient]);

  useEffect(() => {
    dispatch(fetchProfile());
  }, []);

  const PROFILE_TABS = [
    {
      value: 'conta',
      icon: <Icon icon={accounntCircle} width={20} height={20} />,
      component: <View data={{ user, isLoading: user.loading, handleModal, setHandleModal }} />
    }
  ];

  return (
    <Page title="Faturamento">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Minha Conta"
          links={[{ name: 'Home', href: PATH_CLIENT.general.home }, { name: 'Minha Conta' }]}
        />

        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
        >
          {PROFILE_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={capitalCase(tab.value)}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {PROFILE_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
};
export default ClientProfile;

import { useNavigate } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import {
  Typography,
  TableRow,
  TableCell,
  TextField,
  IconButton,
  Stack,
  Autocomplete,
  Box
} from '@mui/material';
import currencyBrl from 'utils/currencyBrl';
import { ProductProps } from '../model';

const Product: React.FC<ProductProps> = ({ planData, setNewPeriod }) => {
  const navigate = useNavigate();
  const { selected, discount, value, period } = planData;
  const periodOptions = [
    {
      id: 'mensal',
      label: 'Mensal'
    },
    {
      id: 'trimestral',
      label: 'Trimestral'
    },
    {
      id: 'anual',
      label: 'Anual'
    }
  ];

  return (
    <TableRow>
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box>
            <Typography fontWeight={600} fontSize="0.900rem" noWrap>
              {selected}
            </Typography>
          </Box>
        </Stack>
      </TableCell>
      <TableCell>
        <Typography fontSize="0.875rem" fontWeight={400} color="rgb(33, 43, 54)">
          {currencyBrl(value)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography fontSize="0.875rem" fontWeight={400} color="rgb(33, 43, 54)">
          {discount ? currencyBrl(discount) : '--'}
        </Typography>
      </TableCell>
      <TableCell>
        <Autocomplete
          sx={{ minWidth: '100px' }}
          size="small"
          options={periodOptions}
          getOptionLabel={(option) => option.label}
          value={period}
          onChange={(event, value) => value !== null && setNewPeriod(value)}
          renderInput={(props) => <TextField {...props} label="Período" />}
        />
      </TableCell>
      <TableCell>
        <Typography fontSize="0.875rem" fontWeight={400} color="rgb(33, 43, 54)">
          {currencyBrl(value - discount)}
        </Typography>
      </TableCell>
      <TableCell>
        <IconButton onClick={() => navigate(-1)}>
          <Icon icon={trash2Outline} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default Product;

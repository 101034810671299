import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import endpoints from 'services/request/endpoints';
import { useSnackbar } from 'notistack5';
import { axiosInstance } from 'services/instance';
import { Company, CustomRulesEditProps, CustomRulesProps } from './Model';
import View from './View';

const NewCustomRules: React.FC = () => {
  const params = useParams();
  const [typePage, setTypePage] = useState<'New' | 'Edit'>('New');
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  // edit
  const [data, setData] = useState<CustomRulesEditProps>({
    acao: '',
    campo: '',
    created_at: '',
    deleted_at: null,
    descricao: '',
    empresas: [
      {
        regra_id: 0,
        empresa_id: 0,
        empresa: {
          cnpj: '',
          fantasia: '',
          id: 0,
          razao: ''
        }
      }
    ],
    id: 0,
    ncm: '',
    tipo_pesquisa: '',
    tributacao: '',
    updated_at: '',
    valor: ''
  });
  const [selected, setSelected] = useState<CustomRulesProps>({
    id: 0,
    ncm: '',
    descricao: '',
    tipo_pesquisa: '',
    tributacao: '',
    empresas: [
      {
        id: 0,
        fantasia: '',
        razao: '',
        cnpj: ''
      }
    ],
    acao: '',
    campo: '',
    valor: ''
  });

  const { enqueueSnackbar } = useSnackbar();

  const callEdit = () => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.customRule}/${params.id}`;
    setLoading(true);
    setTypePage('Edit');

    axiosInstance
      .get(endpoint)
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((error: { response: { data: { message: string } } }) => {
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
        setLoading(false);
      });
  };

  const getCompanies = () => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.companiesList}`;

    axiosInstance
      .get(endpoint)
      .then(({ data }) => {
        setCompanies(data);
      })
      .catch((error) => {
        // console.log('Requisição de empresas falhou', error);
      });
  };

  useEffect(() => {
    const companies = data.empresas.map((empresa) => empresa.empresa);
    setSelected({
      ...data,
      empresas: [...companies]
    });
  }, [data]);

  useEffect(() => {
    if (params && params.id) {
      setTypePage('Edit');
      callEdit();
    }
    getCompanies();
  }, []);

  return (
    <View
      {...{
        setLoading,
        loading,
        typePage,
        selected,
        companies
      }}
    />
  );
};
export default NewCustomRules;

import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'services/request/baseQuery';
import endpoints from 'services/request/endpoints';
import { useSnackbar } from 'notistack5';
import { axiosInstance } from 'services/instance';
import View from './View';

const HomeCompany: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState<{
    clients: {
      total: number;
      growth: number;
    };
  }>();

  async function getDahboardData() {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.integrator.dashboard}`;

    try {
      const { data } = await axiosInstance.get(endpoint);
      setData(data);
    } catch (error) {
      enqueueSnackbar('Não foi possível obter os dados para o Dashboard.', {
        variant: 'error'
      });
    }
  }

  useEffect(() => {
    getDahboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const user = useAppSelector((state) => state.AuthCompany);
  // @ts-ignore
  return <View {...{ user: user.user, data }} />;
};

export default HomeCompany;

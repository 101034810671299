import React, { useEffect, useState } from 'react';

import API from 'services/api';
import View from './View';
import { CompanyConfigurationProps } from './Model';

const ListAliquotCompany: React.FC = () => {
  const { request } = API();
  const [companyConfiguration, setCompanyConfiguration] = useState<CompanyConfigurationProps>(
    {} as CompanyConfigurationProps
  );

  const getCompanyConfiguration = async () => {
    try {
      const { data }: any = await request('company_configuration', 'GET', false);

      setCompanyConfiguration(data);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getCompanyConfiguration();
  }, []);

  return <View data={companyConfiguration} />;
};

export default ListAliquotCompany;

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { axiosInstance } from 'services/instance';
import endpoints from 'services/request/endpoints';
import {
  IntegratorRecoveryPayloadProps,
  LoginCompanyPayloadProps
} from 'services/request/models/payload';
import {
  IntegratorRecoveryResponseProps,
  LoginCompanyResponseProps
} from 'services/request/models/response';

export const fetchLogin = createAsyncThunk<LoginCompanyResponseProps, LoginCompanyPayloadProps>(
  'authentication.company.fetchLogin',
  async (data, { rejectWithValue }) => {
    try {
      const api = axiosInstance;
      const signInResponse = await api({
        baseURL: process.env.REACT_APP_BASE_URL + endpoints.integrator.login,
        method: 'POST',
        data
      });

      return signInResponse.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const Logout = createAction('authentication.company.logout');

export const fetchIntegratorRecovery = createAsyncThunk<
  IntegratorRecoveryResponseProps,
  IntegratorRecoveryPayloadProps
>('authentication.company.fetchIntegratorRecovery', async (data, { rejectWithValue }) => {
  try {
    const api = axiosInstance;
    const response: AxiosResponse = await api({
      baseURL: process.env.REACT_APP_BASE_URL + endpoints.integrator.recovery,
      method: 'POST',
      data
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

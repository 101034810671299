import * as Yup from 'yup';
import { useSnackbar } from 'notistack5';

import { Form, FormikProvider, useFormik } from 'formik';
import {
  Card,
  Typography,
  Grid,
  Stack,
  TextField,
  Autocomplete,
  FormControlLabel,
  Switch,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { LoadingButton } from '@material-ui/lab';
import InputMask from 'react-input-mask';
import { useEffect, useState } from 'react';
import moment from 'moment';
import LoadingScreen from 'components/LoadingScreen';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router';
import { useContextCompany } from 'contexts/CompanyContext';
import { RegisterViewProps } from '../../Model';

const View: React.FC<RegisterViewProps> = ({
  dataCnpj,
  loading,
  dataAutocomplete,
  dataAutocompleteError,
  handleNext,
  setAutoCompleteBillingData
}) => {
  const { set } = dataCnpj;
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const { company, setCompany } = useContextCompany();

  const { enqueueSnackbar } = useSnackbar();

  const NewCompanySchema = Yup.object().shape({
    company: Yup.string().required('Razão Social é obrigatório'),
    companyName: Yup.string().required('Nome Fantasia é obrigatório'),
    cnpj: Yup.string().required('CNPJ é obrigatório'),
    regimeTax: Yup.string().nullable().required('Regime Tributário é obrigatório'),
    name: Yup.string().required('Nome é obrigatório'),
    email: Yup.string().required('E-mail é obrigatório').email('Insira um e-mail válido'),
    emailNfe: Yup.string().email('Insira um E-mail válido').required('O E-mail é obrigatório.'),
    confirmEmail: Yup.string()
      .required('Reinsira o E-mail')
      .email()
      .oneOf([Yup.ref('email')], 'A Confirmação de e-mail deve ser idêntica ao e-mail.'),
    cellNumber: Yup.string().required('Informar um celular para contato é obrigatório.'),
    password: Yup.string()
      .required('A senha é obrigatória')
      .min(8, 'A senha é muito curta, insira no mínimo 8 caracteres.'),
    passwordConfirm: Yup.string()
      .required('Reinsira a senha.')
      .oneOf([Yup.ref('password')], 'A senhas devem ser idênticas.'),
    arrived: Yup.string().nullable().required('Informar como chegou é obrigatório.'),
    ie: Yup.string().when('icms', (val) => {
      if (val === 'Contribuinte') {
        return Yup.string().required('Quando contribuinte, obrigatório informar o I.E');
      }
      return Yup.string().notRequired();
    }),
    icms: Yup.string().nullable().required('Informar o ICMS é obrigatório')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cod: '',
      company: '',
      companyName: '',
      cnpj: '',
      tax: 'simpleNational' || 'presumedProfit' || 'realProfit',
      icmsWithinTheState: false,
      ordinance195Granted: false,
      limitICMSRate: false,
      name: '',
      email: '',
      emailNfe: '',
      confirmEmail: '',
      phoneNumber: '',
      cellNumber: '',
      number: '',
      complemento: '',
      bairro: '',
      password: '',
      passwordConfirm: '',
      zipCode: '',
      regimeTax: '',
      dateIn: '',
      ie: '',
      icms: '',
      address: '',
      city: '',
      state: '',
      mei: false,
      arrived: ''
    },
    validationSchema: NewCompanySchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      setAutoCompleteBillingData({
        address: values.address,
        bairro: values.bairro,
        cep: dataAutocomplete.estabelecimento.cep,
        city: values.city,
        complemento: values.complemento,
        number: values.number,
        state: values.state
      });
      const date = values.dateIn.split('/');

      const payload: any = {
        cnpj: values.cnpj,
        razao: values.company,
        fantasia: values.companyName,
        endereco: values.address,
        numero: values.number,
        complemento: values.complemento,
        cep: values.zipCode,
        bairro: values.bairro,
        cidade: values.city,
        estado: values.state,
        regime_tributario: values.regimeTax,
        contribuinte_icms: values.icms,
        fundado_em: moment(`${date[2]}-${date[1]}-${date[0]}`).format('YYYY-MM-DD'),
        email_cnpj: values.emailNfe && values.emailNfe?.length >= 0 ? values.emailNfe : '',
        mei: values.mei ? values.mei : false,
        nome_contato: values.name,
        telefone_celular: values.cellNumber,
        email_contato: values.email,
        senha: values.password,
        chegou_via: values.arrived,
        aceite_termos: true,
        telefone_fixo: values.phoneNumber
      };

      if (values?.ie?.length > 0) {
        Object.assign(payload, {
          ie: values.ie.replace('.', '').replace('.', '').replace('.', '')
        });
      }

      setCompany({ ...payload });

      handleNext();
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const dropTributacao = ['Simples Nacional', 'Lucro Presumido', 'Lucro Real'];
  const dropContribuente = ['Contribuinte', 'Contribuinte Isento', 'Não contribuinte'];
  const questRed = [
    'Anúncios Patrocinados',
    'Facebook',
    'Google',
    'Instagram',
    'Outdoor',
    'Outros'
  ];

  const currentCnpj: string = formik.getFieldProps('cnpj').value;

  useEffect(() => {
    set(currentCnpj);
  }, [currentCnpj]);

  useEffect(() => {
    if (dataAutocompleteError.detalhes) {
      enqueueSnackbar(dataAutocompleteError.detalhes, {
        variant: 'error'
      });
    }
  }, [dataAutocompleteError]);

  const [vmei, setvmei] = useState(false);
  const [inscEst, setInscEst] = useState('');

  useEffect(() => {
    formik.setFieldValue('ie', inscEst);
  }, [inscEst]);

  useEffect(() => {
    if (company.ie) {
      setInscEst(`${company.ie}`);
    }
  }, [company]);

  useEffect(() => {
    // clear temp

    // end clear temp
    formik.setFieldValue('company', dataAutocomplete?.razao_social);
    formik.setFieldValue(
      'companyName',
      dataAutocomplete?.estabelecimento?.nome_fantasia
        ? dataAutocomplete?.estabelecimento?.nome_fantasia
        : dataAutocomplete?.razao_social
    );
    formik.setFieldValue('emailNfe', dataAutocomplete?.estabelecimento?.email || '');
    formik.setFieldValue('address', dataAutocomplete?.estabelecimento?.logradouro);
    formik.setFieldValue('country', dataAutocomplete?.estabelecimento?.bairro);
    formik.setFieldValue('zipCode', dataAutocomplete?.estabelecimento?.cep);
    formik.setFieldValue('state', dataAutocomplete?.estabelecimento?.estado?.sigla);
    formik.setFieldValue('city', dataAutocomplete?.estabelecimento?.cidade?.nome);
    formik.setFieldValue('number', dataAutocomplete?.estabelecimento?.numero);
    formik.setFieldValue('complemento', dataAutocomplete?.estabelecimento?.complemento);
    formik.setFieldValue('bairro', dataAutocomplete?.estabelecimento?.bairro);
    formik.setFieldValue('ie', dataAutocomplete?.ie);

    if (dataAutocomplete?.simples?.mei === 'Sim') {
      setvmei(true);
      formik.setFieldValue('mei', true);
    }

    if (dataAutocomplete?.estabelecimento?.data_inicio_atividade) {
      formik.setFieldValue(
        'dateIn',
        moment(dataAutocomplete?.estabelecimento?.data_inicio_atividade).format('DD/MM/YYYY')
      );
    }

    if (dataAutocomplete?.simples?.simples === 'Sim') {
      formik.setFieldValue('regimeTax', 'Simples Nacional');
    }
  }, [dataAutocomplete]);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowPassword2 = () => {
    setShowPassword2((show) => !show);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (company.cnpj) {
      formik.setFieldValue('regimeTax', company.regime_tributario);
      formik.setFieldValue('icms', company.contribuinte_icms);

      formik.setFieldValue('name', company.nome_contato);
      formik.setFieldValue('email', company.email_contato);
      formik.setFieldValue('confirmEmail', company.email_contato);
      formik.setFieldValue('cellNumber', company.telefone_celular);
      formik.setFieldValue('phoneNumber', company.telefone_fixo);
      formik.setFieldValue('password', company.senha);
      formik.setFieldValue('passwordConfirm', company.senha);
      formik.setFieldValue('arrived', company.chegou_via);
      if (company.cnpj) {
        formik.setFieldValue('cnpj', company.cnpj);
      }
    }
  }, [company]);

  return (
    <>
      {loading ? (
        <div style={{ padding: '100px 0' }}>
          <LoadingScreen />
        </div>
      ) : (
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <InputMask
                        mask="99.999.999/9999-99"
                        disabled={false}
                        maskChar=""
                        {...getFieldProps('cnpj')}
                        error={Boolean(touched.cnpj && errors.cnpj)}
                        helperText={touched.cnpj && errors.cnpj}
                      >
                        {(inputProps: any) => <TextField {...inputProps} fullWidth label="CNPJ" />}
                      </InputMask>

                      <TextField
                        fullWidth
                        label="Razão Social"
                        {...getFieldProps('company')}
                        error={Boolean(touched.company && errors.company)}
                        helperText={touched.company && errors.company}
                        disabled
                      />
                    </Stack>
                    <TextField
                      fullWidth
                      label="Nome Fantasia"
                      disabled
                      {...getFieldProps('companyName')}
                      error={Boolean(touched.companyName && errors.companyName)}
                      helperText={touched.companyName && errors.companyName}
                    />
                    <Typography variant="h5">Endereço</Typography>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                      <TextField
                        sx={{ width: 550 }}
                        fullWidth
                        label="CEP"
                        {...getFieldProps('zipCode')}
                        disabled
                      />
                      <TextField
                        sx={{ width: 550 }}
                        fullWidth
                        label="Endereço (Rua)"
                        {...getFieldProps('address')}
                        disabled
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                      <TextField
                        sx={{ width: 550 }}
                        fullWidth
                        label="Número"
                        {...getFieldProps('number')}
                        disabled
                      />
                      <TextField
                        sx={{ width: 550 }}
                        fullWidth
                        label="Complemento"
                        {...getFieldProps('complemento')}
                        disabled
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                      <TextField
                        sx={{ width: 820 }}
                        fullWidth
                        label="Bairro"
                        {...getFieldProps('bairro')}
                        disabled
                      />
                      <TextField
                        sx={{ width: 600 }}
                        fullWidth
                        label="Cidade"
                        {...getFieldProps('city')}
                        disabled
                      />
                      <TextField
                        sx={{ width: 200 }}
                        label="Estado"
                        {...getFieldProps('state')}
                        disabled
                      />
                    </Stack>
                    <Typography variant="h5">Fiscal</Typography>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dropTributacao}
                        sx={{ width: 600 }}
                        {...getFieldProps('regimeTax')}
                        onChange={(event, value) => formik.setFieldValue('regimeTax', value)}
                        renderInput={(regimeTaxProp: any) => (
                          <TextField
                            {...regimeTaxProp}
                            label="Regime Tributário"
                            error={Boolean(touched.regimeTax && errors.regimeTax)}
                            helperText={touched.regimeTax && errors.regimeTax}
                          />
                        )}
                      />
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dropContribuente}
                        sx={{ width: 600 }}
                        {...getFieldProps('icms')}
                        onChange={(event, value) => formik.setFieldValue('icms', value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Contribuinte ICMS"
                            error={Boolean(touched.icms && errors.icms)}
                            helperText={touched.icms && errors.icms}
                          />
                        )}
                      />
                      {formik.values.icms !== 'Não contribuinte' ? (
                        <InputMask
                          formatChars={{ '9': '[0-9.]' }}
                          mask="99999999999999999999"
                          disabled={false}
                          maskChar=""
                          {...getFieldProps('ie')}
                          error={Boolean(errors.ie)}
                          helperText={errors.ie}
                        >
                          {(inputProps: any) => (
                            <TextField fullWidth label="Inscrição Estadual" {...inputProps} />
                          )}
                        </InputMask>
                      ) : null}
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                      <TextField
                        sx={{ width: 360 }}
                        fullWidth
                        label="Data de Fundação"
                        {...getFieldProps('dateIn')}
                        disabled
                      />
                      <TextField
                        sx={{ width: 355 }}
                        fullWidth
                        label="E-mail para envio de NFe"
                        {...getFieldProps('emailNfe')}
                        error={Boolean(touched.emailNfe && errors.emailNfe)}
                        helperText={touched.emailNfe && errors.emailNfe}
                      />
                      <FormControlLabel
                        {...getFieldProps('mei')}
                        checked={vmei}
                        onChange={() => setvmei(!vmei)}
                        control={<Switch />}
                        label="Microempreendedor individual (MEI)"
                      />
                    </Stack>
                    <Typography variant="h5">Contato</Typography>
                    <TextField
                      fullWidth
                      label="Nome completo"
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="E-mail"
                        placeholder="Exemplo@Trib"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                      <TextField
                        fullWidth
                        label="Confirmação de E-mail"
                        placeholder="Exemplo@Trib"
                        {...getFieldProps('confirmEmail')}
                        error={Boolean(touched.confirmEmail && errors.confirmEmail)}
                        helperText={touched.confirmEmail && errors.confirmEmail}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                      <InputMask
                        mask="(99)9999-9999"
                        disabled={false}
                        maskChar=" "
                        {...getFieldProps('phoneNumber')}
                        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                        helperText={touched.phoneNumber && errors.phoneNumber}
                      >
                        {(inputprops: any) => (
                          <TextField fullWidth label="Telefone Fixo" {...inputprops} />
                        )}
                      </InputMask>
                      <InputMask
                        mask="(99) 9 9999-9999"
                        disabled={false}
                        maskChar=" "
                        {...getFieldProps('cellNumber')}
                        error={Boolean(touched.cellNumber && errors.cellNumber)}
                        helperText={touched.cellNumber && errors.cellNumber}
                      >
                        {(inputProps: any) => (
                          <TextField fullWidth label="Celular" {...inputProps} />
                        )}
                      </InputMask>
                    </Stack>
                    <Typography variant="h5">Acesso</Typography>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                      <TextField
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        label="Senha"
                        {...getFieldProps('password')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPassword} edge="end">
                                <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />
                      <TextField
                        fullWidth
                        type={showPassword2 ? 'text' : 'password'}
                        label="Confirme a Senha"
                        {...getFieldProps('passwordConfirm')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPassword2} edge="end">
                                <Icon icon={showPassword2 ? eyeFill : eyeOffFill} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
                        helperText={touched.passwordConfirm && errors.passwordConfirm}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={questRed}
                        sx={{ width: 550 }}
                        {...getFieldProps('arrived')}
                        onChange={(event, value) => formik.setFieldValue('arrived', value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Onde nos encontrou"
                            error={Boolean(touched.arrived && errors.arrived)}
                            helperText={touched.arrived && errors.arrived}
                          />
                        )}
                      />
                    </Stack>
                    <Stack
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1, sm: 2, md: 2 }}
                    >
                      <div
                        style={{
                          paddingTop: '30px',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <LoadingButton
                          variant="contained"
                          type="button"
                          loading={isSubmitting}
                          onClick={() => navigate(-1)}
                        >
                          Cancelar
                        </LoadingButton>

                        <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                          Próximo
                        </LoadingButton>
                      </div>
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      )}
    </>
  );
};

export default View;

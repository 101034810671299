import * as Yup from 'yup';
import { useSnackbar } from 'notistack5';

import { Form, FormikProvider, useFormik } from 'formik';
import {
  Box,
  Card,
  Container,
  Typography,
  Grid,
  Stack,
  TextField,
  Autocomplete,
  Switch,
  Button,
  IconButton
} from '@material-ui/core';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

import { LoadingButton } from '@material-ui/lab';
import InputMask from 'react-input-mask';
import { useEffect, useState } from 'react';
import moment from 'moment';
import LoadingScreen from 'components/LoadingScreen';
import { useAppDispatch } from 'services/request/baseQuery';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_COMPANY } from 'routes/paths';
import { useNavigate, useParams } from 'react-router';
import {
  fetchCreateClient,
  fetchUpdateClient
} from 'services/store/actions/integrator/clients.action';
import { CompanyCreateClientPayloadProps } from 'services/request/models/payload';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { FormHelperText, InputAdornment } from '@mui/material';
import { Icon } from '@iconify/react';
import { RegisterProps } from '../Model';

const View: React.FC<RegisterProps> = ({ dataCnpj, loading, dataAutocomplete, typePage }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { set } = dataCnpj;
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  });

  const NewCompanySchema = Yup.object().shape({
    company: Yup.string().required('Razão Social é obrigatório'),
    companyName: Yup.string().required('Nome Fantasia é obrigatório'),
    cnpj: Yup.string().required('CNPJ é obrigatório'),
    regimeTax: Yup.string().nullable().required('Regime Tributário é obrigatório'),
    email: Yup.string().required('E-mail é obrigatório').email('Formato de e-mail inválido'),
    confirmEmail: Yup.string()
      .required('Reinsira o E-mail')
      .email('Formato de e-mail inválido')
      .oneOf([Yup.ref('email')], 'O Email deve ser idêntico.'),
    phoneNumber: Yup.string(),
    cellNumber: Yup.string().required('Informar um celular para contato é obrigatório.'),
    emailNfe: Yup.string().required('E-mail Nfe é Obrigatória').email('Formato de e-mail inválido'),
    password: Yup.string()
      .required('A senha é obrigatória')
      .min(8, 'A senha é muito curta, insira no mínimo 8 caracteres.'),
    passwordConfirm: Yup.string()
      .required('Reinsira a senha.')
      .oneOf([Yup.ref('password')], 'A senhas devem ser idênticas.'),
    icms: Yup.mixed().required('Informar o ICMS é obrigatório'),
    ie: Yup.string()
      .min(9, 'A Inscrição Estadual deve ter 9 dígitos.')
      .when('icms', {
        is: 'Contribuinte',
        then: Yup.string()
          .required('A inscrição estadual é obrigatória quando for contribuinte.')
          .length(9, 'A Inscrição Estadual deve ter 9 dígitos.')
      }),
    name: Yup.string().required('O Nome do contato é Obrigatório'),
    mvaType: Yup.string().required('O tipo de MVA é obrigatório.')
  });

  const UpdateCompanySchema = Yup.object().shape({
    cnpj: Yup.string().required('CNPJ é obrigatório'),
    regimeTax: Yup.string().nullable().required('Regime Tributário é obrigatório'),
    icms: Yup.mixed().required('Informar o ICMS é obrigatório'),
    ie: Yup.string()
      .min(9, 'A Inscrição Estadual deve ter 9 dígitos.')
      .when('icms', {
        is: 'Contribuinte',
        then: Yup.string()
          .required('A inscrição estadual é obrigatória quando for contribuinte.')
          .length(9, 'A Inscrição Estadual deve ter 9 dígitos.')
      }),
    name: Yup.string().required('Nome é obrigatório'),
    email: Yup.string().required('E-mail é obrigatório').email('Formato de e-mail inválido'),
    confirmEmail: Yup.string()
      .required('Reinsira o E-mail')
      .email('Formato de e-mail inválido')
      .oneOf([Yup.ref('email')], 'O Email deve ser idêntico.'),
    phoneNumber: Yup.string(),
    cellNumber: Yup.string().required('Informar um celular para contato é obrigatório.'),
    emailNfe: Yup.string().required('E-mail Nfe é Obrigatória')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cod: '',
      company: '',
      companyName: '',
      cnpj: '',
      tax: 'Simples Nacional' || 'Lucro Presumido' || 'Lucro Real',
      icmsWithinTheState: false,
      ordinance195Granted: false,
      limitICMSRate: false,
      name: '',
      email: '',
      emailNfe: '',
      confirmEmail: '',
      phoneNumber: '',
      cellNumber: '',
      number: '',
      complemento: '',
      bairro: '',
      password: '',
      passwordConfirm: '',
      zipCode: '',
      regimeTax: '',
      dateIn: '',
      ie: '',
      icms: '',
      address: '',
      city: '',
      state: '',
      mei: false,
      mvaType: '',
      status: false
    },
    validationSchema: typePage === 'New' ? NewCompanySchema : UpdateCompanySchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      if (typePage === 'New') {
        const date = values.dateIn.split('/');

        const payload = {
          cnpj: values.cnpj,
          razao: values.company,
          fantasia: values.companyName,
          endereco: values.address,
          numero: values.number,
          complemento: values.complemento,
          bairro: values.bairro,
          cidade: values.city,
          estado: values.state,
          regime_tributario: values.regimeTax,
          contribuinte_icms: values.icms,
          fundado_em: moment(`${date[2]}-${date[1]}-${date[0]}`).format('YYYY-MM-DD'),
          email_cnpj: values.emailNfe,
          mei: values.mei,
          nome_contato: values.name,
          telefone_celular: values.cellNumber,
          email_contato: values.email,
          senha: values.password,
          telefone_fixo: values.phoneNumber,
          cep: values.zipCode,
          grupo_permissao: 1,
          tipo_mva: values.mvaType
        } as CompanyCreateClientPayloadProps;

        if (values.ie.length > 0) {
          Object.assign(payload, { ie: values.ie });
        }

        try {
          const response = await dispatch(fetchCreateClient(payload));
          const { requestStatus } = response.meta;

          if (requestStatus === 'fulfilled') {
            enqueueSnackbar('Cadastro efetuado com sucesso!', {
              variant: 'success'
            });
            navigate(-1);
          } else {
            enqueueSnackbar('Ocorreu um problema.', {
              variant: 'error'
            });
          }
        } catch (error: any) {
          setSubmitting(false);
          setErrors(error);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        }
      } else {
        const payload = {
          cnpj: values.cnpj,
          regime_tributario: values.regimeTax,
          contribuinte_icms: values.icms,
          email_cnpj: values.emailNfe,
          mei: `${values.mei}`,
          nome_contato: values.name,
          telefone_celular: values.cellNumber,
          email_contato: values.email,
          ie: values.ie,
          telefone_fixo: values.phoneNumber,
          tipo_mva: values.mvaType,
          ativo: values.status,
          id: `${params.id}`
        };

        try {
          const response = await dispatch(fetchUpdateClient(payload));
          const { requestStatus } = response.meta;

          if (requestStatus === 'fulfilled') {
            enqueueSnackbar('Cadastro atualizado com sucesso!', {
              variant: 'success'
            });
          } else {
            enqueueSnackbar('Ocorreu um problema.', {
              variant: 'error'
            });
          }
          navigate(-1);
        } catch (error: any) {
          setSubmitting(false);
          setErrors(error);
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        }
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const dropTributacao = ['Simples Nacional', 'Lucro Presumido', 'Lucro Real'];
  const dropContribuente = ['Contribuinte', 'Contribuinte Isento', 'Não contribuinte'];

  const currentCnpj: string = formik.getFieldProps('cnpj').value;

  useEffect(() => {
    set(currentCnpj);
  }, [currentCnpj]);

  const [inscEst, setInscEst] = useState('');

  useEffect(() => {
    formik.setFieldValue('ie', inscEst);
  }, [inscEst]);

  useEffect(() => {
    if (dataAutocomplete && dataAutocomplete.estabelecimento) {
      // clear temp
      formik.setFieldValue('regimeTax', '');
      // end clear temp

      formik.setFieldValue('company', dataAutocomplete?.razao_social || '');
      formik.setFieldValue(
        'companyName',
        dataAutocomplete?.estabelecimento?.nome_fantasia || dataAutocomplete?.razao_social || ''
      );
      formik.setFieldValue('emailNfe', dataAutocomplete?.estabelecimento?.email || '');
      formik.setFieldValue('address', dataAutocomplete?.estabelecimento?.logradouro || '');
      formik.setFieldValue('country', dataAutocomplete?.estabelecimento?.bairro || '');
      formik.setFieldValue('zipCode', dataAutocomplete?.estabelecimento?.cep || '');
      formik.setFieldValue('state', dataAutocomplete?.estabelecimento?.estado?.sigla || '');
      formik.setFieldValue('city', dataAutocomplete?.estabelecimento?.cidade?.nome || '');
      formik.setFieldValue('number', dataAutocomplete?.estabelecimento?.numero || '');
      formik.setFieldValue('complemento', dataAutocomplete?.estabelecimento?.complemento || '');
      formik.setFieldValue('bairro', dataAutocomplete?.estabelecimento?.bairro || '');
      formik.setFieldValue('tipo_mva', dataAutocomplete?.tipo_mva || '');

      if (dataAutocomplete?.simples?.mei === 'Sim') {
        formik.setFieldValue('mei', true);
      }

      if (dataAutocomplete?.estabelecimento?.data_inicio_atividade) {
        formik.setFieldValue(
          'dateIn',
          moment(dataAutocomplete?.estabelecimento?.data_inicio_atividade).format('DD/MM/YYYY')
        );
      }

      if (dataAutocomplete?.simples?.simples === 'Sim') {
        formik.setFieldValue('regimeTax', 'Simples Nacional');
      }
    } else {
      formik.setFieldValue('regimeTax', dataAutocomplete?.regime_tributario || '');
      formik.setFieldValue('company', dataAutocomplete?.razao || '');
      formik.setFieldValue(
        'companyName',
        dataAutocomplete?.fantasia || dataAutocomplete?.razao || ''
      );
      formik.setFieldValue('cnpj', dataAutocomplete?.cnpj || '');
      formik.setFieldValue('emailNfe', dataAutocomplete?.email_cnpj || '');
      formik.setFieldValue(
        'confirmEmail',
        dataAutocomplete ? dataAutocomplete.email_contato.toLowerCase() : ''
      );
      formik.setFieldValue('address', dataAutocomplete?.endereco || '');
      formik.setFieldValue('country', dataAutocomplete?.bairro || '');
      formik.setFieldValue('zipCode', dataAutocomplete?.cep || '');
      formik.setFieldValue('state', dataAutocomplete?.estado || '');
      formik.setFieldValue('city', dataAutocomplete?.cidade || '');
      formik.setFieldValue('number', dataAutocomplete?.numero || '');
      formik.setFieldValue('complemento', dataAutocomplete?.complemento || '');
      formik.setFieldValue('bairro', dataAutocomplete?.bairro || '');
      formik.setFieldValue('mei', dataAutocomplete?.mei || false);
      formik.setFieldValue('name', dataAutocomplete?.nome_contato || '');
      formik.setFieldValue('cellNumber', dataAutocomplete?.telefone_celular || '');
      formik.setFieldValue('email', dataAutocomplete?.email_contato || '');
      formik.setFieldValue('arrived', dataAutocomplete?.chegou_via || '');
      formik.setFieldValue('ie', dataAutocomplete?.ie || '');
      if (dataAutocomplete?.ie) {
        setInscEst(dataAutocomplete?.ie);
      }
      formik.setFieldValue('phoneNumber', dataAutocomplete?.telefone_fixo || '');
      formik.setFieldValue(
        'dateIn',
        dataAutocomplete?.fundado_em
          ? moment(dataAutocomplete?.fundado_em).format('DD/MM/YYYY')
          : ''
      );
      formik.setFieldValue('regimeTax', dataAutocomplete?.regime_tributario || '');
      formik.setFieldValue('icms', dataAutocomplete?.contribuinte_icms || '');
      formik.setFieldValue('status', `${dataAutocomplete?.situacao}` || '');
      formik.setFieldValue('mvaType', dataAutocomplete?.tipo_mva || '');
      formik.setFieldValue('status', dataAutocomplete?.ativo || false);
    }
  }, [dataAutocomplete]);

  if (loading) {
    return (
      <Page title={typePage === 'New' ? 'Gestão: Novo Cliente' : 'Gestão: Cliente'}>
        <Container>
          <HeaderBreadcrumbs
            heading="Novo Cliente"
            links={[
              { name: 'Clientes', href: PATH_COMPANY.general.management.clients },
              {
                name:
                  typePage === 'New' ? 'Novo Cliente' : formik.getFieldProps('companyName').value
              }
            ]}
          />
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <LoadingScreen />
                    </Stack>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Container>
      </Page>
    );
  }

  return (
    <Page title={typePage === 'New' ? 'Gestão: Novo Cliente' : 'Gestão: Cliente'}>
      <Container>
        <HeaderBreadcrumbs
          heading="Novo Cliente"
          links={[
            { name: 'Clientes', href: PATH_COMPANY.general.management.clients },
            {
              name: typePage === 'New' ? 'Novo Cliente' : formik.getFieldProps('companyName').value
            }
          ]}
        />
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <InputMask
                        mask="99.999.999/9999-99"
                        disabled={typePage === 'Edit'}
                        maskChar=""
                        {...getFieldProps('cnpj')}
                        error={Boolean(touched.cnpj && errors.cnpj)}
                        helperText={touched.cnpj && errors.cnpj}
                      >
                        {(inputProps: any) => (
                          <TextField
                            {...inputProps}
                            fullWidth
                            label="CNPJ"
                            disabled={typePage === 'Edit'}
                          />
                        )}
                      </InputMask>

                      <TextField
                        fullWidth
                        label="Razão Social"
                        {...getFieldProps('company')}
                        disabled
                      />
                    </Stack>
                    <TextField
                      fullWidth
                      label="Nome Fantasia"
                      {...getFieldProps('companyName')}
                      disabled
                    />
                    <Typography variant="h5">Endereço</Typography>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                      <TextField
                        sx={{ width: 550 }}
                        fullWidth
                        label="CEP"
                        {...getFieldProps('zipCode')}
                        disabled
                      />
                      <TextField
                        sx={{ width: 550 }}
                        fullWidth
                        label="Endereço (Rua)"
                        {...getFieldProps('address')}
                        disabled
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                      <TextField
                        sx={{ width: 550 }}
                        fullWidth
                        label="Número"
                        {...getFieldProps('number')}
                        disabled
                      />
                      <TextField
                        sx={{ width: 550 }}
                        fullWidth
                        label="Complemento"
                        {...getFieldProps('complemento')}
                        disabled
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                      <TextField
                        sx={{ width: 820 }}
                        fullWidth
                        label="Bairro"
                        {...getFieldProps('bairro')}
                        disabled
                      />
                      <TextField
                        sx={{ width: 600 }}
                        fullWidth
                        label="Cidade"
                        {...getFieldProps('city')}
                        disabled
                      />
                      <TextField
                        sx={{ width: 200 }}
                        label="Estado"
                        {...getFieldProps('state')}
                        disabled
                      />
                    </Stack>
                    <Typography variant="h5">Fiscal</Typography>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dropTributacao}
                        sx={{ width: 600 }}
                        {...getFieldProps('regimeTax')}
                        onChange={(event, value) => formik.setFieldValue('regimeTax', value)}
                        renderInput={(regimeTaxProp: any) => (
                          <TextField
                            helperText={touched.regimeTax && errors.regimeTax}
                            error={Boolean(touched.regimeTax && errors.regimeTax)}
                            {...regimeTaxProp}
                            label="Regime Tributário"
                          />
                        )}
                      />
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={dropContribuente}
                        sx={{ width: 600 }}
                        {...getFieldProps('icms')}
                        onChange={(event, value) => formik.setFieldValue('icms', value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Contribuinte ICMS"
                            error={Boolean(touched.icms && errors.icms)}
                            helperText={touched.icms && errors.icms}
                          />
                        )}
                      />

                      <InputMask
                        mask="999999999"
                        maskChar=""
                        {...getFieldProps('ie')}
                        value={inscEst}
                        onChange={(event: any) => {
                          setInscEst(event.target.value);
                          formik.setFieldValue('ie', event.target.value);
                        }}
                        error={Boolean(touched.ie && errors.ie)}
                        helperText={touched.ie && errors.ie}
                      >
                        {(inputProps: any) => (
                          <TextField
                            {...inputProps}
                            sx={{ width: 600 }}
                            label="Inscrição Estadual"
                          />
                        )}
                      </InputMask>
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                      <TextField
                        sx={{ width: 360 }}
                        fullWidth
                        label="Data de Fundação"
                        {...getFieldProps('dateIn')}
                        disabled
                      />
                      <TextField
                        fullWidth
                        label="E-mail para envio de NFe"
                        {...getFieldProps('emailNfe')}
                        error={Boolean(touched.emailNfe && errors.emailNfe)}
                        helperText={touched.emailNfe && errors.emailNfe}
                      />
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                      <FormControlLabel
                        {...getFieldProps('mei')}
                        checked={getFieldProps('mei').value}
                        onChange={(event, value) => formik.setFieldValue('mei', value)}
                        control={<Switch />}
                        label="Microempreendedor individual (MEI)"
                      />
                    </Stack>

                    <Typography variant="h5">Substituto Tributário</Typography>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                      <FormControl
                        sx={{ width: '600px' }}
                        error={Boolean(touched.mvaType && errors.mvaType)}
                      >
                        <FormLabel sx={{ flexWrap: 'nowrap' }}>Tipo de MVA</FormLabel>
                        <RadioGroup {...getFieldProps('mvaType')} row sx={{ flexWrap: 'nowrap' }}>
                          <FormControlLabel
                            value="mva_outorgado"
                            control={<Radio />}
                            label="Portaria 195"
                          />
                          <FormControlLabel value="mva_2b" control={<Radio />} label="Anexo 2B" />
                        </RadioGroup>
                        <FormHelperText sx={{ mt: 0 }}>
                          {errors.mvaType && touched.mvaType ? errors.mvaType : ''}
                        </FormHelperText>
                      </FormControl>
                    </Stack>

                    <Typography variant="h5">Contato</Typography>
                    <TextField
                      fullWidth
                      label="Nome completo"
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                      <TextField
                        fullWidth
                        label="E-mail"
                        placeholder="Exemplo@Trib"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                      <TextField
                        fullWidth
                        label="Confirmação de E-mail"
                        placeholder="Exemplo@Trib"
                        {...getFieldProps('confirmEmail')}
                        error={Boolean(touched.confirmEmail && errors.confirmEmail)}
                        helperText={touched.confirmEmail && errors.confirmEmail}
                      />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                      <InputMask
                        mask="(99)9999-9999"
                        maskChar=""
                        {...getFieldProps('phoneNumber')}
                        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                        helperText={touched.phoneNumber && errors.phoneNumber}
                      >
                        {(inputprops: any) => (
                          <TextField fullWidth label="Telefone Fixo" {...inputprops} />
                        )}
                      </InputMask>
                      <InputMask
                        mask="(99) 9 9999-9999"
                        maskChar=""
                        {...getFieldProps('cellNumber')}
                        error={Boolean(touched.cellNumber && errors.cellNumber)}
                        helperText={touched.cellNumber && errors.cellNumber}
                      >
                        {(inputProps: any) => (
                          <TextField fullWidth label="Celular" {...inputProps} />
                        )}
                      </InputMask>
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                      {typePage === 'New' ? (
                        <>
                          <TextField
                            type={showPassword.password ? 'text' : 'password'}
                            fullWidth
                            label="Senha"
                            {...getFieldProps('password')}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setShowPassword({
                                        ...showPassword,
                                        password: !showPassword.password
                                      })
                                    }
                                    edge="end"
                                  >
                                    <Icon icon={showPassword.password ? eyeFill : eyeOffFill} />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                          />
                          <TextField
                            fullWidth
                            type={showPassword.confirmPassword ? 'text' : 'password'}
                            label="Confirme a Senha"
                            {...getFieldProps('passwordConfirm')}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setShowPassword({
                                        ...showPassword,
                                        confirmPassword: !showPassword.confirmPassword
                                      })
                                    }
                                    edge="end"
                                  >
                                    <Icon
                                      icon={showPassword.confirmPassword ? eyeFill : eyeOffFill}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                            error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
                            helperText={touched.passwordConfirm && errors.passwordConfirm}
                          />
                        </>
                      ) : null}
                    </Stack>
                    {typePage === 'Edit' ? (
                      <>
                        <Typography variant="h5">Situação</Typography>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                          <FormControlLabel
                            {...getFieldProps('status')}
                            checked={getFieldProps('status').value}
                            onChange={(event, value) => {
                              formik.setFieldValue('status', value);
                            }}
                            control={<Switch />}
                            label="Ativo"
                          />
                        </Stack>
                      </>
                    ) : null}
                    <Stack
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      direction={{ xs: 'column', sm: 'row' }}
                      spacing={{ xs: 1, sm: 2, md: 2 }}
                    >
                      <Box sx={{ mt: 3, display: 'flex' }}>
                        <Button
                          variant="outlined"
                          type="button"
                          color="primary"
                          onClick={() => navigate(-1)}
                        >
                          Cancelar
                        </Button>
                      </Box>
                      <Box sx={{ mt: 3, display: 'flex' }}>
                        <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                          {typePage === 'New' ? 'Cadastrar' : 'Atualizar'}
                        </LoadingButton>
                      </Box>
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
};

export default View;

import { useEffect } from 'react';
import {
  Autocomplete,
  Typography,
  Box,
  Radio,
  RadioGroup,
  Paper,
  FormControlLabel,
  Stack,
  TextField,
  Grid
} from '@mui/material';
import { Icon } from '@iconify/react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import { PaymentMethodsProps } from '../model';

const options = [
  { id: 1, label: 'Cartão de Crédito', icon: 'material-symbols:credit-card-outline' },
  {
    id: 2,
    label: 'Pix',
    icon: 'fa6-brands:pix'
  },
  {
    id: 3,
    label: 'Boleto',
    icon: 'mdi:barcode-scan'
  }
];

const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  setCardValues,
  cardValues,
  setMethod,
  method,
  isSubmitted,
  setIsSubmitted,
  paymentOptions,
  installmentsOptions,
  isNewCard,
  setIsNewCard,
  companyConfiguration
}) => {
  const ValidationSchema = Yup.object().shape({
    cardName: Yup.string().required('O Nome do Titular do Cartão é Obrigatório.'),
    cardNumber: Yup.string().required('O Número do Cartão é Obrigatório.'),
    expirationDate: Yup.string().required('A Data de Validade é Obrigatória.'),
    cvv: Yup.string().required('O Código de Verificação é Obrigatório.'),
    installments: Yup.object().required('O número de Parcelas é Obrigatório.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...cardValues
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      setCardValues(values);
    }
  });

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    if (method.metodo_id === '2') {
      if (isNewCard === 'newCard') {
        handleSubmit();
      }
      setIsSubmitted(false);
    }
  }, [isSubmitted]);

  const renderFormCreditCard = () => {
    if (isNewCard === 'newCard' || !companyConfiguration.cobranca.cartao_nome) {
      return (
        <Box>
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Nome no Cartão"
                fullWidth
                disabled={false}
                {...getFieldProps('cardName')}
                error={Boolean(errors.cardName && touched.cardName)}
                helperText={touched.cardName && errors.cardName}
                sx={{ fontSize: '0.875rem' }}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <InputMask
                mask="9999 9999 9999 9999"
                disabled={false}
                maskChar=""
                {...getFieldProps('cardNumber')}
                error={Boolean(errors.cardNumber && touched.cardNumber)}
                helperText={touched.cardNumber && errors.cardNumber}
              >
                {(props: any) => (
                  <TextField
                    label="Número do Cartão"
                    fullWidth
                    {...props}
                    sx={{ fontSize: '0.875rem' }}
                    size="small"
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item container direction="row" spacing={3} xs={12}>
              <Grid item xs={4}>
                <InputMask
                  mask="999"
                  disabled={false}
                  maskChar=""
                  {...getFieldProps('cvv')}
                  error={Boolean(errors.cvv && touched.cvv)}
                  helperText={touched.cvv && errors.cvv}
                >
                  {(props: any) => (
                    <TextField
                      label="CVV"
                      fullWidth
                      {...props}
                      sx={{ fontSize: '0.875rem' }}
                      size="small"
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={4}>
                <InputMask
                  formatChars={{ '1': '[0-1]', '9': '[0-9]' }}
                  mask="19/9999"
                  disabled={false}
                  maskChar=""
                  {...getFieldProps('expirationDate')}
                  error={Boolean(errors.expirationDate && touched.expirationDate)}
                  helperText={touched.expirationDate && errors.expirationDate}
                >
                  {(props: any) => (
                    <TextField
                      label="Data de Validade"
                      fullWidth
                      {...props}
                      placeholder="mm/aaaa"
                      sx={{ fontSize: '0.875rem' }}
                      size="small"
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  {...getFieldProps('installments')}
                  size="small"
                  options={installmentsOptions}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => setFieldValue('installments', value)}
                  renderInput={(props: any) => <TextField {...props} label="Parcelas" />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      );
    }

    return <></>;
  };

  return (
    <Box sx={{ padding: 3 }}>
      <RadioGroup
        sx={{ display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'start' }}
        value={method.metodo_id}
        onChange={(event, value) => {
          const selectedMethod = paymentOptions.filter((option) => option.metodo_id === value)[0];
          const { metodo_id, metodo_nome } = selectedMethod;

          const metodo_vezes = metodo_id === '2' ? getFieldProps('installments').value.id : 1;

          setMethod({
            metodo_id,
            metodo_nome,
            metodo_vezes
          });
        }}
      >
        {paymentOptions.map(({ metodo_id, metodo_nome }) => (
          <Paper
            key={metodo_id}
            variant="outlined"
            sx={{
              width: '100%',
              padding: 3,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 3
            }}
          >
            <Stack direction="row" sx={{ width: '100%' }}>
              <FormControlLabel
                sx={{ width: '100%' }}
                value={metodo_id}
                control={<Radio />}
                label={
                  <Box id="labelContainer" sx={{ marginLeft: 2, width: '100%' }}>
                    <Typography fontSize="0.875rem" fontWeight={600}>
                      {metodo_nome}
                    </Typography>
                  </Box>
                }
              />
              {metodo_nome === 'Cartão de Crédito' && (
                <Icon icon={options[0].icon} width={50} height={50} />
              )}
              {metodo_nome === 'Pix Recebimento' && (
                <Icon icon={options[1].icon} width={50} height={50} />
              )}
              {metodo_nome === 'Boleto' && <Icon icon={options[2].icon} width={50} height={50} />}
            </Stack>

            {method.metodo_id === '2' && metodo_id === '2' && (
              <>
                <RadioGroup
                  onChange={(event, value) => {
                    setIsNewCard(value);
                  }}
                  value={isNewCard}
                  sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 3 }}
                  defaultValue="oldCard"
                >
                  <FormControlLabel
                    value="oldCard"
                    control={<Radio />}
                    label={
                      <Typography fontSize="0.875rem" fontWeight={600}>
                        Utilizar Cartão cadastrado
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="newCard"
                    control={<Radio />}
                    label={
                      <Typography fontSize="0.875rem" fontWeight={600}>
                        Cadastrar Novo Cartão
                      </Typography>
                    }
                  />
                </RadioGroup>
                <FormikProvider value={formik}>
                  <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    {renderFormCreditCard()}
                    {/* {isNewCard === 'newCard' && (
                      <Box>
                        <Grid container direction="column" spacing={3}>
                          <Grid item xs={12}>
                            <TextField
                              label="Nome no Cartão"
                              fullWidth
                              disabled={false}
                              {...getFieldProps('cardName')}
                              error={Boolean(errors.cardName && touched.cardName)}
                              helperText={touched.cardName && errors.cardName}
                              sx={{ fontSize: '0.875rem' }}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <InputMask
                              mask="9999  9999  9999  9999"
                              disabled={false}
                              maskChar=""
                              {...getFieldProps('cardNumber')}
                              error={Boolean(errors.cardNumber && touched.cardNumber)}
                              helperText={touched.cardNumber && errors.cardNumber}
                            >
                              {(props: any) => (
                                <TextField
                                  label="Número do Cartão"
                                  fullWidth
                                  {...props}
                                  sx={{ fontSize: '0.875rem' }}
                                  size="small"
                                />
                              )}
                            </InputMask>
                          </Grid>
                          <Grid item container direction="row" spacing={3} xs={12}>
                            <Grid item xs={4}>
                              <InputMask
                                mask="999"
                                disabled={false}
                                maskChar=""
                                {...getFieldProps('cvv')}
                                error={Boolean(errors.cvv && touched.cvv)}
                                helperText={touched.cvv && errors.cvv}
                              >
                                {(props: any) => (
                                  <TextField
                                    label="CVV"
                                    fullWidth
                                    {...props}
                                    sx={{ fontSize: '0.875rem' }}
                                    size="small"
                                  />
                                )}
                              </InputMask>
                            </Grid>
                            <Grid item xs={4}>
                              <InputMask
                                formatChars={{ '1': '[0-1]', '9': '[0-9]' }}
                                mask="19/9999"
                                disabled={false}
                                maskChar=""
                                {...getFieldProps('expirationDate')}
                                error={Boolean(errors.expirationDate && touched.expirationDate)}
                                helperText={touched.expirationDate && errors.expirationDate}
                              >
                                {(props: any) => (
                                  <TextField
                                    label="Data de Validade"
                                    fullWidth
                                    {...props}
                                    placeholder="mm/aaaa"
                                    sx={{ fontSize: '0.875rem' }}
                                    size="small"
                                  />
                                )}
                              </InputMask>
                            </Grid>
                            <Grid item xs={4}>
                              <Autocomplete
                                {...getFieldProps('installments')}
                                size="small"
                                options={installmentsOptions}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, value) => setFieldValue('installments', value)}
                                renderInput={(props: any) => (
                                  <TextField {...props} label="Parcelas" />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    )} */}
                  </Form>
                </FormikProvider>
              </>
            )}
          </Paper>
        ))}
      </RadioGroup>
    </Box>
  );
};

export default PaymentMethods;

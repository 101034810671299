import { Method } from 'axios';
import { axiosInstance } from 'services/instance';
import { endpointforts } from 'services/request/endpoints/endpointsforts';

const API = () => {
  const request = <R, P = any>(
    endpoint: keyof typeof endpointforts,
    method: Method,
    params?: P,
    paramAdditional?: string
  ) =>
    axiosInstance.request<R>({
      url: paramAdditional ? endpointforts[endpoint] + paramAdditional : endpointforts[endpoint],
      method,
      data: params
    });

  return {
    request
  };
};

export default API;

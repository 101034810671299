/* eslint-disable prefer-const */
import { Icon } from '@iconify/react';
import { Box } from '@material-ui/core';
import { useEffect, useState } from 'react';
import roundReceipt from '@iconify/icons-ic/round-receipt';
import { Container, Tab, Tabs } from '@mui/material';
import { capitalCase } from 'change-case';
import Page from 'components/Page';
import useSettings from 'hooks/useSettings';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_COMPANY } from 'routes/paths';
import API from 'services/api';
import AccountBilling from './AccountBilling/index';
import { MethodProps, PaymentOptionsProps, ViewInvoicingProps } from '../Model';

export interface CreditCard {
  id: string;
  cardNumber: string;
  cardType: string;
}

// paymentMethods

const activeOptions = ['1', '2', '6'];

export default function AccountBillingPaymentMethod({ data }: ViewInvoicingProps) {
  const { request } = API();
  const [currentTab, setCurrentTab] = useState('faturamento');
  const { themeStretch } = useSettings();
  const [paymentOptions, setPaymentOptions] = useState<PaymentOptionsProps[]>([]);
  const [method, setMethod] = useState<MethodProps>({
    metodo_id: '',
    metodo_nome: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewCard, setIsNewCard] = useState('oldCard');

  const ACCOUNT_TABS = [
    {
      value: 'faturamento',
      icon: <Icon icon={roundReceipt} width={20} height={20} />,
      component: (
        <AccountBilling
          data={data}
          paymentOptions={paymentOptions}
          method={method}
          setMethod={setMethod}
          isSubmitted={isSubmitted}
          setIsSubmitted={setIsSubmitted}
          isNewCard={isNewCard}
          setIsNewCard={setIsNewCard}
          isLoading={isLoading}
        />
      )
    }
  ];

  const getPaymentMethods = async () => {
    setIsLoading(true);
    try {
      const { data }: any = await request('integrator_payment_methods', 'GET', false);

      setPaymentOptions(
        data.filter((option: any) => activeOptions.includes(String(option.metodo_id)))
      );
      setIsLoading(false);
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (data.cobranca) {
      let nameMethod = '';

      if (data.cobranca.metodo_pagamento === 2) {
        nameMethod = 'Cartão de Crédito';
      } else if (data.cobranca.metodo_pagamento === 1) {
        nameMethod = 'Boleto';
      } else {
        nameMethod = 'Pix Recebimento';
      }

      setMethod({
        metodo_id: `${data.cobranca.metodo_pagamento}`,
        metodo_nome: nameMethod
      });
    }
  }, [data]);

  return (
    <>
      <Page title="Faturamento">
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading="Faturamento"
            links={[{ name: 'Home', href: PATH_COMPANY.general.home }, { name: 'Faturamento' }]}
          />

          <Tabs
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={(e, value) => setCurrentTab(value)}
          >
            {ACCOUNT_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={capitalCase(tab.value)}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>

          <Box sx={{ mb: 5 }} />

          {ACCOUNT_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Container>
      </Page>
    </>
  );
}

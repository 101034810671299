import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from 'services/instance';
import endpoints from 'services/request/endpoints';
import {
  ClientRecoveryPayloadProps,
  LoginClientPayloadProps
} from 'services/request/models/payload';
import {
  ClientRecoveryResponseProps,
  LoginClientResponseProps
} from 'services/request/models/response';
import { AxiosResponse } from 'axios';
import { RememberProps } from '../../models/admin';

export const fetchLoginClientV2 = createAsyncThunk<
  LoginClientResponseProps,
  LoginClientPayloadProps
>('authentication.clientv2.fetchLogin', async (data, { rejectWithValue }) => {
  try {
    const api = axiosInstance;
    const signInResponse = await api({
      baseURL: process.env.REACT_APP_BASE_URL + endpoints.clientV2.login,
      method: 'POST',
      data
    });

    return signInResponse.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const LogoutClientV2 = createAction('authentication.clientv2.logout');

export const setRememberV2 = createAction<RememberProps>('authentication.clientv2.setRemember');

export const fetchClientV2Recovery = createAsyncThunk<
  ClientRecoveryResponseProps,
  ClientRecoveryPayloadProps
>('authentication.clientv2.fetchClientRecovery', async (data, { rejectWithValue }) => {
  try {
    const api = axiosInstance;
    const response: AxiosResponse = await api({
      baseURL: process.env.REACT_APP_BASE_URL + endpoints.clientV2.recovery,
      method: 'POST',
      data
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CheckoutSelector } from 'services/store/reducers/integrator/checkout.store';
import { CheckoutStateProps } from 'services/store/models/integrator';
import { PATH_COMPANY } from 'routes/paths';
import endpoints from 'services/request/endpoints';
import { useSnackbar } from 'notistack5';
import { axiosInstance } from 'services/instance';
import API from 'services/api';
import { CompanyConfigurationProps } from 'screens/integrator/invoicing/Model';
import {
  PlanDataProps,
  PaymentOptionsProps,
  MethodProps,
  CardValuesProps,
  PaymentResponseProps
} from './model';
import View from './view';

export const Checkout: React.FC = () => {
  // variables
  const { enqueueSnackbar } = useSnackbar();
  const { request } = API();
  const [companyConfiguration, setCompanyConfiguration] = useState<CompanyConfigurationProps>(
    {} as CompanyConfigurationProps
  );

  // root
  const navigate = useNavigate();
  const checkout: CheckoutStateProps = useSelector(CheckoutSelector);
  const storeIntegratorData = useSelector((state: any) => state.AuthCompany.user);
  const [isLoading, setIsLoading] = useState(false);
  const { descricao, valor, desconto_trimestral, desconto_anual, id } = checkout;
  const activeOptions = ['1', '2', '6'];
  const [isPreview, setIsPreview] = useState(false);

  // view

  // paymentMethods
  const installmentsOptions = [
    {
      id: 1,
      label: '1x sem juros'
    },
    {
      id: 2,
      label: '2x sem juros'
    },
    {
      id: 3,
      label: '3x sem juros'
    },
    {
      id: 4,
      label: '4x sem juros'
    },
    {
      id: 5,
      label: '5x sem juros'
    },
    {
      id: 6,
      label: '6x sem juros'
    },
    {
      id: 7,
      label: '7x sem juros'
    },
    {
      id: 8,
      label: '8x sem juros'
    },
    {
      id: 9,
      label: '9x sem juros'
    },
    {
      id: 10,
      label: '10x sem juros'
    },
    {
      id: 11,
      label: '11x sem juros'
    },
    {
      id: 12,
      label: '12x sem juros'
    }
  ];

  const [paymentOptions, setPaymentOptions] = useState<PaymentOptionsProps[]>([]);

  const [isNewCard, setIsNewCard] = useState('oldCard');

  const [cardValues, setCardValues] = useState<CardValuesProps>({
    cardName: '',
    cardNumber: '',
    expirationDate: '',
    cvv: '',
    installments: installmentsOptions[0]
  });

  const [method, setMethod] = useState<MethodProps>({
    metodo_id: '',
    metodo_nome: '',
    metodo_vezes: 1
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  // stepper
  const steps = ['Carrinho', 'Pagamento'];

  // product
  const [planData, setPlanData] = useState<PlanDataProps>({
    id: 0,
    selected: '',
    period: {
      id: '',
      label: ''
    },
    value: 0,
    discount: 0,
    discountCoupon: ''
  });
  const [newPeriod, setNewPeriod] = useState({ id: '', label: '' });

  // preview
  const [previewData, setPreviewData] = useState<PaymentResponseProps>({
    message: '',
    description: '',
    transaction: 0,
    banksplit: {
      duedate: '',
      digitableline: '',
      barcode: '',
      bankslipurl: '',
      keypix: '',
      qrcodepix: ''
    }
  });

  const [isPix, setIsPix] = useState(false);

  // billingForm
  const [isSubmittedBillingForm, setIsSubmittedBillingForm] = useState(false);

  // endVariables

  // functions

  const getIntegratorData = () => {
    const { integrador_id } = storeIntegratorData;

    const data = {
      integrador_id
    };
    return data;
  };

  async function getPaymentMethods() {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.integrator.paymentMethods}`;
    setIsLoading(true);
    await axiosInstance
      .get(endpoint)
      .then(({ data }) => {
        setPaymentOptions(
          data.filter((option: any) => activeOptions.includes(String(option.metodo_id)))
        );
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }

  async function handlePayment() {
    setIsSubmitted(false);
    if (!method.metodo_id) {
      enqueueSnackbar('Por favor, selecione um método de pagamento!', {
        variant: 'error'
      });
      return;
    }
    const payload = {
      integrador_id: integratorData.integrador_id,
      metodo: {
        ...method
      },
      checkout: {
        plano_id: planData.id,
        plano_nome: planData.selected,
        plano_periodo: planData.period.id
      }
    };
    if (method.metodo_nome === 'Cartão de Crédito' && isNewCard === 'newCard') {
      Object.assign(payload.metodo, {
        cartao: {
          nome: cardValues.cardName,
          numero: cardValues.cardNumber,
          expira: cardValues.expirationDate,
          codigo: cardValues.cvv
        }
      });
    }

    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.integrator.pay}`;
    setIsLoading(true);
    await axiosInstance
      .post(endpoint, payload)
      .then(({ data }) => {
        if (method.metodo_nome === 'Pix Recebimento') {
          enqueueSnackbar('QR Code gerado com Sucesso!', {
            variant: 'success'
          });
          setPreviewData(data);
          setIsPreview(true);
          setIsPix(true);
        }
        if (method.metodo_nome === 'Boleto') {
          enqueueSnackbar('Boleto gerado com Sucesso!', {
            variant: 'success'
          });
          setPreviewData(data);
          setIsPreview(true);
        }
        if (method.metodo_nome === 'Cartão de Crédito') {
          enqueueSnackbar('Pagamento Efetuado com Sucesso!', {
            variant: 'success'
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: 'error'
        });
        setIsLoading(false);
        console.log(error.response.data.message);
      });
  }

  const integratorData = getIntegratorData();

  const OnMount = (isUpdate: boolean) => {
    !descricao && navigate(PATH_COMPANY.general.licences);
    !isUpdate && getPaymentMethods();
    let desconto = 0;
    let valorTotal = valor;
    const periodo = isUpdate ? newPeriod.id : checkout.periodo;
    let periodLabel = 'Mensal';
    if (isUpdate && periodo === 'mensal') {
      setCardValues({ ...cardValues, installments: { ...installmentsOptions[0] } });
    }
    if (periodo === 'trimestral') {
      desconto = desconto_trimestral * valor * 0.01 * 3;
      valorTotal = valor * 3;
      periodLabel = 'Trimestral';
      setCardValues({ ...cardValues, installments: { ...installmentsOptions[2] } });
    } else if (periodo === 'anual') {
      desconto = desconto_anual * valor * 0.01 * 12;
      valorTotal = valor * 12;
      periodLabel = 'Anual';
      setCardValues({ ...cardValues, installments: { ...installmentsOptions[11] } });
    }
    setPlanData({
      id,
      selected: descricao,
      period: {
        id: periodo,
        label: periodLabel
      },
      value: valorTotal,
      discount: desconto,
      discountCoupon: ''
    });
  };

  const getCompanyConfiguration = async () => {
    try {
      const { data }: any = await request('company_configuration', 'GET', false);

      setCompanyConfiguration(data);
    } catch (error) {
      console.log('error', error);
    }
  };

  // endFunctions
  useEffect(() => {
    getCompanyConfiguration();
  }, []);

  useEffect(() => {
    let isUpdate = false;
    if (newPeriod.id) {
      isUpdate = true;
    }
    OnMount(isUpdate);
  }, [newPeriod]);

  useEffect(() => {
    if (isSubmitted) {
      if (method.metodo_nome !== 'Cartão de Crédito') {
        handlePayment();
        return;
      }
      if (isNewCard === 'oldCard') {
        handlePayment();
      }
    }
  }, [isSubmitted]);

  useEffect(() => {
    if (cardValues.cardName) {
      handlePayment();
    }
  }, [cardValues.cardName]);

  return (
    <View
      {...{
        isLoading,
        isPreview,

        // paymentMethods
        setCardValues,
        cardValues,
        setMethod,
        method,
        isSubmitted,
        setIsSubmitted,
        paymentOptions,
        installmentsOptions,
        handlePayment,
        isNewCard,
        setIsNewCard,

        // product
        planData,
        setPlanData,
        setNewPeriod,

        // stepper
        steps,

        // preview
        previewData,
        isPix,

        // billingForm
        isSubmittedBillingForm,
        setIsSubmittedBillingForm,
        companyConfiguration
      }}
    />
  );
};

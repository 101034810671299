import {
  Stepper as MuiStepper,
  Stack,
  Step,
  StepLabel,
  StepConnector,
  StepIconProps,
  styled,
  stepConnectorClasses
} from '@mui/material';
import { StepperProps } from '../model';

const Stepper: React.FC<StepperProps> = ({ currentStep, steps }) => {
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)'
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: 'rgb(0, 171, 85)'
      }
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: 'rgb(0, 171, 85)'
      }
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 2,
      borderRadius: 1
    }
  }));

  const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
      color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
      ...(ownerState.active && {
        color: 'rgb(0, 171, 85)'
      }),
      '& .QontoStepIcon-completedIcon': {
        color: 'rgb(0, 171, 85)',
        zIndex: 1,
        fontSize: 18
      },
      '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor'
      }
    })
  );

  const QontoStepIcon = ({ active, className }: StepIconProps) => (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      <div className="QontoStepIcon-circle" />
    </QontoStepIconRoot>
  );

  return (
    <Stack spacing={4} sx={{ display: 'inline-block', width: 'auto' }}>
      <MuiStepper
        alternativeLabel
        activeStep={currentStep === 'Carrinho' ? 0 : 1}
        connector={<QontoConnector />}
        sx={{ width: 'auto', justifyContent: 'flex-start' }}
      >
        {steps.map((label) => (
          <Step key={label} sx={{ width: 'auto' }}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </MuiStepper>
    </Stack>
  );
};

export default Stepper;

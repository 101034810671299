import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Link, Stack, Drawer, Typography, IconButton } from '@material-ui/core';
// hooks
import { RootState, useSelector } from 'services/store';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import { Icon } from '@iconify/react';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// routes
import { PATH_COMPANY } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MenuHidden } from '../../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

// ----------------------------------------------------------------------

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
};

function IconCollapse({ onToggleCollapse }: IconCollapseProps) {
  const { isCollapse } = useCollapseDrawer();
  return (
    <IconButton
      onClick={onToggleCollapse}
      sx={{
        color: '#637381',
        backgroundColor: 'rgb(255, 255, 255)',
        border: '1px dashed rgba(99, 115, 129, 0.3)',
        padding: '4px',

        position: 'fixed',
        top: isCollapse ? 75 : 95,
        left: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        zIndex: 3000,
        translate: '-50%',

        '&:hover': {
          backgroundColor: 'rgb(255, 255, 255)'
        }
      }}
    >
      <Icon icon={isCollapse ? arrowIosForwardFill : arrowIosBackFill} width={16} height={16} />
    </IconButton>
  );
}

type SidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  isTrialPeriod: boolean;
};

export default function Sidebar({ isOpenSidebar, onCloseSidebar, isTrialPeriod }: SidebarProps) {
  const { pathname } = useLocation();
  const [AuthCompany] = useSelector((state: RootState) => [state.AuthCompany]);
  const { user } = AuthCompany;

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <Scrollbar
        sx={{
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <Stack
          spacing={3}
          sx={{
            px: 2.5,
            pt: 5,
            pb: 2,
            ...(isCollapse && {
              alignItems: 'center'
            })
          }}
        >
          <Stack direction="column" alignItems="center" justifyContent="space-between">
            {!isCollapse ? (
              <Box component={RouterLink} to="/" sx={{ display: 'inline-flex', padding: 2 }}>
                <Logo sx={{ height: 100, width: 100 }} />
              </Box>
            ) : (
              <Box component={RouterLink} to="/" sx={{ display: 'inline-flex', padding: 2 }}>
                <Logo sx={{ height: 60, width: 60 }} />
              </Box>
            )}
          </Stack>

          {isCollapse ? (
            <MyAvatar sx={{ mx: 'auto', mb: 2 }} />
          ) : (
            <Link underline="none" component={RouterLink} to={PATH_COMPANY.general.profile}>
              <AccountStyle>
                <MyAvatar />
                <Box sx={{ ml: 2 }}>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    {user?.nome}
                  </Typography>
                </Box>
              </AccountStyle>
            </Link>
          )}
        </Stack>

        <NavSection navConfig={sidebarConfig} isShow={!isCollapse} />

        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
    </>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MenuHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: isTrialPeriod ? { width: DRAWER_WIDTH, top: 5 } : { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MenuHidden>

      <MenuHidden width="lgDown">
        <IconCollapse {...{ onToggleCollapse }} />

        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              }),
              top: isTrialPeriod ? 5 : 0
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MenuHidden>
    </RootStyle>
  );
}

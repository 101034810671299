import { Icon } from '@iconify/react';
import { Box, Container, Tab, Tabs } from '@mui/material';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useAppSelector } from 'services/request/baseQuery';
import { useDispatch } from 'services/store';
import { fetchProfile } from 'services/store/actions/integrator/profile.action';
import accounntCircle from '@iconify/icons-ic/account-circle';
import ApartmentIcon from '@iconify/icons-ic/round-apartment';
import { capitalCase } from 'change-case';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import { PATH_COMPANY } from 'routes/paths';
import useSettings from 'hooks/useSettings';

import API from 'services/api';
import View from './View';
import DataCompany from './DataCompany';
import { CompanyDataProps } from './Model';

const AdminProfile: FC = (): ReactElement => {
  const dispatch = useDispatch();
  const { request } = API();
  const { themeStretch } = useSettings();
  const [user] = useAppSelector((state) => [state.ProfileIntegrator]);
  const [currentTab, setCurrentTab] = useState('Conta');
  const [handleModal, setHandleModal] = useState(false);
  const [companyData, setCompanyData] = useState<CompanyDataProps>({} as CompanyDataProps);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  const getCompanyData = async () => {
    setIsLoading(true);
    try {
      const { data }: any = await request('integrator_company_data', 'GET', false);

      setCompanyData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  const PROFILE_TABS = [
    {
      value: 'Conta',
      icon: <Icon icon={accounntCircle} width={20} height={20} />,
      component: <View data={{ user, isLoading: user.loading, handleModal, setHandleModal }} />
    },
    {
      value: 'Empresa',
      icon: <Icon icon={ApartmentIcon} width={20} height={20} />,
      component: <DataCompany data={{ companyData, isLoading }} />
    }
  ];

  return (
    <Page title="Minha Conta">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Minha Conta"
          links={[{ name: 'Home', href: PATH_COMPANY.general.home }, { name: 'Minha Conta' }]}
        />

        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
        >
          {PROFILE_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={capitalCase(tab.value)}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {PROFILE_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
};
export default AdminProfile;

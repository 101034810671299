import { useSnackbar } from 'notistack5';
import { useLocation, useNavigate } from 'react-router';
import { axiosInstance } from 'services/instance';
import { dispatch } from 'services/store';
import { LogoutAdmin } from 'services/store/actions/auth.action';
import { Logout as LogoutCompany } from 'services/store/actions/authcompany.action';
import { LogoutClient } from 'services/store/actions/authclient.action';
import { useEffect } from 'react';
import { PATH_COMPANY } from 'routes/paths';
import { useContextCompany } from 'contexts/CompanyContext';

const AxiosInterceptor = () => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const currentRoute = location.pathname.split('/')[1];
  const { setMessageExpired } = useContextCompany();

  const handleLogout = () => {
    switch (currentRoute) {
      case 'admin':
        dispatch(LogoutAdmin());
        break;

      case 'painel-cliente':
        dispatch(LogoutClient());
        break;

      default:
        dispatch(LogoutCompany());
        break;
    }
  };

  interface errorProps {
    response: {
      status: number;
      data: {
        message: string;
      };
    };
  }

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error: errorProps) => {
        if (
          error.response.status === 401 &&
          error.response.data.message.includes('Unauthenticated')
        ) {
          enqueueSnackbar('Sua sessão expirou!', {
            variant: 'error'
          });
          handleLogout();
        }

        if (error.response.status === 402) {
          navigate(PATH_COMPANY.general.periodExpired);
          setMessageExpired(error.response.data.message);
        }

        return Promise.reject(error);
      }
    );
  }, []);

  return null;
};

export { AxiosInterceptor };

import { useState } from 'react';
// material
import {
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Box,
  Collapse,
  Typography,
  Divider
} from '@material-ui/core';
// redux
// routes
// @types
// components
import { CompanyListHead } from 'components/_dashboard/companies';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from 'moment';
import currencyBrl from 'utils/currencyBrl';
import { fPercent } from 'utils/formatNumber';
import { MaskCEST, MaskNCM } from 'utils/masks';
import { RowsProps, DetailsProps } from '../Model';
import MoreMenu from './MoreMenu';

function Rows({ nota_fiscal, TABLE_HEAD_ITENS, valorTotalIcmsst, valorTotalNota }: RowsProps) {
  const [open, setOpen] = useState<boolean>(false);
  const { emissao, emitente, emitente_uf, nota, itens } = nota_fiscal;
  return (
    <>
      <TableRow>
        <TableCell style={{ width: 100 }}>
          <Button
            color="success"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Button>
        </TableCell>
        <TableCell style={{ minWidth: 50 }}>{nota}</TableCell>
        <TableCell style={{ minWidth: 50 }}>{moment(emissao).format('DD/MM/YYYY')}</TableCell>
        <TableCell style={{ minWidth: 50 }}>{emitente}</TableCell>
        <TableCell style={{ minWidth: 50 }}>{currencyBrl(valorTotalNota(nota_fiscal))}</TableCell>
        <TableCell style={{ minWidth: 50 }}>{currencyBrl(valorTotalIcmsst(nota_fiscal))}</TableCell>
        <TableCell style={{ minWidth: 20 }}>{emitente_uf}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse style={{ marginLeft: 40 }} in={open} timeout="auto">
            <Box>
              <Table size="small" aria-label="purchases">
                <CompanyListHead headLabel={TABLE_HEAD_ITENS} />
                <TableBody>
                  {itens.map((item, key: number) => (
                    <Details key={key} item={item} nota={nota} nota_fiscal={nota_fiscal} />
                  ))}
                </TableBody>
              </Table>
            </Box>
            <div style={{ backgroundColor: 'rgb(233 233 233)', height: '1px' }} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default Rows;

const Details = ({ item, nota }: DetailsProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { descricao, produto, cfop, ncm, cest, um, quantidade, tipo_busca } = item;
  const { mva, valor_produto, valor_desconto, valor_frete, valor_ipi, base_calculo_icmsst } =
    item.base_calculo.description;
  const { valor_total_produtos, aliquota_interna_icms, aliquota_icms, valor_icms_st } =
    item.icmsst.description;

  return (
    <>
      <TableRow>
        <TableCell style={{ width: 100 }}>
          <Button
            color="success"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Button>
        </TableCell>
        <TableCell style={{ minWidth: 100 }}>{item.item}</TableCell>
        <TableCell style={{ minWidth: 100 }}>{produto}</TableCell>
        <TableCell style={{ minWidth: 100 }}>{descricao}</TableCell>
        <TableCell style={{ minWidth: 100 }}>{currencyBrl(valor_total_produtos)}</TableCell>
        <TableCell style={{ minWidth: 100 }}>{fPercent(mva)}</TableCell>
        <TableCell style={{ minWidth: 100 }}>{currencyBrl(valor_icms_st)}</TableCell>
        <TableCell>
          <MoreMenu item={item} nota={nota} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse style={{ marginLeft: 80 }} in={open} timeout="auto">
            <Divider />
            <Table size="small" aria-label="purchases">
              <TableBody
                style={{
                  padding: 0,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <TableCell style={{ padding: 2 }}>
                  <Box display="flex" flexDirection="row" gap={1}>
                    <TableCell style={{ minWidth: 50, padding: 0 }}>
                      <Typography fontSize="0.875rem" fontWeight="700">
                        CFOP:
                      </Typography>
                      <Typography fontSize="0.875rem" fontWeight="700">
                        NCM:
                      </Typography>
                      <Typography fontSize="0.875rem" fontWeight="700">
                        CEST:
                      </Typography>
                      <Typography fontSize="0.875rem" fontWeight="700">
                        UM:
                      </Typography>
                    </TableCell>
                    <TableCell style={{ minWidth: 50, padding: 0 }} align="right">
                      <Typography fontSize="0.875rem">{cfop}</Typography>
                      <Typography fontSize="0.875rem">{MaskNCM(ncm)}</Typography>
                      <Typography fontSize="0.875rem">{cest === null ? '-' : cest}</Typography>
                      <Typography fontSize="0.875rem">{um}</Typography>
                    </TableCell>
                  </Box>
                </TableCell>

                <TableCell style={{ padding: 2 }}>
                  <Box display="flex" flexDirection="row" padding={1} gap={1}>
                    <TableCell style={{ padding: 0 }}>
                      <Typography noWrap fontSize="0.875rem" fontWeight="700">
                        Valor Unitário:
                      </Typography>
                      <Typography fontSize="0.875rem" fontWeight="700">
                        Quantidade:
                      </Typography>
                      <Typography fontSize="0.875rem" fontWeight="700">
                        Desconto:
                      </Typography>
                      <Typography fontSize="0.875rem" fontWeight="700">
                        Frete:
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: 0 }} align="right">
                      <Typography fontSize="0.875rem">{currencyBrl(valor_produto)}</Typography>
                      <Typography fontSize="0.875rem">{quantidade}</Typography>
                      <Typography fontSize="0.875rem">{currencyBrl(valor_desconto)}</Typography>
                      <Typography fontSize="0.875rem">{currencyBrl(valor_frete)}</Typography>
                    </TableCell>
                  </Box>
                </TableCell>

                <TableCell style={{ padding: 2 }}>
                  <Box display="flex" flexDirection="row" padding={1} gap={1}>
                    <TableCell style={{ minWidth: 50, padding: 0 }}>
                      <Typography fontSize="0.875rem" fontWeight="700">
                        IPI:
                      </Typography>
                      <Typography
                        noWrap
                        fontSize="0.875rem"
                        fontWeight="700"
                        color="rgb(0, 92, 61)"
                      >
                        % ICMS Interno:
                      </Typography>
                      <Typography
                        noWrap
                        fontSize="0.875rem"
                        fontWeight="700"
                        color="rgb(0, 92, 61)"
                      >
                        % ICMS:
                      </Typography>
                      <Typography
                        noWrap
                        fontSize="0.875rem"
                        fontWeight="700"
                        color="rgb(0, 92, 61)"
                      >
                        Tipo de Busca:
                      </Typography>
                    </TableCell>
                    <TableCell style={{ minWidth: 50, padding: 0 }} align="right">
                      <Typography fontSize="0.875rem">{currencyBrl(valor_ipi)}</Typography>
                      <Typography fontSize="0.875rem">{fPercent(aliquota_interna_icms)}</Typography>
                      <Typography fontSize="0.875rem">{fPercent(aliquota_icms)}</Typography>
                      <Typography fontSize="0.875rem">{tipo_busca}</Typography>
                    </TableCell>
                  </Box>
                </TableCell>

                <TableCell style={{ padding: 2 }}>
                  <Box display="flex" flexDirection="row" gap={1}>
                    <TableCell style={{ minWidth: 50, padding: 0 }}>
                      <Typography
                        noWrap
                        fontSize="0.875rem"
                        fontWeight="700"
                        color="rgb(0, 92, 61)"
                      >
                        NCM X:
                      </Typography>
                      <Typography
                        noWrap
                        fontSize="0.875rem"
                        fontWeight="700"
                        color="rgb(0, 92, 61)"
                      >
                        CEST X:
                      </Typography>
                      <Typography
                        noWrap
                        fontSize="0.875rem"
                        fontWeight="700"
                        color="rgb(0, 92, 61)"
                      >
                        NCM 195:
                      </Typography>
                      <Typography
                        noWrap
                        fontSize="0.875rem"
                        fontWeight="700"
                        color="rgb(0, 92, 61)"
                      >
                        CEST 195:
                      </Typography>
                    </TableCell>
                    <TableCell style={{ minWidth: 50, padding: 0 }} align="right">
                      <Typography fontSize="0.875rem">{MaskNCM(ncm)}</Typography>
                      <Typography fontSize="0.875rem">
                        {cest === null ? '-' : MaskCEST(cest.toString())}
                      </Typography>
                      <Typography fontSize="0.875rem">{MaskNCM(ncm)}</Typography>
                      <Typography fontSize="0.875rem">
                        {cest === null ? '-' : MaskCEST(cest.toString())}
                      </Typography>
                    </TableCell>
                  </Box>
                </TableCell>

                <TableCell style={{ padding: 2 }}>
                  <Box display="flex" flexDirection="row" gap={2}>
                    <TableCell style={{ minWidth: 50, padding: 0 }}>
                      <Typography
                        noWrap
                        fontSize="0.875rem"
                        fontWeight="700"
                        color="rgb(0, 92, 61)"
                      >
                        Base de Calculo ICMS ST:
                      </Typography>
                      <Typography
                        noWrap
                        fontSize="0.875rem"
                        fontWeight="700"
                        color="rgb(0, 92, 61)"
                      >
                        Valor ICMS ST:
                      </Typography>
                      <Typography
                        noWrap
                        fontSize="0.875rem"
                        fontWeight="700"
                        color="rgb(0, 92, 61)"
                      >
                        Protocolo 41-2008:
                      </Typography>
                      <Typography
                        noWrap
                        fontSize="0.875rem"
                        fontWeight="700"
                        color="rgb(0, 92, 61)"
                      >
                        Convênio 5291:
                      </Typography>
                    </TableCell>
                    <TableCell style={{ minWidth: 50, padding: 0 }} align="right">
                      <Typography fontSize="0.875rem">
                        {currencyBrl(base_calculo_icmsst)}
                      </Typography>
                      <Typography fontSize="0.875rem">{currencyBrl(valor_icms_st)}</Typography>
                      <Typography noWrap fontSize="0.875rem">
                        Protocolo 41-2008
                      </Typography>
                      <Typography fontSize="0.875rem">Convênio 5291</Typography>
                    </TableCell>
                  </Box>
                </TableCell>
              </TableBody>
            </Table>
            <div style={{ backgroundColor: 'rgb(233 233 233)', height: '1px' }} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

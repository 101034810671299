import useSettings from 'hooks/useSettings';
import React, { useEffect, useState } from 'react';
import endpoints from 'services/request/endpoints';
import { axiosInstance } from 'services/instance';
import { List } from './Model';
import View from './View';

const ListTableSegmentCompany: React.FC = () => {
  const { themeStretch } = useSettings();
  const [currentTab, setCurrentTab] = useState<'active' | 'inactive'>('active');
  const [filter, setFilter] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [list, setList] = useState<List[]>([]);

  const TABLE_HEAD = [
    { id: 'id', label: 'Cód.', alignRight: false },
    { id: 'nome', label: 'Nome', alignRight: false },
    { id: 'updated_at', label: 'Atualizado em', alignRight: false }
  ];

  const getList = async () => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.integrator.tables.segments}`;
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(endpoint);
      setList(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (filter: string) => {
    setFilter(filter);
  };

  const filteredList = list.filter((el) => {
    const { codigo, nome, ativo } = el;

    if (currentTab === 'active') {
      return (
        ativo === true &&
        (`${codigo}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
          `${nome}`.toLowerCase().includes(`${filter}`.toLowerCase()))
      );
    }

    if (currentTab === 'inactive') {
      return (
        ativo === false &&
        (`${codigo}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
          `${nome}`.toLowerCase().includes(`${filter}`.toLowerCase()))
      );
    }

    return (
      `${codigo}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
      `${nome}`.toLowerCase().includes(`${filter}`.toLowerCase())
    );
  });

  const isNofilterResult = filteredList.length === 0;

  return (
    <View
      {...{
        themeStretch,
        filteredList,
        TABLE_HEAD,
        handleFilter,

        isLoading: loading,
        isNofilterResult,
        page,
        rowsPerPage,
        setCurrentTab,
        currentTab,
        emptyRows,
        filter,
        handleChangeRowsPerPage,
        setPage
      }}
    />
  );
};

export default ListTableSegmentCompany;

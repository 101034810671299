import useSettings from 'hooks/useSettings';
import React, { useEffect, useState } from 'react';
import endpoints from 'services/request/endpoints';
import { axiosInstance } from 'services/instance';
import useSweetAlert from 'hooks/useSweetAlert';
import { ListProps } from './Model';
import View from './View';

const ListTableCustomRules: React.FC = () => {
  const { themeStretch } = useSettings();
  const { exclude } = useSweetAlert();
  const [filter, setFilter] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [list, setList] = useState<ListProps[]>([]);

  const TABLE_HEAD = [
    { id: 'id', label: 'Cód.', alignRight: false },
    { id: 'ncm', label: 'NCM', alignRight: false },
    { id: 'description', label: 'Descrição', alignRight: false },
    { id: 'type_of_search', label: 'Tipo de Pesquisa', alignRight: false },
    { id: 'tributation', label: 'Tributação', alignRight: false },
    { id: 'companies', label: 'Empresas', alignRight: false },
    { id: 'action', label: 'Ação', alignRight: false },
    { id: 'field', label: 'Campo', alignRight: false },
    { id: 'value', label: 'Valor', alignRight: false },
    { id: '', label: '', alignRight: false }
  ];

  const getList = async () => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.customRules}`;
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(endpoint);

      setList(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async (id: number) => {
    exclude({
      endpoint: `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.customRule}/${id}`,
      setLoading: setLoadingDelete
    });
  };

  const handleFilter = (filter: string) => {
    setFilter(filter);
  };

  const filteredList = list.filter((el) => {
    const { ncm, descricao, tipo_pesquisa, tributacao, acao, campo, valor } = el;

    return (
      `${ncm}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
      `${descricao}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
      `${tipo_pesquisa}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
      `${tributacao}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
      `${acao}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
      `${campo}`.toLowerCase().includes(`${filter}`.toLowerCase()) ||
      `${valor}`.toLowerCase().includes(`${filter}`.toLowerCase())
    );
  });

  const isNofilterResult = filteredList.length === 0;

  return (
    <View
      {...{
        themeStretch,
        filteredList,
        TABLE_HEAD,
        handleFilter,
        handleDelete,
        isLoading: loading,
        isNofilterResult,
        page,
        rowsPerPage,
        emptyRows,
        filter,
        handleChangeRowsPerPage,
        setPage,
        loadingDelete
      }}
    />
  );
};

export default ListTableCustomRules;

// eslint-disable-next-line import/no-anonymous-default-export
const endpointforts = {
  admin_login: '/administrative/login',
  admin_recovery: '/administrative/recovery',
  admin_companiesList: '/administrative/companies',
  admin_registerCompany: '/administrative/company',
  admin_company: '/administrative/company',
  admin_group: '/administrative/group-permission',
  admin_groups: '/administrative/group-permissions',
  admin_permissions: '/administrative/permissions',
  admin_users: '/administrative/users',
  admin_user: '/administrative/user',
  admin_profile: '/administrative/me',
  admin_changeDetails: '/administrative/me',
  admin_changePassword: '/administrative/me/password',
  admin_states: '/administrative/table-states',
  admin_state: '/administrative/table-state',
  admin_attachmentxs: '/administrative/table-attachment-xs',
  admin_attachmentx: '/administrative/table-attachment-x',
  admin_segment: '/administrative/table-segment',
  admin_segments: '/administrative/table-segments',
  admin_port195s: '/administrative/table-port195s',
  admin_port195: '/administrative/table-port195',
  admin_conv5291s: '/administrative/table-conv5291s',
  admin_conv5291: '/administrative/table-conv5291',
  admin_proticms41s: '/administrative/table-proticms41s',
  admin_proticms41: '/administrative/table-proticms41',
  admin_plans: '/administrative/plans',
  company_plans: '/company/plans',
  company_configuration: '/company/configuration/invoicing',
  integrator_registerCompany: '/company',
  integrator_login: '/company/login',
  integrator_permissions: '/company/permissions',
  integrator_users: '/company/users',
  integrator_user: '/company/user',
  integrator_group: '/company/group-permission',
  integrator_groups: '/company/group-permissions',
  integrator_clients: '/company/clients',
  integrator_client: '/company/clients',
  integrator_profile: '/company/me',
  integrator_changeDetails: '/company/me',
  integrator_changePassword: '/company/me/password',
  integrator_company_data: '/company/company-data',
  integrator_recovery: '/company/recovery',
  integrator_payment_methods: '/company/payment-methods',
  client_login: '/client/login',
  client_permissions: '/client/permissions',
  client_users: '/client/users',
  client_user: '/client/user',
  client_group: '/client/group-permission',
  client_groups: '/client/group-permissions',
  client_profile: '/client/me',
  client_changeDetails: '/client/me',
  client_changePassword: '/client/me/password',
  client_recovery: '/client/recovery',
  company_consult: '/company/consult'
};

export { endpointforts };

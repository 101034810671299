import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { TextField, Alert, Stack } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useSnackbar } from 'notistack5';
import { useNavigate } from 'react-router';
import { PATH_ADMIN } from 'routes/paths';
import InputMask from 'react-input-mask';
import { ResetPasswordFormProps, InitialValues } from '../Model';

export default function ResetPasswordForm({ fetchRecovery }: ResetPasswordFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const ResetPasswordSchema = Yup.object().shape({
    cpf: Yup.string().required('O CPF é Obrigatório!')
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      cpf: ''
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setSubmitting(true);
      try {
        const response = await fetchRecovery(values.cpf);
        const returnMessage = response?.payload?.message || 'Solicitação realizada com sucesso!';

        if (response.meta.requestStatus === 'fulfilled') {
          setSubmitting(false);
          enqueueSnackbar(returnMessage, {
            variant: 'success'
          });
          navigate(PATH_ADMIN.general.home);
        } else {
          setSubmitting(false);
          enqueueSnackbar('Não foi possível recuperar sua senha!', {
            variant: 'error'
          });
        }
      } catch (error) {
        setSubmitting(false);
        setErrors({ afterSubmit: error.message });
        enqueueSnackbar('Ocorreu algum problema, tente novamente mais tarde.', {
          variant: 'error'
        });
      }
    }
  });
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
          <InputMask
            mask={
              formik.getFieldProps('cpf').value.length <= 14
                ? '999.999.999-999'
                : '99.999.999/9999-99'
            }
            maskChar=""
            helperText={touched.cpf && errors.cpf}
            error={Boolean(touched.cpf && errors.cpf)}
            {...getFieldProps('cpf')}
          >
            {(inputprops: any) => <TextField fullWidth label="CPF/CNPJ" {...inputprops} />}
          </InputMask>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Recuperar Senha
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

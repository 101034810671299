import * as Yup from 'yup';

import { Form, FormikProvider, useFormik } from 'formik';
import {
  Box,
  Card,
  Container,
  Typography,
  Grid,
  Stack,
  TextField,
  Button,
  Autocomplete,
  Modal,
  FormHelperText
} from '@material-ui/core';

import { LoadingButton } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import LoadingScreen from 'components/LoadingScreen';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_ADMIN } from 'routes/paths';
import { useNavigate } from 'react-router';
import endpoints from 'services/request/endpoints';
import InputMask from 'react-input-mask';
import useSweetAlert from 'hooks/useSweetAlert';
import { Company, NewCustomRuleViewProps } from '../Model';
import CompaniesList from '../Components/CompaniesList';

const View: React.FC<NewCustomRuleViewProps> = ({ loading, typePage, selected, companies }) => {
  const navigate = useNavigate();
  const { sendData } = useSweetAlert();
  const [openModal, setIsOpenModal] = useState(false);
  const [companiesValue, setCompaniesValue] = useState<Company[]>([]);
  const [errorCompanies, setErrorCompanies] = useState(false);
  const [requiredSearchType, setRequiredSearchType] = useState(false);

  const ValidationSchema = Yup.object().shape({
    ncm: Yup.string().max(8).required('A descrição é Obrigatória.'),
    type_search: Yup.string().required('O tipo de pesquisa é Obrigatório.'),
    tributation: Yup.string().required('A tributação é Obrigatória.'),
    companies: Yup.array().required('Selecione pelo menos uma empresa.'),
    action: Yup.string().required('A ação é Obrigatória.'),
    field: Yup.string().required('O Campo é Obrigatório.'),
    value: Yup.string().required('O valor é Obrigatório.')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ncm: '',
      description: '',
      type_search: '',
      tributation: '',
      companies: [
        {
          id: 0,
          razao: '',
          fantasia: '',
          cnpj: ''
        }
      ],
      action: '',
      field: '',
      value: ''
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      if (companiesValue.length >= 1) {
        const endpoint =
          typePage === 'New'
            ? `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.customRule}`
            : `${process.env.REACT_APP_BASE_URL}${endpoints.administrative.tables.customRule}/${selected?.id}`;

        const payload = {
          ncm: values.ncm.replace('.', '').replace('.', '').replace('.', ''),
          descricao: values.description,
          tipo_pesquisa: values.type_search,
          tributacao: values.tributation,
          empresas: companiesValue,
          acao: values.action,
          campo: values.field,
          valor: values.value.replace(',', '.').replace('%', '')
        };

        sendData({ typePage, payload, endpoint, setSubmitting, setErrors });
      } else {
        setErrorCompanies(true);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  useEffect(() => {
    if (selected.descricao) {
      formik.setFieldValue('ncm', selected.ncm);
      formik.setFieldValue('description', selected.descricao);
      formik.setFieldValue('type_search', selected.tipo_pesquisa);
      formik.setFieldValue('tributation', selected.tributacao);
      formik.setFieldValue('companies', selected.empresas);
      setCompaniesValue(selected.empresas);
      formik.setFieldValue('action', selected.acao);
      formik.setFieldValue('field', selected.campo);
      formik.setFieldValue('value', selected.valor);
    }
  }, [selected.descricao]);

  useEffect(() => {
    if (!selected) {
      if (formik.values.ncm.length === 8) {
        formik.setFieldValue('type_search', 'Exata');
        setRequiredSearchType(true);
      } else {
        formik.setFieldValue('type_search', '');
        setRequiredSearchType(false);
      }
    }
  }, [formik.values.ncm]);

  return (
    <Page title={typePage === 'New' ? 'Nova Regra' : 'Regra'}>
      <Container>
        <HeaderBreadcrumbs
          heading="Regras Personalizadas"
          links={[
            {
              name: 'Regras Personalizadas',
              href: PATH_ADMIN.general.administrative.tables.customRules
            },
            {
              name: typePage === 'New' ? 'Nova Regra' : 'Regra'
            }
          ]}
        />
        {loading ? (
          <div style={{ marginTop: '300px' }}>
            <LoadingScreen />
          </div>
        ) : (
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <Typography variant="h5">Dados</Typography>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="Descrição"
                          {...getFieldProps('description')}
                          error={Boolean(touched.description && errors.description)}
                          helperText={touched.description && errors.description}
                        />
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                        <InputMask
                          formatChars={{ '9': '[0-9.]' }}
                          mask="99999999"
                          disabled={false}
                          maskChar=""
                          {...getFieldProps('ncm')}
                          error={Boolean(touched.ncm && errors.ncm)}
                          helperText={touched.ncm && errors.ncm}
                          sx={{ width: '100%', minWidth: 120 }}
                        >
                          {(inputProps: any) => <TextField label="NCM" {...inputProps} />}
                        </InputMask>
                        <Autocomplete
                          sx={{ width: '100%' }}
                          disablePortal
                          id="combo-box-demo"
                          options={['Aproximação', 'Exata']}
                          getOptionLabel={(option: any) => option}
                          {...getFieldProps('type_search')}
                          onChange={(event: any, value: any) =>
                            formik.setFieldValue('type_search', value)
                          }
                          disabled={requiredSearchType}
                          renderInput={(props: any) => (
                            <TextField
                              {...props}
                              fullWidth
                              label="Tipo de Pesquisa"
                              error={Boolean(touched.type_search && errors.type_search)}
                            />
                          )}
                        />
                        <Autocomplete
                          sx={{ width: '100%' }}
                          disablePortal
                          id="combo-box-demo"
                          options={['Lucro Real', 'Lucro Presumido', 'Simples Nacional', 'Todas']}
                          getOptionLabel={(option: any) => option}
                          {...getFieldProps('tributation')}
                          onChange={(event: any, value: any) =>
                            formik.setFieldValue('tributation', value)
                          }
                          renderInput={(props: any) => (
                            <TextField
                              {...props}
                              label="Tributação"
                              error={Boolean(touched.tributation && errors.tributation)}
                            />
                          )}
                        />
                        <Stack direction="column" alignItems="center" sx={{ width: '100%' }}>
                          <Button
                            variant={touched.companies && errorCompanies ? 'outlined' : 'contained'}
                            onClick={() => {
                              setIsOpenModal(true);
                              formik.setFieldTouched('companies', true);
                            }}
                            sx={
                              touched.companies && errorCompanies
                                ? {
                                    width: '100%',
                                    height: '56px',
                                    color: 'red',
                                    borderColor: 'red'
                                  }
                                : { width: '100%', height: '56px' }
                            }
                          >
                            <Typography noWrap>Vincular Empresas</Typography>
                          </Button>
                          {errorCompanies && touched.companies ? (
                            <FormHelperText error>Selecione pelo menos uma empresa</FormHelperText>
                          ) : (
                            companiesValue.length > 0 && (
                              <FormHelperText>
                                {companiesValue.length}
                                {companiesValue.length === 1
                                  ? ' empresa selecionada.'
                                  : ' empresas selecionadas.'}
                              </FormHelperText>
                            )
                          )}
                        </Stack>
                        <Modal open={openModal}>
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              minWidth: 500
                            }}
                          >
                            <Card>
                              <CompaniesList
                                companies={companies}
                                setIsOpenModal={setIsOpenModal}
                                setCompaniesValue={setCompaniesValue}
                                companiesValue={companiesValue}
                              />
                            </Card>
                          </Box>
                        </Modal>
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 2 }}>
                        <Autocomplete
                          sx={{ width: '100%' }}
                          disablePortal
                          id="combo-box-demo"
                          options={['Reduzir', 'Acrescentar', 'Modificar']}
                          getOptionLabel={(option: any) => option}
                          {...getFieldProps('action')}
                          onChange={(event: any, value: any) =>
                            formik.setFieldValue('action', value)
                          }
                          renderInput={(props: any) => (
                            <TextField
                              {...props}
                              label="Ação"
                              fullWidth
                              error={Boolean(touched.action && errors.action)}
                            />
                          )}
                        />
                        <Autocomplete
                          sx={{ width: '100%' }}
                          disablePortal
                          id="combo-box-demo"
                          options={['Alíquota Interna ICMS']}
                          getOptionLabel={(option: any) => option}
                          {...getFieldProps('field')}
                          onChange={(event: any, value: any) =>
                            formik.setFieldValue('field', value)
                          }
                          renderInput={(props: any) => (
                            <TextField
                              {...props}
                              label="Campo"
                              fullWidth
                              error={Boolean(touched.field && errors.field)}
                            />
                          )}
                        />
                        <InputMask
                          formatChars={{ '9': '[0-9]' }}
                          mask={formik.values.value.length === 2 ? '99,00%' : '99,99%'}
                          disabled={false}
                          maskChar=""
                          {...getFieldProps('value')}
                          error={Boolean(touched.value && errors.value)}
                          helperText={touched.value && errors.value}
                        >
                          {(inputProps: any) => (
                            <TextField label="Valor" fullWidth {...inputProps} />
                          )}
                        </InputMask>
                      </Stack>

                      <Stack
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 2 }}
                      >
                        <Box sx={{ mt: 3, display: 'flex' }}>
                          <Button
                            variant="outlined"
                            type="button"
                            color="primary"
                            onClick={() => navigate(-1)}
                          >
                            Cancelar
                          </Button>
                        </Box>
                        <Box sx={{ mt: 3, display: 'flex' }}>
                          <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                            {typePage === 'New' ? 'Cadastrar' : 'Atualizar'}
                          </LoadingButton>
                        </Box>
                      </Stack>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        )}
      </Container>
    </Page>
  );
};

export default View;
